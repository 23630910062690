import React from 'react';
import { Field } from 'formik';
import { createStyles, InputLabel, MenuItem, Theme } from '@material-ui/core';
import { Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginBottom: '10px',
      color: theme.colors.darkGray,
      fontSize: '15px',
      fontWeight: 400,
    },
  }),
);

interface Props {
  fieldId: string;
  label: string;
  name: string;
  multiple?: boolean;
  placeholder?: string;
  children: React.ReactNode;
}

const SelectInput: React.FC<Props> = (props) => {
  const { children, fieldId, label, name, placeholder, multiple = false } = props;

  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.label} htmlFor={fieldId}>
        {label}
      </InputLabel>
      <Field
        component={Select}
        name={name}
        inputProps={{ id: fieldId }}
        disableUnderline
        MenuProps={{
          getContentAnchorEl: () => null,
        }}
        multiple={multiple}
      >
        <MenuItem value="" disabled>
          {placeholder || 'Wybierz'}
        </MenuItem>
        {children}
      </Field>
    </>
  );
};

export default SelectInput;
