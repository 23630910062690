import React, { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import axios from 'axios';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItem } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Cube } from '../../shared/types';
import { API_URL } from '../../shared/utils/config';
import { currentCube, setCurrentCube } from '../dataBrowserSlice';
import { sortByName } from '../../shared/utils/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.colors.darkGray,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    active: {
      color: theme.colors.blue,
    },
  }),
);

type Props = {
  cubesCount: number;
  categoryId: number;
  opened: boolean;
  onCubeClick?: (cube: Cube) => void;
};

function CubeList(props: Props) {
  const { categoryId, cubesCount, opened, onCubeClick } = props;

  const dispatch = useDispatch();

  const activeCube = useSelector(currentCube);

  const [cubes, setCubes] = useState([] as Cube[]);

  const classes = useStyles();

  useEffect(() => {
    const fetchCategoryCubes = async () => {
      try {
        const response = await axios.get(`${API_URL}/warehouse/categories/${categoryId}/cubes/`);
        setCubes(response.data);
      } catch (error) {
        console.error(`Failed to fetch cubes for ${categoryId}: ${error}`);
      }
    };

    if (opened && _.isEmpty(cubes)) {
      fetchCategoryCubes();
    }
  }, [categoryId, cubes, opened]);

  const handleClick = (cube: Cube) => {
    dispatch(setCurrentCube(cube.id));
    if (onCubeClick) {
      onCubeClick(cube);
    }
  };

  return (
    <>
      {_.isEmpty(cubes)
        ? _.range(cubesCount).map((index) => <Skeleton key={index} animation="wave" />)
        : sortByName(cubes).map((cube) => (
            <ListItem
              key={cube.id}
              button
              onClick={() => handleClick(cube)}
              className={`${classes.root} ${classes.nested} ${
                activeCube && activeCube.id === cube.id && classes.active
              }`}
            >
              <ListItemText
                primary={`• ${cube.name}${cube.aggregationLevel ? ` (KTS-${cube.aggregationLevel})` : ''}`}
              />
            </ListItem>
          ))}
    </>
  );
}

export default CubeList;
