import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useMutation, queryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import GenericButton from '../../../shared/components/GenericButton';
import Title from '../../../shared/components/Title';
import RegionPicker from '../../../Groups/GroupEditorModal/RegionPicker';
import UnitSearchBar from '../../../Groups/GroupEditorModal/RegionPicker/UnitSearchBar';
import UnitPicker from '../../../Groups/GroupEditorModal/UnitPicker';
import api from '../../../shared/api';
import TerritoryListPicker from '../../../Groups/GroupEditorModal/TerritoryListPicker';
import TerritoryPicker from '../../../Groups/GroupEditorModal/TerritoryPicker';
import { useTerritoryStore } from '../../../Groups/GroupEditorModal';

const {
  report: { generatev2 },
} = api;

interface Props {
  isOpen: boolean;
  reportId: number;
  onClose: () => void;
}

interface Fields {
  administrativeUnit: number | null;
  territory: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      marginBottom: '10px',
      color: theme.colors.darkGray,
      fontSize: '15px',
      fontWeight: 400,
    },
    dialogContent: {
      boxSizing: 'border-box',
      padding: '34px',
      overflow: 'hidden',
    },
    form: {
      height: 'auto',
      display: 'flex',
    },
    columnContent: {
      height: '100%',
      overflow: 'hidden',
    },
    column: {
      height: '100%',
    },
    container: {
      height: '100%',
    },
  }),
);

const initialValues = {
  administrativeUnit: null,
  territory: false,
};

const schema = Yup.object({
  administrativeUnit: Yup.number().nullable(true),
  territory: Yup.boolean(),
});

const GenerateReportModalV2: React.FC<Props> = ({ isOpen, onClose, reportId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const activeTerritoryId = useTerritoryStore((state) => state.activeTerritoryId);

  const [mutate] = useMutation(generatev2, {
    onSuccess: () => {
      enqueueSnackbar('Raport dodany do kolejki.', {
        variant: 'success',
        autoHideDuration: 3000,
        disableWindowBlurListener: true,
      });
      queryCache.invalidateQueries(['fetchGeneratedReports']);
      onClose();
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });
  const onSubmit = (values: Fields) => {
    if (values.administrativeUnit) {
      mutate({ administrativeUnitId: values.administrativeUnit, reportId, territory: values.territory });
    }
  };
  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={() => onClose()}>
      <DialogTitle>Generuj raport</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {({ values, isValid, submitForm }) => (
          <>
            <DialogContent className={classes.dialogContent}>
              <Form className={classes.form}>
                <Grid container spacing={2} className={classes.container}>
                  <Grid item xs={6} className={classes.column}>
                    <Box className={classes.columnContent}>
                      <Title variant="h2" modal title="Teryt" />
                      <Box style={{ marginTop: '8px' }}>
                        <UnitSearchBar />
                      </Box>
                      <Box style={{ marginTop: '8px', height: 'calc(50vh - 70px)', overflow: 'auto' }}>
                        <RegionPicker />
                        <TerritoryListPicker />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} className={classes.column}>
                    <Box className={classes.columnContent}>
                      <Title variant="h2" modal title="Wybierz jednostki" />
                      {!activeTerritoryId ? <UnitPicker multi={false} /> : <TerritoryPicker multi={false} />}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <GenericButton
                disabled={!isValid || !values.administrativeUnit}
                variant="primary"
                onClick={() => submitForm()}
              >
                Generuj
              </GenericButton>
              <GenericButton variant="destructiveSecondary" onClick={() => onClose()}>
                Anuluj
              </GenericButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default GenerateReportModalV2;
