import axios from 'axios';
import { saveAs } from 'file-saver';
import { omit } from 'lodash-es';
import { API_URL } from './utils/config';
import {
  AdministrativeGroup,
  AdministrativeGroupList,
  AdministrativeUnit,
  Cube,
  CubeCategory,
  CubeTreeSource,
  Indicator,
  IndicatorCategory,
  TableData,
  Report,
  ReportCategory,
  ReportFormResult,
  GeneratedReport,
  ApiResults,
  TerritoryGroup,
  TerritoryGroupList,
  ReportContent,
  ReportFolder,
  ReportIndicator,
} from './types';

interface fetchDataPayload {
  id: number;
  postData: any;
}

interface APIObject {
  id?: number;
}

function CRUDActions<T extends APIObject>(endpoint: string) {
  return {
    fetchList: async () => {
      const response = await axios.get(endpoint);
      return response.data?.results as T[];
    },
    fetchDetails: async (id?: number) => {
      if (id) {
        const response = await axios.get(`${endpoint}/${id}/`);
        return response.data as T;
      }
      return undefined;
    },
    save: async (formData: T) => {
      let response;
      if (formData.id) {
        response = await axios.put(`${endpoint}/${formData.id}/`, formData);
      } else {
        response = await axios.post(`${endpoint}/`, formData);
      }
      return response.data as T;
    },
    delete: async (id?: number) => {
      if (id) {
        const response = await axios.delete(`${endpoint}/${id}/`);
        return response.data as T;
      }
      return undefined;
    },
    tableData: async (payload: fetchDataPayload) => {
      const { id, postData } = payload;
      return axios.post(`${endpoint}/${id}/data/`, postData).then((response) => response.data as TableData);
    },
    tree: async () => axios.get(`${endpoint}/tree/`).then((response) => response.data as T[]),
  };
}

const fetchListGroups = async (id?: number) => {
  if (id) {
    const response = await axios.get(`${API_URL}/group_lists/${id}/groups/`);
    return response.data as AdministrativeGroup[];
  }
  return undefined;
};

const fetchListTerritories = async (id?: number) => {
  if (id) {
    const response = await axios.get(`${API_URL}/territory_lists/${id}/territories`);
    return response.data as TerritoryGroup[];
  }
  return undefined;
};

const fetchChildUnits = async (key: string, id?: number, type?: string) => {
  if (id) {
    const response = await axios.get(`${API_URL}/units/`, { params: { parent: id, type: type, limit: 1650 } });
    return response.data?.results as AdministrativeUnit[];
  }
  return undefined;
};

const fetchTopLevelUnits = async () => axios.get(`${API_URL}/units/top_level`).then((response) => response.data);

const fetchGroupUnits = async (key: string, id?: number) => {
  if (id) {
    const response = await axios.get(`${API_URL}/groups/${id}/units/`);
    return response.data as [AdministrativeUnit[], TerritoryGroup[]];
  }
  return undefined;
};
const fetchTerritoryUnits = async (key: string, id?: number) => {
  if (id) {
    const response = await axios.get(`${API_URL}/territories/${id}/units/`);
    return response.data as AdministrativeUnit[];
  }
  return undefined;
};

const fetchFolderIndicators = async (key: string, id?: number) => {
  if (id) {
    const response = await axios.get(`${API_URL}/warehouse/folders/${id}/indicators/`);
    return response.data as Indicator[];
  }
  return undefined;
};

const copyIndicatorToFolder = async (indicatorId: number, folderId: number) => {
  await axios.post(`${API_URL}/warehouse/indicators/${indicatorId}/copy_to_folder/${folderId}/`);
  return undefined;
};

const linkIndicatorToFolder = async (indicatorId: number, folderId: number) => {
  await axios.post(`${API_URL}/warehouse/indicators/${indicatorId}/link_to_folder/${folderId}/`);
  return undefined;
};

const copyIndicatorCategoryToFolder = async (indicatorCategoryId: number, folderId: number) => {
  return axios.post(`${API_URL}/warehouse/folders/${indicatorCategoryId}/copy_folder/`, { folderId });
};

// Copy report folder to another folder
const copyFolderToFolder = async (indicatorCategoryId: number, folderId: number) => {
  return axios.post(`${API_URL}/reports/folders/${indicatorCategoryId}/copy_report_folder/`, { folderId });
};

const fetchFolderListDetails = async (folderId: number[]) => {
  const response = await axios.post(`${API_URL}/warehouse/folders/list_details/`, { folderId });
  return response.data as IndicatorCategory[];
};

const fetchCubeTree = async () =>
  axios.get(`${API_URL}/warehouse/categories/tree`).then((res) => res.data as CubeTreeSource[]);

const fetchIndicators = async () => {
  const response = await axios.get(`${API_URL}/warehouse/indicators/`);
  return response.data.results as Indicator[];
};

const fetchAdministrativeGroups = async () => {
  const response = await axios.get(`${API_URL}/group_lists`);
  return response.data.results as AdministrativeGroupList[];
};

const fetchFolderReports = async (key: string) => {
  const response = await axios.get(`${API_URL}/warehouse/reports/`);
  return response.data.results as Report[];
};

const copyReportToFolder = async (reportId: number, folderId: number) => {
  await axios.post(`${API_URL}/warehouse/reports/${reportId}/copy_to_folder/${folderId}/`);
  return undefined;
};

const createReport = (data: ReportFormResult) => {
  const formData = new FormData();
  if (data.logo) {
    formData.append('logo', data.logo, data.logo.name);
  }
  formData.append('json_data', JSON.stringify(omit(data, ['logo'])));
  return axios.post<Report>(`${API_URL}/warehouse/reports/`, formData, {
    headers: {
      'Content-Type': 'application/form-data',
    },
  });
};

const editReport = (data: ReportFormResult) => {
  const formData = new FormData();
  if (data.logo) {
    formData.append('logo', data.logo, data.logo?.name);
  }
  formData.append('json_data', JSON.stringify(omit(data, ['logo'])));
  return axios.put<Report>(`${API_URL}/warehouse/reports/${data.id}/`, formData, {
    headers: {
      'Content-Type': 'application/form-data',
    },
  });
};
const fetchReportFolderIndicators = async (key: string, id?: number) => {
  if (id) {
    const response = await axios.get(`${API_URL}/reports/folders/${id}/indicators/`);
    return response.data as ReportIndicator[];
  }
  return undefined;
};
const fetchReportFolderChildrens = async (key: string, id?: number) => {
  if (id) {
    const response = await axios.get(`${API_URL}/reports/folders/${id}/childrens/`);
    return response.data as ReportFolder;
  }
  return undefined;
};

export interface GenerateReportV2Request {
  reportId: number;
  administrativeUnitId?: number;
  administrativeGroupId?: number;
  territory?: boolean;
}

const generateReportV2 = ({
  administrativeGroupId,
  reportId,
  territory,
  administrativeUnitId,
}: GenerateReportV2Request) =>
  axios.post(`${API_URL}/reports/generatev2`, {
    report: reportId,
    administrative_unit: administrativeUnitId,
    territory,
    administrative_group: administrativeGroupId,
  });
const generateReport = (reportId: number, administrativeUnitId?: number, territory?: boolean) =>
  axios.post(`${API_URL}/reports/generate`, {
    report: reportId,
    administrative_unit: administrativeUnitId,
    territory: territory,
  });

const editReportContent = (reportId: number, content: ReportContent) =>
  axios.put(`${API_URL}/reports/generate`, { report: reportId, content: content });

const fetchGeneratedReports = (reportId: number) =>
  axios
    .get<ApiResults<GeneratedReport>>(`${API_URL}/reports/generated_reports/`, {
      params: { report: reportId },
    })
    .then((res) => res.data.results);

const fetchGeneratedReport = (reportId: number) =>
  axios.get(`${API_URL}/reports/generated_reports/${reportId}`, {}).then((res) => res.data as GeneratedReport);

const getFileName = (disposition: string): string | null => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string | null = null;
  if (utf8FilenameRegex.test(disposition)) {
    const matches = utf8FilenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = decodeURIComponent(matches[1]);
    }
  } else {
    const matches = asciiFilenameRegex.exec(disposition);
    if (matches != null && matches[2]) {
      fileName = matches[2];
    }
  }
  return fileName;
};

const refreshGeneratedReport = (generatedReportId: number, content?: ReportContent) =>
  axios
    .post(`${API_URL}/reports/generated_reports/${generatedReportId}/refresh/`, {
      content: content,
    })
    .then((res) => res.data.results);

const downloadGeneratedHtmlReport = (generatedReportId: number) =>
  axios
    .get(`${API_URL}/reports/generated_reports/${generatedReportId}/html`, {
      responseType: 'blob',
    })
    .then((response) => {
      const filename = getFileName(response.headers['content-disposition']) || 'raport.html';
      saveAs(response.data, filename);
    });

const downloadGeneratedPdfReport = (generatedReportId: number) =>
  axios
    .get(`${API_URL}/reports/generated_reports/${generatedReportId}/pdf`, {
      responseType: 'blob',
    })
    .then((response) => {
      const filename = getFileName(response.headers['content-disposition']) || 'raport.pdf';
      saveAs(response.data, filename);
    });

// Copy indicator category with all its contents to report folder
const copyCategoryToReportFolder = (categoryId: number, folderId: number) =>
  axios.post(`${API_URL}/reports/category_to_report_folder`, {
    category: categoryId,
    folder: folderId,
  });

export default {
  cube: CRUDActions<Cube>(`${API_URL}/warehouse/cubes`),
  cubeCategory: { ...CRUDActions<CubeCategory>(`${API_URL}/warehouse/categories`), tree: fetchCubeTree },
  indicator: {
    ...CRUDActions<Indicator>(`${API_URL}/warehouse/indicators`),
    copyToFolder: copyIndicatorToFolder,
    linkToFolder: linkIndicatorToFolder,
  },
  indicatorCategory: {
    ...CRUDActions<IndicatorCategory>(`${API_URL}/warehouse/folders`),
    indicators: fetchFolderIndicators,
    copyToFolder: copyIndicatorCategoryToFolder,
    fetchListDetails: fetchFolderListDetails,
  },
  report: {
    ...CRUDActions<Report>(`${API_URL}/warehouse/reports`),
    copyToFolder: copyReportToFolder,
    indicators: fetchIndicators,
    groupList: fetchAdministrativeGroups,
    formCreateReport: createReport,
    formEditReport: editReport,
    generate: generateReport,
    generatev2: generateReportV2,
    generatedReport: fetchGeneratedReport,
    generatedReportsList: fetchGeneratedReports,
    editContent: editReportContent,
    refreshGeneratedReport: refreshGeneratedReport,
    download: {
      html: downloadGeneratedHtmlReport,
      pdf: downloadGeneratedPdfReport,
    },
  },
  reportFolder: {
    ...CRUDActions<ReportCategory>(`${API_URL}/reports/folders`),
    reports: fetchFolderReports,
    indicators: fetchReportFolderIndicators,
    childrens: fetchReportFolderChildrens,
    copyToFolder: copyFolderToFolder,
    copyCategoryToReportFolder,
  },
  reportIndicator: {
    ...CRUDActions<ReportIndicator>(`${API_URL}/reports/indicators`),
  },
  reportCategory: {
    ...CRUDActions<ReportCategory>(`${API_URL}/warehouse/folders`),
    reports: fetchFolderReports,
  },
  administrativeGroup: { ...CRUDActions<AdministrativeGroup>(`${API_URL}/groups`), units: fetchGroupUnits },
  groupList: { ...CRUDActions<AdministrativeGroupList>(`${API_URL}/group_lists`), groups: fetchListGroups },
  territoryGroup: { ...CRUDActions<TerritoryGroup>(`${API_URL}/territories`), units: fetchTerritoryUnits },
  territoryList: {
    ...CRUDActions<TerritoryGroupList>(`${API_URL}/territory_lists`),
    territories: fetchListTerritories,
  },
  administrativeUnit: {
    ...CRUDActions<AdministrativeUnit>(`${API_URL}/units`),
    children: fetchChildUnits,
    topLevel: fetchTopLevelUnits,
  },
};
