import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import Title from '../../shared/components/Title';
import GenericButton from '../../shared/components/GenericButton';
import IndicatorAnalysisModal from './IndicatorAnalysis/IndicatorAnalysisModal';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import warehouseAPI from '../../shared/api';
import { Indicator } from '../../shared/types';
import IndicatorToFolderCopyButton from '../IndicatorEditor/IndicatorToFolderCopyButton/index';
import IndicatorToFolderLinkButton from '../IndicatorEditor/IndicatorToFolderLinkButton/index';

const useStyles = makeStyles({
  button: {
    marginLeft: '10px',
  },
});

interface Props {
  indicator?: Indicator;
}

function Header(props: Props) {
  const { indicator } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [mutate] = useMutation(warehouseAPI.indicator.delete);
  const queryCache = useQueryCache();

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  return indicator ? (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end">
        <Link to={`/indicators/${indicator.id}/edit`}>
          <GenericButton variant="primary" disabled={false}>
            Edytuj
          </GenericButton>
        </Link>
        <GenericButton
          disabled={!indicator.formula}
          className={classes.button}
          variant="primary"
          onClick={() => setOpen(true)}
        >
          Analizuj
        </GenericButton>
        <IndicatorToFolderCopyButton />
        <IndicatorToFolderLinkButton />
        <GenericButton className={classes.button} variant="destructiveSecondary" onClick={() => setOpenDelete(true)}>
          Usuń
        </GenericButton>
        <IndicatorAnalysisModal indicator={indicator} open={open} onClose={() => setOpen(false)} />
        <ConfirmationModal
          open={openDelete}
          title="Usuń wskaźnik"
          message="Czy na pewno usunąć wskaźnik?"
          onConfirm={async () => {
            if (indicator) {
              try {
                await mutate(indicator.id);
                await queryCache.invalidateQueries(['fetchFolderIndicators', 'fetchIndicatorTree']);
                history.push('/indicators');
                setOpenDelete(false);
              } catch (e) {
                enqueueSnackbar('Błąd przy usuwaniu wskaźnika', { variant: 'error' });
              }
            }
          }}
          onCancel={() => setOpenDelete(false)}
        />
      </Box>
      <Box paddingTop={1}>
        <Title variant="h1" title={indicator.name} />
      </Box>
    </Box>
  ) : (
    <Title variant="h2" title="Wybierz wskaźnik aby zobaczyć podgląd" />
  );
}

export default Header;
