import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CubeBasedComponent, IndicatorBasedComponent, IndicatorComponent } from '../../shared/types';
import CubeCard from './CubeCard';
import IndicatorCard from './IndicatorCard';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '6px',
    },
    summaryRoot: {
      borderRadius: '6px',
      backgroundColor: theme.colors.blue,
      color: theme.colors.white,
      minHeight: '50px',
      paddingLeft: '20px',
    },
    summaryExpanded: {
      backgroundColor: theme.colors.offWhite,
      color: theme.colors.black,
      borderRadius: '6px 6px 0px 0px',
    },
    icon: {
      background: 'none',
    },
    name: {
      marginLeft: '15px',
    },
    list: {
      padding: 0,
    },
    button: {
      color: 'inherit',
    },
    label: {
      color: theme.colors.darkGray,
      fontSize: '13px',
      fontWeight: 400,
    },
    value: {
      color: theme.colors.black,
      fontSize: '13px',
      fontWeight: 600,
    },
  }),
);

interface Props {
  component: IndicatorComponent;
  index: number;
  showControls?: boolean;
}

function ComponentCard(props: Props) {
  const { component, index, showControls = false } = props;

  return component.type === 'CUBE' ? (
    <CubeCard component={component as CubeBasedComponent} index={index} showControls={showControls} />
  ) : (
    <IndicatorCard component={component as IndicatorBasedComponent} index={index} showControls={showControls} />
  );
}

export default ComponentCard;
