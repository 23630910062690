import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';
import { useQuery } from 'react-query';
import warehouseAPI from '../../shared/api';
import useIndicatorStore from '../indicatorStore';
import ParentTreeItem from './ParentTreeItem';
import DimensionValuesItem from './DimensionValuesItem';
import { CubeBasedComponent } from '../../shared/types';
import { useStyles } from './index';

interface Props {
  component: CubeBasedComponent;
  showControls?: boolean;
  index: number;
}

function CubeCard(props: Props) {
  const { component, showControls, index } = props;

  const { sourceCube, dimensionValues, measure, label, extrapolated, aggregationLevel } = component;

  const { data: cube } = useQuery(['fetchCubeDetails', sourceCube], () => warehouseAPI.cube.fetchDetails(sourceCube));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const setActiveComponent = useIndicatorStore((state) => state.setActiveComponent);
  const removeComponent = useIndicatorStore((state) => state.removeIndicatorComponent);

  const handleEdit = (event: any) => {
    event.stopPropagation(); // don't collapse card on click
    setActiveComponent(component);
    setAnchorEl(null);
  };

  const handleRemove = (event: any) => {
    event.stopPropagation(); // don't collapse card on click
    removeComponent(index);
    setAnchorEl(null);
  };

  return cube ? (
    <Accordion className={classes.root} defaultExpanded variant="outlined">
      <AccordionSummary
        classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded }}
        aria-label="Expand"
        expandIcon={<ExpandMoreIcon />}
      >
        <Box display="flex" alignContent="flex-start" alignItems="center">
          {showControls && (
            <IconButton
              className={classes.button}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            >
              <Icon>
                <MoreVertIcon />
              </Icon>
            </IconButton>
          )}
          <Typography variant="h2">{label}</Typography>
          <Typography variant="h2" className={classes.name}>
            {cube.name}
          </Typography>
        </Box>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleEdit}>Edytuj</MenuItem>
          <MenuItem onClick={handleRemove}>Usuń</MenuItem>
        </Menu>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <List className={classes.list}>
              <ParentTreeItem category name={cube.sourceType} index={0} />
              {cube.parents.map((parent, parentIndex) => (
                <ParentTreeItem key={parent.id} category name={parent.name} index={parentIndex + 1} />
              ))}
              <ParentTreeItem category={false} name={cube.name} index={cube.parents.length} />
            </List>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              {dimensionValues.map((dimensionValue) => {
                const sourceDimension = _.find(cube.dimensions, { id: dimensionValue.dimension });
                return (
                  sourceDimension && (
                    <DimensionValuesItem
                      key={sourceDimension.id}
                      dimension={sourceDimension}
                      value={dimensionValue.value}
                    />
                  )
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Typography className={classes.label}>Poziom agregacji</Typography>
            <Typography className={classes.value}>{`KTS-${aggregationLevel}${
              extrapolated ? ' (ekstrapolowany)' : ''
            }`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Miara</Typography>
            <Typography className={classes.value}>
              {measure && cube.measures.find(({ id }) => id === measure)?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.label}>Dostępność w latach</Typography>
            <Typography className={classes.value}>{cube.yearsAvailability?.join(', ')}</Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null;
}

export default CubeCard;
