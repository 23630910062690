import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Title from '../../shared/components/Title';
import CubeCategoryListItem from './CubeCategoryListItem';
import { Cube, CubeTreeSource } from '../../shared/types';
import { sortByNativeId } from '../../shared/utils/helpers';

interface Props {
  sourceTree: CubeTreeSource;
  onCubeClick?: (cube: Cube) => void;
}

export default function CubeSourceTree({ sourceTree: { name, categories }, onCubeClick }: Props) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText>
          <Title variant="h3" title={name} />
        </ListItemText>
        {open ? <ExpandMoreIcon /> : <ChevronLeftIcon />}
      </ListItem>
      <Collapse in={open} timeout={100} unmountOnExit>
        {sortByNativeId(categories).map((category) => (
          <CubeCategoryListItem key={category.id} category={category} onCubeClick={onCubeClick} />
        ))}
      </Collapse>
    </>
  );
}
