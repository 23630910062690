import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import LeftSiderLayout from '../../shared/components/LeftSiderLayout';
import ContentPanel from './ContentPanel';
import Sider from './Sider';
import useIndicatorStore from '../indicatorStore';
import warehouseAPI from '../../shared/api';

function IndicatorEditor() {
  const { id } = useParams();

  const [setActiveIndicator, editIndicator, editNewIndicator] = useIndicatorStore((state) => [
    state.setActiveIndicator,
    state.editIndicator,
    state.editNewIndicator,
  ]);

  const { data: indicator } = useQuery(['fetchIndicatorDetails', id], () => warehouseAPI.indicator.fetchDetails(id));

  useEffect(() => {
    if (indicator) {
      setActiveIndicator(indicator);
      editIndicator(indicator);
    }
    if (!id) {
      editNewIndicator();
    }
  }, [indicator, id, setActiveIndicator, editIndicator, editNewIndicator]);

  return <LeftSiderLayout sider={<Sider />} content={<ContentPanel />} />;
}

export default IndicatorEditor;
