import React from 'react';
import CubeTree from '../../DataBrowser/CubeTree';
import IndicatorTree from '../IndicatorTree';
import useIndicatorStore from '../indicatorStore';

function Sider() {
  const setActiveComponent = useIndicatorStore((state) => state.setActiveComponent);

  return (
    <>
      <CubeTree
        onCubeClick={(cube) =>
          setActiveComponent({
            type: 'CUBE',
            sourceCube: cube.id,
            label: '',
            measure: cube.measures[0].id,
          })
        }
      />
      <IndicatorTree
        onIndicatorClick={(indicator) =>
          setActiveComponent({
            type: 'INDICATOR',
            sourceIndicator: indicator.id,
            label: '',
          })
        }
      />
    </>
  );
}

export default Sider;
