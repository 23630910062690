import React from 'react';
import { List } from '@material-ui/core';
import { useQuery } from 'react-query';
import GroupListItem from './GroupListItem';
import { AdministrativeGroup, AdministrativeGroupList } from '../../shared/types';
import warehouseAPI from '../../shared/api';

interface Props {
  currentGroup: AdministrativeGroup | null;
  setCurrentGroup: React.Dispatch<any>;
  currentList: AdministrativeGroupList | null;
  setCurrentList: React.Dispatch<any>;
  setIsTerritory: React.Dispatch<any>;
  type: string | null;
}

function GroupList(props: Props) {
  const { currentGroup, setCurrentGroup, currentList, setCurrentList, setIsTerritory, type } = props;

  const { data: lists } = useQuery('fetchAdministrativeGroupLists', warehouseAPI.groupList.fetchList);

  return (
    <List>
      {lists &&
        lists.map((list, index) => (
          <GroupListItem
            key={list.id}
            list={list}
            type={type}
            currentList={currentList}
            setCurrentList={setCurrentList}
            currentGroup={currentGroup}
            setCurrentGroup={setCurrentGroup}
            setIsTerritory={setIsTerritory}
            initialOpen={index === 0}
          />
        ))}
    </List>
  );
}

export default GroupList;
