import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid, Typography } from '@material-ui/core';
import { FieldArray } from 'formik';
import _ from 'lodash';
import { Cube, ResourceStatus } from '../../shared/types';
import SelectInput from '../../shared/components/forms/SelectInput';
import dayjs from 'dayjs';

type Props = {
  cube: Cube;
};

function CubeForm(props: Props) {
  const { cube } = props;

  const translateCubeStatus = (status: ResourceStatus) => {
    switch (status) {
      case ResourceStatus.active: {
        return 'Aktywny';
      }
      case ResourceStatus.inactive: {
        return 'Nieaktywny';
      }

      case ResourceStatus.needsUpdate: {
        return 'Do zaktualizowania';
      }
      case ResourceStatus.error: {
        return 'Błąd importu';
      }
    }
  };

  return (
    <>
      {cube.description && (
        <Grid item xs={12}>
          <Typography variant="body2">Opis</Typography>
          <Typography variant="subtitle2">{cube.description}</Typography>
        </Grid>
      )}
      {cube.status && (
        <Grid item xs={12}>
          <Typography variant="body2">Status</Typography>
          <Typography variant="subtitle2">{translateCubeStatus(cube.status)}</Typography>
        </Grid>
      )}

      {cube.lastDataImport && (
        <Grid item xs={12}>
          <Typography variant="body2">Data ostatniej aktualizacji</Typography>
          <Typography variant="subtitle2">{dayjs(cube.lastDataImport).format('YYYY/MM/DD HH:mm')}</Typography>
        </Grid>
      )}
      {cube.yearsAvailability && (
        <Grid item xs={12}>
          <Typography variant="body2">Dostępność w latach</Typography>
          <Typography variant="subtitle1">{cube.yearsAvailability.join(', ')}</Typography>
        </Grid>
      )}
      {cube.dimensions && (
        <FieldArray
          name="dimensionValues"
          render={() =>
            cube.dimensions
              .filter((dimension) => dimension.type === 'CATEGORICAL')
              .map(({ id, name, values }, index) => {
                return (
                  <Grid item xs={12} key={id}>
                    <SelectInput fieldId={`dimension-${id}`} name={`dimensionValues.${index}.value`} label={name}>
                      {_.sortBy(values).map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </SelectInput>
                  </Grid>
                );
              })
          }
        />
      )}
      {window.location.href.includes('/add') && (
        <Grid item xs={12} key="miary">
          <SelectInput fieldId="measure-select" name="measures" label="Miary">
            {cube.measures.map((measure) => (
              <MenuItem key={measure.id} value={measure.id}>
                {measure.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
      )}
    </>
  );
}

export default CubeForm;
