import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { createStyles, Typography, Tooltip } from '@material-ui/core';
import moment from 'moment';
import Title from '../../../shared/components/Title';
import { Indicator } from '../../../shared/types';
import { indicatorCharacters, partnershipValueTypes } from '../../../shared/utils/helpers';
import warehouseAPI from '../../../shared/api';
import { useQuery } from 'react-query';

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
  first: {
    marginTop: '20px',
  },
  rest: {
    marginTop: '30px',
  },
  header: {
    color: 'grey',
  },
});

const Content = withStyles((theme) =>
  createStyles({
    root: {
      marginTop: '8px',
      fontSize: '15px',
      letterSpacing: '0px',
      color: theme.colors.black,
    },
  }),
)(Typography);

interface Props {
  indicator: Indicator;
}

function IndicatorDetails(props: Props) {
  const { indicator } = props;
  const classes = useStyles();

  const { data: folders } = useQuery(['fetchIndicatorCategory', indicator.folders], () => {
    if (indicator.folders) {
      return warehouseAPI.indicatorCategory.fetchListDetails(indicator.folders);
    }
    return [];
  });

  return (
    indicator && (
      <div className={classes.content}>
        <Title variant="h2" title="Szczegóły wskaźnika" />
        <div className={classes.first}>
          <Title variant="h4" title="Nazwa wskaźnika" className={classes.header} />
          <Content>{indicator.name}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Nazwa skrócona" className={classes.header} />
          <Content>{indicator.shortName}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Foldery" className={classes.header} />
          {folders &&
            folders.map((folder) => (
              <Tooltip title={folder.parentPath ? folder.parentPath.map((parent) => parent.name).join('/') : ''}>
                <Content>{folder.name}</Content>
              </Tooltip>
            ))}
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Opis" className={classes.header} />
          <Content>{indicator.description}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Charakter" className={classes.header} />
          <Content>
            {indicatorCharacters.find((indicatorCharacter) => indicatorCharacter.value === indicator.character)?.label}
          </Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Autor" className={classes.header} />
          <Content>{indicator.author?.username}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Data utworzenia" className={classes.header} />
          <Content>{moment(indicator.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Data modyfikacji" className={classes.header} />
          <Content>{moment(indicator.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Dostępność w latach" className={classes.header} />
          <Content>{indicator.yearsAvailability?.join(', ')}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Miara" className={classes.header} />
          <Content>{indicator.measure}</Content>
        </div>
        <div className={classes.rest}>
          <Title variant="h4" title="Włączony do wskaźnika syntetycznego" className={classes.header} />
          <Content>{indicator.includedInSynthetic ? 'Tak' : 'Nie'}</Content>
        </div>
        {indicator.partnershipValueType && (
          <div className={classes.rest}>
            <Title variant="h4" title="Typ wartości dla partnerstwa" className={classes.header} />
            <Content>
              {partnershipValueTypes.find((valueType) => valueType.value === indicator.partnershipValueType)?.label}
            </Content>
          </div>
        )}
      </div>
    )
  );
}

export default IndicatorDetails;
