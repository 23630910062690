import React from 'react';
import { useQuery } from 'react-query';
import warehouseAPI from '../../../shared/api';

import FolderListItem from './FolderListItem';

type Props = {
  folderId: number;
  allowEmptySelection?: boolean;
};

const ReportStructure: React.FC<Props> = ({ folderId }) => {
  const { data: folder } = useQuery(['fetchReportFolder', folderId], warehouseAPI.reportFolder.childrens);

  return <>{folder && <FolderListItem key={folder.id} folder={folder} />}</>;
};

export default ReportStructure;
