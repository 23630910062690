import React from 'react';
import { useField } from 'formik';
import { Grid } from '@material-ui/core';
import Unit from './Unit';

function ChosenUnits() {
  const [field] = useField('administrativeUnits');

  return (
    <div style={{ overflow: 'auto' }}>
      <Grid container>
        {field.value.map((unitId: number) => (
          <Grid key={unitId} item xs={12}>
            <Unit unitId={unitId} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
export default ChosenUnits;
