import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';
import { Indicator, IndicatorComponent } from '../shared/types';

type IndicatorStore = {
  activeIndicator?: Indicator;
  setActiveIndicator: (indicator: Indicator) => void;
  editorComponents: IndicatorComponent[];
  addIndicatorComponent: (component: IndicatorComponent) => void;
  removeIndicatorComponent: (id: number) => void;
  isChanged: boolean;
  editNewIndicator: () => void;
  editIndicator: (indicator: Indicator) => void;
  activeComponent?: IndicatorComponent;
  setActiveComponent: (component: IndicatorComponent) => void;
};

const useIndicatorStore = create<IndicatorStore>(
  devtools((set) => ({
    activeIndicator: undefined,
    setActiveIndicator: (indicator) => set(() => ({ activeIndicator: indicator })),
    editorComponents: [],
    addIndicatorComponent: (component) =>
      set(
        produce((state) => {
          if (component.label) {
            state.editorComponents = state.editorComponents.map((editorComponent: IndicatorComponent) => {
              if (editorComponent.label === component.label) {
                return component;
              }
              return editorComponent;
            });
          } else {
            const label = `C${state.editorComponents.length + 1}`;
            state.editorComponents.push({ ...component, label });
          }
          state.isChanged = true;
        }),
      ),
    removeIndicatorComponent: (index: number) =>
      set(
        produce((state) => {
          state.editorComponents.splice(index, 1);
          state.isChanged = true;
        }),
      ),
    isChanged: false,
    editNewIndicator: () =>
      set(() => ({
        editorComponents: [],
        isChanged: false,
      })),
    editIndicator: (indicator) => set(() => ({ editorComponents: indicator.components, isChanged: false })),
    activeComponent: undefined,
    setActiveComponent: (component: IndicatorComponent) => set(() => ({ activeComponent: component })),
  })),
);

export default useIndicatorStore;
