import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../shared/components/Title';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  leftHeader: {
    width: '80%',
  },
  rightHeader: {
    width: '20%',
    display: 'flex',
  },
  button: {
    marginRight: '10px',
  },
  icon: {
    width: '100%',
    paddingBottom: '4px',
  },
});

function Header() {
  const classes = useStyles();

  const { id } = useParams();

  return (
    <div className={classes.root}>
      <div className={classes.leftHeader}>
        <Title variant="h1" title={id ? 'Edycja raportu' : 'Tworzenie nowego raportu'} />
      </div>
    </div>
  );
}

export default Header;
