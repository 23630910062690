import React from 'react';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, createStyles, Grid, MenuItem, Theme, Typography } from '@material-ui/core';
import Title from '../../shared/components/Title';
import GenericButton from '../../shared/components/GenericButton';
import SelectInput from '../../shared/components/forms/SelectInput';
import DataTable from '../../shared/components/DataTable';
import { Cube, TableData } from '../../shared/types';
import useGroupStore from '../../Groups/groupStore';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    info: {
      marginLeft: '10px',
      color: theme.colors.darkGray,
    },
    title: {
      marginBottom: '25px',
    },
  }),
);

interface Props {
  table: TableData | null;
  cube: Cube;
}

function CubeAnalysis(props: Props) {
  const { cube, table } = props;

  const { submitForm, isValid, isSubmitting } = useFormikContext();

  const groupIsSelected = useGroupStore((state) => Boolean(state.currentGroupId));

  const classes = useStyles();

  const saveExcel = (cubeName: string) => {
    if (table !== null) {
      const data = [table?.header, ...table?.rows];
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Dane');
      XLSX.writeFile(workbook, `${cubeName.replace(' ', '_')}.xlsx`);
    }
  };

  return (
    <>
      <Title className={classes.title} variant="h2" title="Analiza" />
      <Grid container spacing={3} style={{ overflow: 'hidden' }}>
        <Grid item xs={6}>
          <SelectInput multiple fieldId="years" label="Lata" name="years" placeholder="Wybierz lata">
            {cube.yearsAvailability?.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item xs={6}>
          <SelectInput fieldId="measure" label="Miara" name="measure" placeholder="Wybierz miarę">
            {cube.measures.map((measure) => (
              <MenuItem key={measure.id} value={measure.id}>
                {measure.name}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>
        <Grid item xs={12}>
          <GenericButton variant="primary" disabled={!isValid || !groupIsSelected || isSubmitting} onClick={submitForm}>
            {isSubmitting ? <CircularProgress size={24} /> : 'Analizuj'}
          </GenericButton>
          {!isValid && (
            <Typography className={classes.info} variant="body1" component="span">
              Wybierz konfigurację, którą chcesz wyświetlić
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <GenericButton variant="primary" disabled={!table} onClick={() => saveExcel(cube.name)}>
            Pobierz xlsx
          </GenericButton>
        </Grid>
        <Grid item xs={12} style={{ maxHeight: 'calc(70vh - 70px)', overflow: 'auto' }}>
          {table && <DataTable data={table} />}
        </Grid>
      </Grid>
    </>
  );
}

export default CubeAnalysis;
