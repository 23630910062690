import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, createStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import { IndicatorComponent } from '../../../shared/types';
import ComponentCard from '../../IndicatorComponentCard';
import Title from '../../../shared/components/Title';
import GenericButton from '../../../shared/components/GenericButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    token: {
      minWidth: '40px',
      height: '40px',
      marginRight: '5px',
    },
    tokenContainer: {
      minHeight: '60px',
      borderRadius: '6px',
      marginBottom: '20px',
      overflow: 'auto',
    },
  }),
);

interface Props {
  components: IndicatorComponent[];
  formula: string;
}

function IndicatorComponents(props: Props) {
  const { components, formula } = props;

  const classes = useStyles();

  return (
    <>
      <Title variant="h2" title="Formuła" />
      <Box className={classes.tokenContainer} display="flex" justifyContent="flex-start" alignItems="center">
        {!_.isEmpty(formula) &&
          formula.split(' ').map((token, index) => (
            <GenericButton
              key={`${token}-${index}`}
              className={classes.token}
              variant={token.match(/C\d+/) ? 'secondary' : 'tertiary'}
            >
              {token}
            </GenericButton>
          ))}
      </Box>
      <Title variant="h2" title="Składowe" />
      {components.map((component, index) => (
        <ComponentCard key={component.label} index={index} component={component} />
      ))}
    </>
  );
}

export default IndicatorComponents;
