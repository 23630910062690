import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tnt from '../assets/tnt_logo.svg';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    marginBottom: '15px',
    marginTop: '15px',
    color: '#BDBEC1',
    fontSize: '10px',
    fontWeight: 400,
    height: '20px',
  },
  logo: {
    marginLeft: '10px',
  },
});

function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      Monitor Rozwoju by
      <img className={classes.logo} src={Tnt} alt="TEONITE logo" />
    </Box>
  );
}

export default Footer;
