export type User = {
  username: string;
};

enum SourceType {
  GUS = 'GUS',
  // eslint-disable-next-line camelcase
  GUS_demography = 'GUS_demography',
  MF = 'MF',
  POLTAX = 'POLTAX',
}

export enum ResourceStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  needsUpdate = 'NEEDS_UPDATE',
  error = 'ERROR',
}

export interface Category {
  id?: number;
  name: string;
  description: string;
}

export interface CubeCategory extends Category {
  children: Array<CubeCategory>;
  cubesCount: number;
  nativeId: string;
  sourceType: SourceType;
  status: ResourceStatus;
  parent: CubeCategory;
}

export interface CubeTreeSource {
  name: string;
  categories: CubeCategory[];
}

export interface Dimension {
  id: number;
  name: string;
  values: string[];
  isAggregable: boolean;
  type: 'TIME' | 'LOCATION' | 'CATEGORICAL';
}

export interface DimensionValue {
  dimension: number;
  value: string;
}

export interface Measure {
  id: number;
  name: string;
}

export interface Cube {
  id: number;
  name: string;
  description: string;
  sourceType: SourceType;
  status: ResourceStatus;
  lastDataImport: string;
  category: number;
  dimensions: Dimension[];
  measures: Measure[];
  parents: CubeCategory[];
  aggregationLevel: number;
  yearsAvailability?: number[];
}

export interface IndicatorCategory extends Category {
  children?: Array<IndicatorCategory>;
  parent?: number;
  parentPath?: IndicatorCategory[];
  indicatorCount?: number;
}

export interface ReportIndicator {
  id?: number;
  name: string;
  description: string;
  shortName: string;
  folder: ReportFolder | number;
  type: string;
  sourceIndicator?: number | null;
  sourceCube?: number | null;
  dimensionValues?: DimensionValue[];
  measure?: number;
  extrapolated?: boolean;
  precision?: number;
  hideForPartnerhsip?: boolean;
}

export interface Indicator {
  id?: number;
  name: string;
  shortName: string;
  folders?: number[];
  description: string;
  components: IndicatorComponent[];
  parents: IndicatorCategory[];
  formula: string;
  precision?: number;
  measure: string;
  author?: User;
  createdAt?: string;
  updatedAt?: string;
  aggregationLevel?: number;
  yearsAvailability?: number[];
  character: string;
  includedInSynthetic: boolean;
  partnershipValueType?: string;
  copyMissingValues: boolean;
}

export interface IndicatorComponent {
  id?: number;
  type: 'CUBE' | 'INDICATOR';
  sourceCube?: number;
  sourceIndicator?: number;
  dimensionValues?: DimensionValue[];
  measure?: number;
  label?: string;
  aggregationLevel?: number;
}

export interface CubeBasedComponent extends IndicatorComponent {
  type: 'CUBE';
  sourceCube: number;
  sourceIndicator: never;
  dimensionValues: DimensionValue[];
  measure: number;
  label: string;
  extrapolated: boolean;
  aggregationLevel: number;
}

export interface IndicatorBasedComponent extends IndicatorComponent {
  type: 'INDICATOR';
  sourceCube: never;
  sourceIndicator: number;
  label: string;
}

export interface IndicatorComponentSource {
  name: string;
  dimensions: Dimension[];
  parents: Category[];
  aggregationLevel: number;
  yearsAvailability: number[];
}

enum AdministrativeUnitType {
  COUNTRY = 'COUNTRY',
  MACROREGION = 'MACROREGION',
  VOIEVODSHIP = 'VOIEVODSHIP',
  REGION = 'REGION',
  SUBREGION = 'SUBREGION',
  POWIAT = 'POWIAT',
  POWIAT_CITY = 'POWIAT_CITY',
  URBAN_GMINA = 'URBAN_GMINA',
  RURAL_GMINA = 'RURAL_GMINA',
  MIXED_GMINA = 'MIXED_GMINA',
  CITY = 'CITY',
  URBAN_AREA = 'URBAN_AREA',
  RURAL_AREA = 'RURAL_AREA',
  DELEGATION = 'DELEGATION',
  DISTRICT = 'DISTRICT',
}

export interface AdministrativeUnit {
  id: number;
  auid: string;
  name: string;
  type: AdministrativeUnitType;
  KTScode: string;
  TERYTCode?: string;
  hasChildren: boolean;
  historicalTerytCodes: Array<AdministrativeUnit>;
  effectiveUntil: string;
  comment?: string;
}

export interface AdministrativeGroup {
  id?: number;
  name: string;
  description: string;
  administrativeUnits: Array<AdministrativeUnit>;
  territoryUnits?: Array<TerritoryGroup>;
  unitsCount?: number;
}

export interface AdministrativeGroupList {
  id?: number;
  name: string;
  description: string;
  groupsCount?: number;
  administrativeUnits?: AdministrativeUnit[];
}

export interface TerritoryGroup {
  id?: number;
  name: string;
  description: string;
  administrativeUnits: Array<AdministrativeUnit>;
  unitsCount?: number;
}
export interface TerritoryGroupList {
  id?: number;
  name: string;
  description: string;
  groupsCount?: number;
  administrativeUnits?: AdministrativeUnit[];
}

export interface TableData {
  header: Array<string>;
  rows: Array<Array<string | number>>;
}

export interface ReportFolder extends IndicatorCategory {}

export interface Report {
  id?: number;
  name: string;
  description?: string;
  folder: ReportFolder;
  status: string;
  yearsRange?: number[];
  administrativeUnit: AdministrativeUnit;
  indicatorList: Array<Indicator>;
  logo?: string;
}

export interface ReportFormResult {
  id?: number;
  name: string;
  description?: string;
  folder?: number;
  indicator_category?: number;
  years_range: number[];
  logo?: File;
}

export interface ReportList {
  reports: Array<Report>;
}

export interface ReportCategory extends Category {
  reportCount?: number;
}

export interface SubArea {
  name: string;
  plot?: string;
  description?: string;
  tableHeader?: number[];
  tableRows?: number[];
  descriptions: string[];
}

export interface Area {
  name: string;
  description?: string;
  subareas: SubArea[];
}

export interface ReportContent {
  landscape: boolean;
  areas: Area[];
  pageTitle: string;
  title: string[];
}

export interface GeneratedReport {
  id: number;
  hasHtml: boolean;
  hasPdf: boolean;
  updatedAt: string;
  createdBy: User;
  tableName: string;
  status: string;
  evaluationTime: string;
  group?: {
    id: number;
    name: string;
  };
  administrativeUnitName?: string;
  content?: ReportContent;
  error?: string;
}

export interface ApiResults<T> {
  count: number;
  next?: number;
  pervious?: number;
  results: T[];
}
