import React, { useState } from 'react';
import { Box, Collapse, IconButton, ListItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { TerritoryGroup, TerritoryGroupList } from '../../shared/types';
import warehouseAPI from '../../shared/api';
import TerritoryEditorModal from '../TerritoryEditorModal';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupList: {
      color: theme.colors.black,
      fontWeight: 600,
      fontSize: '20px',
      letterSpacing: '0px',
      width: 'auto',
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '15px',
      letterSpacing: '0px',
      color: theme.colors.darkGray,
      opacity: 1,
      position: 'relative',
      width: 'auto',
    },
    territoriesCount: {
      background: `${theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      opacity: 1,
      width: '27px',
      textAlign: 'center',
      color: theme.colors.darkGray,
    },
    activeItem: {
      color: theme.colors.blue,
    },
    addButton: {
      '&:hover': {
        color: theme.colors.blue,
      },
    },
  }),
);

interface Props {
  list: TerritoryGroupList;
  currentTerritory: TerritoryGroup | null;
  setCurrentGroup: React.Dispatch<any>;
  currentList: TerritoryGroupList | null;
  setCurrentList: React.Dispatch<any>;
  setIsTerritory: React.Dispatch<any>;
  initialOpen: boolean;
  type: string | null;
}

function TerritoryListItem(props: Props) {
  const {
    list: { name, groupsCount, id },
    currentTerritory,
    setCurrentGroup,
    currentList,
    setCurrentList,
    setIsTerritory,
    initialOpen = false,
    type,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(initialOpen);
  const [openEditor, setEditorOpen] = useState(false);
  const [editorTerritory, setEditorTerritory] = useState<TerritoryGroup | undefined>(undefined);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const hasTerritories = Boolean(groupsCount && groupsCount > 0);
  const { data: territories } = useQuery(
    ['fetchListTerritories', id],
    () => warehouseAPI.territoryList.territories(id),
    {
      enabled: open,
    },
  );
  const [mutate] = useMutation(warehouseAPI.territoryGroup.delete);
  const queryCache = useQueryCache();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => setOpen(!open);

  const handleTerritoryChoice = (territory: TerritoryGroup, list: TerritoryGroupList) => {
    setIsTerritory(true);
    setCurrentGroup(territory);
    setCurrentList(list);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ListItem
          className={`${classes.groupList} ${type && currentList?.id === id && classes.activeItem}`}
          button
          onClick={type ? () => setCurrentList({ name, groupsCount, id } as TerritoryGroupList) : handleClick}
        >
          {name}
          <Typography className={classes.territoriesCount} variant="caption">
            {groupsCount}
          </Typography>
        </ListItem>
        <IconButton
          onClick={() => {
            setEditorOpen(true);
          }}
          size="small"
        >
          {!type && <AddCircleOutlineIcon className={classes.addButton} />}
        </IconButton>
      </Box>
      {hasTerritories && !type && (
        <Collapse in={open} timeout={100} unmountOnExit>
          {_.isEmpty(territories)
            ? _.range(groupsCount as number).map((index) => <Skeleton key={index} animation="wave" />)
            : (territories as TerritoryGroup[]).map((territory) => (
                <Box key={territory.id} display="flex" justifyContent="flex-start" alignItems="center">
                  <ListItem
                    className={`${classes.listItem} ${currentTerritory?.id === territory.id && classes.activeItem}`}
                    button
                    onClick={() => handleTerritoryChoice(territory, { name, groupsCount, id } as TerritoryGroupList)}
                  >
                    {territory.name}
                  </ListItem>
                  {currentTerritory?.id === territory.id && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEditorTerritory(territory);
                        setEditorOpen(true);
                      }}
                    >
                      <EditOutlinedIcon color="primary" fontSize="small" />
                    </IconButton>
                  )}
                  {currentTerritory?.id === territory.id && (
                    <IconButton
                      onClick={() => {
                        setOpenConfirmModal(true);
                      }}
                      size="small"
                    >
                      <DeleteOutlinedIcon className={classes.addButton} />
                    </IconButton>
                  )}
                </Box>
              ))}
        </Collapse>
      )}

      {id && (
        <TerritoryEditorModal
          open={openEditor}
          onClose={() => setEditorOpen(false)}
          listId={id}
          territory={editorTerritory}
        />
      )}
      {currentTerritory?.id && (
        <ConfirmationModal
          open={openConfirmModal}
          title="Usuń obszar"
          message="Czy na pewno usunąć obszar?"
          onConfirm={async () => {
            if (currentTerritory?.id) {
              try {
                await mutate(currentTerritory?.id);
                await queryCache.invalidateQueries('fetchListTerritories');
                setOpenConfirmModal(false);
              } catch (e) {
                enqueueSnackbar('Błąd przy usuwaniu obszaru', { variant: 'error' });
              }
            }
          }}
          onCancel={() => setOpenConfirmModal(false)}
        />
      )}
    </>
  );
}

export default TerritoryListItem;
