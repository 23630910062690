import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, Tooltip, Typography, Menu, MenuItem } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryCache } from 'react-query';
import { ReportFolder, ReportIndicator } from '../../../shared/types';
import IndicatorList from './IndicatorList';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import warehouseAPI from '../../../shared/api';
import { sortByName } from '../../../shared/utils/helpers';
import FolderEditorModal from '../../Modals/FolderEditorModal';
import { AddOutlined } from '@material-ui/icons';
import ReportIndicatorForm from './ReportIndicatorForm/ReportIndicatorForm';
import CopyFolderModal from '../../Modals/CopyFolderModal';
import IndicatorCategoryCopyModal from '../../Modals/IndicatorCategoryCopyModal';

type StyleProps = {
  open: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.colors.black,
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '13px',
      letterSpacing: '0px',
      color: (props: StyleProps) => (props.open ? theme.colors.black : theme.colors.darkGray),
      opacity: 1,
      position: 'relative',
      fontFamily: 'Poppins',
      '&:hover': {
        color: theme.colors.black,
      },
    },
    cubesCount: {
      background: (props: StyleProps) =>
        `${props.open ? theme.colors.darkGray : theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      opacity: 1,
      width: '27px',
      textAlign: 'center',
      color: (props: StyleProps) => (props.open ? theme.colors.white : theme.colors.darkGray),
    },
    active: {
      color: theme.colors.blue,
    },
    deleteButton: {
      padding: 0,
    },
    editButton: {
      padding: 0,
    },
    copyButton: {
      padding: 0,
    },
  }),
);

type Props = {
  folder: ReportFolder;
  showIndicators?: boolean;
  onIndicatorClick?: (indicator: ReportIndicator) => void;
  showMenu?: boolean;
  setActive?: (id: number | null) => void;
  activeId?: number;
};

const FolderListItem: React.FC<Props> = ({
  folder,
  onIndicatorClick,
  setActive,
  showMenu = true,
  showIndicators = true,
  activeId,
}) => {
  const [open, setOpen] = useState(false);

  const [showControls, setShowControls] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openIndicatorModal, setOpenIndicatorModel] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openIndicatorCategoryCopyModal, setOpenIndicatorCategoryCopyModal] = useState(false);

  const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null);
  const [activeFolder, setActiveFolder] = useState<ReportFolder | null>(null);
  const [openEditorModal, setOpenEditor] = useState(false);

  const [mutate] = useMutation(warehouseAPI.reportFolder.delete);
  const queryCache = useQueryCache();

  const { enqueueSnackbar } = useSnackbar();

  const hasIndicators = Boolean(folder.indicatorCount && folder.indicatorCount > 0);
  const classes = useStyles({ open });

  const showDeleteIcon = showControls && !hasIndicators && _.isEmpty(folder.children) && folder.parent;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  type CopyRequest = {
    indicatorCategoryId: number;
    folderId: number;
  };

  const [copyFolderMutation] = useMutation(
    (data: CopyRequest) => warehouseAPI.reportFolder.copyToFolder(data.indicatorCategoryId, data.folderId),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('fetchReportFolder');
        enqueueSnackbar('Skopiowano wybrany folder.', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Wystąpił błąd podczas kopiowania folderu.', { variant: 'error' });
      },
    },
  );
  const [copyCategoryToFolderMutation] = useMutation(
    (data: CopyRequest) =>
      warehouseAPI.reportFolder.copyCategoryToReportFolder(data.indicatorCategoryId, data.folderId),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('fetchReportFolder');
        enqueueSnackbar('Skopiowano wybrany folder.', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Wystąpił błąd podczas kopiowania folderu.', { variant: 'error' });
      },
    },
  );
  return (
    <>
      <ListItem
        className={classes.listItem}
        button
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        {open ? <ExpandMoreIcon onClick={() => setOpen(!open)} /> : <ChevronRightIcon onClick={() => setOpen(!open)} />}
        <ListItemText
          style={{ color: activeId === folder.id ? '#3E75D5' : 'black' }}
          primary={`${folder.name}`}
          onClick={() => {
            if (setActive) {
              setActive(folder.id ?? null);
            }
          }}
        />
        {showMenu && showControls && (
          <>
            <Tooltip title="Dodaj">
              <IconButton className={classes.editButton} onClick={handleMenuClick}>
                <AddOutlined />
              </IconButton>
            </Tooltip>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MenuItem
                onClick={() => {
                  if (folder.id) {
                    setActiveCategoryId(folder.id);
                    setOpenEditor(true);
                  }
                }}
              >
                Dodaj nowy folder
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (folder.id) {
                    setActiveFolder(folder);
                    setOpenIndicatorCategoryCopyModal(true);
                  }
                }}
              >
                Dodaj istniejący folder z zakładki "Wskaźniki"
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (folder.id) {
                    setActiveFolder(folder);
                    setOpenIndicatorModel(true);
                  }
                }}
              >
                Dodaj wskaźnik
              </MenuItem>
            </Menu>
            <Tooltip title="Edytuj">
              <IconButton
                className={classes.editButton}
                onClick={() => {
                  if (folder.id) {
                    setActiveFolder(folder);
                    setOpenEditor(true);
                  }
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Kopiuj">
              <IconButton
                className={classes.editButton}
                onClick={() => {
                  if (folder.id) {
                    setActiveFolder(folder);
                    setOpenCopyModal(true);
                  }
                }}
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {showDeleteIcon && (
          <>
            <IconButton className={classes.deleteButton} onClick={() => setOpenConfirmModal(true)}>
              <DeleteOutlinedIcon />
            </IconButton>
            <ConfirmationModal
              open={openConfirmModal}
              title="Usuń folder"
              message="Czy na pewno usunąć folder?"
              onConfirm={async () => {
                try {
                  await mutate(folder.id);
                  await queryCache.invalidateQueries('fetchReportFolder');
                  setOpenConfirmModal(false);
                } catch (e) {
                  enqueueSnackbar('Błąd przy usuwaniu folderu', { variant: 'error' });
                }
              }}
              onCancel={() => setOpenConfirmModal(false)}
            />
          </>
        )}
        {hasIndicators && (
          <Typography className={classes.cubesCount} variant="caption">
            {folder.indicatorCount}
          </Typography>
        )}
      </ListItem>
      <Collapse in={open} className={classes.nested} unmountOnExit>
        {sortByName(folder.children || []).map((childFolder) => (
          <FolderListItem
            key={childFolder.id}
            folder={childFolder}
            onIndicatorClick={onIndicatorClick}
            showMenu={showMenu}
            setActive={setActive}
            activeId={activeId}
            showIndicators={showIndicators}
          />
        ))}
        {folder.id && hasIndicators && showIndicators && (
          <IndicatorList folderId={folder.id} indicatorCount={folder.indicatorCount || 0} />
        )}
      </Collapse>
      <CopyFolderModal
        open={openCopyModal}
        onClose={() => setOpenCopyModal(false)}
        onSelect={async (folderId: number) => {
          activeFolder && (await copyFolderMutation({ indicatorCategoryId: activeFolder?.id as number, folderId }));
        }}
      />
      <FolderEditorModal
        open={openEditorModal}
        onClose={() => {
          setOpenEditor(false);
          setActiveFolder(null);
          setActiveCategoryId(null);
          handleClose();
        }}
        parent={activeCategoryId || undefined}
        folder={activeFolder ?? undefined}
      />
      <IndicatorCategoryCopyModal
        open={openIndicatorCategoryCopyModal}
        onClose={() => {
          setOpenIndicatorCategoryCopyModal(false);
        }}
        onSelect={async (categoryId: number) => {
          activeFolder &&
            activeFolder?.id &&
            (await copyCategoryToFolderMutation({
              indicatorCategoryId: categoryId,
              folderId: activeFolder?.id,
            }));
        }}
      />
      <ReportIndicatorForm
        open={openIndicatorModal}
        indicator={undefined}
        folder={activeFolder ? activeFolder.id : undefined}
        onClose={() => {
          setOpenIndicatorModel(false);
          handleClose();
        }}
      />
    </>
  );
};

export default FolderListItem;
