import { combineReducers } from '@reduxjs/toolkit';
import auth from '../Auth/authSlice';
import dataBrowser from '../DataBrowser/dataBrowserSlice';

const rootReducer = combineReducers({
  auth,
  dataBrowser,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
