import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    colors: {
      red: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      offWhite: React.CSSProperties['color'];
      black: React.CSSProperties['color'];
      gray: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    colors: {
      red: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      offWhite: React.CSSProperties['color'];
      black: React.CSSProperties['color'];
      gray: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
    };
  }
}

const AppTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3E75D5',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontSize: '20px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '15px',
      fontWeight: 600,
    },
    body1: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '15px',
      fontWeight: 400,
      color: '#7C7E83',
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontStyle: 'normal',
        fontVariant: 'normal',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'uppercase',
        opacity: 1,
        letterSpacing: '0px',
        fontWeight: 600,
        fontSize: '13px',
      },
    },
    MuiInput: {
      root: {
        width: '100%',
        border: '2px solid #F1F1F1',
        borderRadius: '6px',
        minHeight: '40px',
        padding: '0px 15px 0px 15px',
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: 0,
        expanded: {
          margin: 0,
        },
      },
    },
  },
  colors: {
    red: '#D53E3E',
    blue: '#3E75D5',
    white: '#FFFFFF',
    offWhite: '#F6F6F6',
    black: '#161616',
    gray: '#DEDEDE',
    lightGray: '#F1F1F1',
    darkGray: '#7C7E83',
  },
});

export default AppTheme;
