import React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress } from '@material-ui/core';
import Title from '../../shared/components/Title';
import IndicatorCategoryListItem from './IndicatorCategoryItem';
import warehouseAPI from '../../shared/api';
import { Indicator } from '../../shared/types';
import { sortByName } from '../../shared/utils/helpers';
import CategoryEditorModal from '../CategoryEditorModal';
import useModalStore from './modalStore';
import IndicatorFolderModal from '../IndicatorEditor/IndicatorForm/IndicatorFolderModal';

const MainList = withStyles({
  root: {
    padding: '30px',
  },
  subheader: {
    backgroundColor: 'white',
  },
})(List);

const useStyles = makeStyles({
  header: {
    marginTop: '30px',
  },
  icon: {
    paddingBottom: '4px',
  },
});

type CopyRequest = {
  indicatorCategoryId: number;
  folderId: number;
};

interface Props {
  onIndicatorClick?: (indicator: Indicator) => void;
}

function IndicatorTree(props: Props) {
  const { onIndicatorClick } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(
    (data: CopyRequest) => warehouseAPI.indicatorCategory.copyToFolder(data.indicatorCategoryId, data.folderId),
    {
      onSuccess: () => {
        queryCache.invalidateQueries('fetchIndicatorTree');
        enqueueSnackbar('Skopiowano wybrany folder.', { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar('Wystąpił błąd podczas kopiowania folderu.', { variant: 'error' });
      },
    },
  );
  const queryCache = useQueryCache();

  const { activeIndicatorCategory, openEditorModal, closeEditorModal, openCopyModal, closeCopyModal } = useModalStore(
    (state) => state,
  );

  const { isLoading, data: folderTree = [] } = useQuery('fetchIndicatorTree', warehouseAPI.indicatorCategory.tree, {
    onError: () => {
      enqueueSnackbar('Błąd pobierania drzewa wskaźników', { variant: 'error' });
    },
  });
  const classes = useStyles();

  return (
    <>
      <MainList
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <div className={classes.header}>
              <Title variant="h2" title="Wskaźniki" />
            </div>
          </ListSubheader>
        }
        disablePadding
      >
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={60} />
          </Box>
        ) : (
          sortByName(folderTree).map((folder) => (
            <IndicatorCategoryListItem folder={folder} key={folder.id} onIndicatorClick={onIndicatorClick} />
          ))
        )}
      </MainList>
      <CategoryEditorModal open={openEditorModal} onClose={closeEditorModal} folder={activeIndicatorCategory} />
      <IndicatorFolderModal
        open={openCopyModal}
        onClose={closeCopyModal}
        onSelect={async (folderId: number) => {
          activeIndicatorCategory &&
            (await mutate({ indicatorCategoryId: activeIndicatorCategory.id as number, folderId }));
        }}
        allowEmptySelection
      />
    </>
  );
}

export default IndicatorTree;
