import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IndicatorTree from '../IndicatorTree';
import IndicatorAdd from '../../shared/components/icons/IndicatorAdd';

const useStyles = makeStyles({
  linkContainer: {
    marginTop: '30px',
    '& a': {
      width: '80%',
    },
  },
  icon: {
    paddingBottom: '4px',
  },
});

const BootstrapButton = withStyles({
  root: {
    width: '100%',
    minHeight: '40px',
    textAlign: 'left',
    borderRadius: '6px',
    background: '#3E75D5 0% 0% no-repeat padding-box',
    color: '#FFFFFF',
    '&:hover': {
      boxShadow: '0px 6px 10px #7C7E834D',
      backgroundColor: '#3E75D5',
    },
    '&:disabled': {
      backgroundColor: '#F6F6F6',
      color: '#7C7E83',
    },
  },
  label: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
})(Button);

function Sider() {
  const classes = useStyles();

  const history = useHistory();

  return (
    <>
      <Box className={classes.linkContainer} display="flex" justifyContent="center">
        <Link to="indicators/add">
          <BootstrapButton
            startIcon={
              <Icon className={classes.icon}>
                <IndicatorAdd color="#ffffff" />
              </Icon>
            }
          >
            Dodaj nowy wskaźnik
          </BootstrapButton>
        </Link>
      </Box>
      <IndicatorTree onIndicatorClick={(indicator) => history.push(`/indicators/${indicator.id}`)} />
    </>
  );
}

export default Sider;
