import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Switch } from 'formik-material-ui';
import { Button, FormControlLabel, Grid, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import _ from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import CubeForm from '../../../DataBrowser/CubeDetails/CubeForm';
import useIndicatorStore from '../../indicatorStore';
import warehouseAPI from '../../../shared/api';
import { CubeBasedComponent, Dimension } from '../../../shared/types';
import SelectInput from '../../../shared/components/forms/SelectInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '25px',
    },
    name: {
      fontWeight: 400,
    },
    button: {
      width: '100%',
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: 'white',
      '&:hover': {
        boxShadow: `0px 6px 10px ${theme.colors.darkGray}`,
        backgroundColor: theme.colors.blue,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    arrow: {
      fontSize: '16px',
      marginLeft: '8px',
    },
  }),
);

interface Props {
  cubeId: number;
}

function CubeComponentForm(props: Props) {
  const { cubeId } = props;

  const classes = useStyles();

  const { data: cube } = useQuery(['fetchCubeDetails', cubeId], () => warehouseAPI.cube.fetchDetails(cubeId));

  const [addComponent, components, activeComponent] = useIndicatorStore((state) => [
    state.addIndicatorComponent,
    state.editorComponents,
    state.activeComponent as CubeBasedComponent,
  ]);

  const schema = Yup.object().shape({
    extrapolated: Yup.boolean(),
    measure: Yup.number().required('Wybierz miarę'),
    dimensionValues: Yup.array()
      .of(
        Yup.object().shape({
          dimension: Yup.number().required('Wymiar musi mieć ID'),
          value: Yup.string().required('Wymiar musi mieć wartość'),
        }),
      )
      .test('Dimension Value Test', 'Wybierz inną kombinację wartości', (value, context) =>
        _.chain(components as any)
          .find({
            type: 'CUBE',
            sourceCube: cubeId,
            dimensionValues: value,
            extrapolated: context.parent.extrapolated,
            measure: context.parent.measure,
          })
          .isUndefined()
          .value(),
      ),
  });

  const getInitialDimensionValue = (dimension: Dimension) => {
    if (activeComponent) {
      const componentDimensionValues = activeComponent.dimensionValues;
      const validDimensionValue = _.find(componentDimensionValues, { dimension: dimension.id });
      if (validDimensionValue) {
        return validDimensionValue.value;
      }
    }
    return dimension.values[0];
  };

  return cube ? (
    <Formik
      initialValues={{
        extrapolated: activeComponent?.extrapolated || false,
        dimensionValues: cube.dimensions
          .filter((dimension) => dimension.type === 'CATEGORICAL')
          .map((dimension) => ({ dimension: dimension.id, value: getInitialDimensionValue(dimension) })),
        measure: activeComponent?.measure,
      }}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        addComponent({
          label: activeComponent.label,
          type: 'CUBE',
          sourceCube: cube.id,
          aggregationLevel: cube.aggregationLevel,
          ...values,
        });
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ submitForm, isValid, values }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2">Nazwa</Typography>
              <Typography className={classes.name} variant="h2">
                {cube.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Poziom agregacji</Typography>
              <Typography className={classes.name} variant="body1">
                {`KTS-${cube.aggregationLevel}`}
              </Typography>
              {cube.aggregationLevel < 6 && (
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      color="primary"
                      type="checkbox"
                      name="extrapolated"
                      checked={values.extrapolated}
                    />
                  }
                  label="Ekstrapoluj dla wyższych poziomów"
                />
              )}
            </Grid>
            <CubeForm cube={cube} />
            <Grid item xs={12}>
              <SelectInput fieldId="measure-select" name="measure" label="Miara">
                {cube.measures.map((measure) => (
                  <MenuItem key={measure.id} value={measure.id}>
                    {measure.name}
                  </MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={12}>
              <Button className={classes.button} onClick={submitForm} disabled={!isValid}>
                Dodaj do wskaźnika
                <ArrowForwardIcon className={classes.arrow} />
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  ) : null;
}

export default CubeComponentForm;
