import React, { useContext } from 'react';
import { Field, Form, Formik, FormikValues } from 'formik';
import { DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { RadioGroup, TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import GenericButton from '../../../../../shared/components/GenericButton';
import { PopoverContext } from './index';

interface Props {
  onSubmit: (token: string) => void;
}

function ReplaceValue(props: Props) {
  const { onSubmit } = props;

  const { onClose } = useContext(PopoverContext);

  const schema = Yup.object({
    type: Yup.string().required('To pole nie może być puste.'),
    yearValue: Yup.number().when('type', {
      is: 'yearValue',
      then: Yup.number()
        .required('Wybierz liczbę lat')
        .integer('Wartość musi być liczbą całkowitą')
        .positive('Wartość musi być dodatnia')
        .min(1950, 'Ta wartość nie wygląda na słuszny rok')
        .max(2050, 'Ta wartość nie wygląda na słuszny rok'),
    }),
    oldYear: Yup.number().when('type', {
      is: 'replaceYearWithOtherYear',
      then: Yup.number()
        .required('Podaj stary rok')
        .integer('Wartość musi być liczbą całkowitą')
        .min(1950, 'Ta wartość nie wygląda na słuszny rok')
        .max(2050, 'Ta wartość nie wygląda na słuszny rok'),
    }),
    newYear: Yup.number().when('type', {
      is: 'replaceYearWithOtherYear',
      then: Yup.number()
        .required('Podaj nowy rok')
        .integer('Wartość musi być liczbą całkowitą')
        .min(1950, 'Ta wartość nie wygląda na słuszny rok')
        .max(2050, 'Ta wartość nie wygląda na słuszny rok'),
    }),
  });

  const getToken = (values: FormikValues) => {
    const { type, yearValue, oldYear, newYear } = values;

    if (type === 'fill') {
      return 'FILL';
    }
    if (type === 'yearValue') {
      return `REPLACE[${yearValue}]`;
    }
    if (type === 'fillZero') {
      return 'FILLZERO';
    }
    if (type === 'replaceYearWithOtherYear') {
      return `REPLACE_YEAR[${oldYear}]WITH[${newYear}]`;
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        type: '',
        yearValue: '',
        oldYear: '',
        newYear: '',
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        const token = getToken(values);
        if (token) {
          onSubmit(token);
        }
        if (onClose) {
          onClose();
        }
      }}
    >
      {({ values, dirty, isValid, isSubmitting, submitForm }) => (
        <Form>
          <DialogTitle disableTypography>
            <Typography variant="body2">Dodaj rok</Typography>
          </DialogTitle>
          <DialogContent>
            <Field component={RadioGroup} name="type">
              <FormControlLabel
                value="fill"
                control={<Radio color="primary" />}
                label="Wypełnij brakujące wartości"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="yearValue"
                control={<Radio color="primary" />}
                label="Zastąp wartości dla roku"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="fillZero"
                control={<Radio color="primary" />}
                label="Zastąp brakujące dane zerem"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="replaceYearWithOtherYear"
                control={<Radio color="primary" />}
                label="Zastąp rok innym rokiem"
                disabled={isSubmitting}
              />
            </Field>
            {values.type === 'replaceYearWithOtherYear' && (
              <>
                <Field
                  component={TextField}
                  name="oldYear"
                  InputProps={{ disableUnderline: true }}
                  placeholder="Podaj rók który chcesz zastąpić"
                  type="text"
                  fullWidth
                />
                <Field
                  component={TextField}
                  name="newYear"
                  InputProps={{ disableUnderline: true }}
                  placeholder="Podaj rok z którego wziąć wartości"
                  type="text"
                  fullWidth
                />
              </>
            )}
            {values.type === 'yearValue' && (
              <>
                <Field
                  component={TextField}
                  name="yearValue"
                  InputProps={{ disableUnderline: true }}
                  placeholder="Podaj rok"
                  type="text"
                  fullWidth
                  disabled={values.type !== 'yearValue'}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
              Dodaj
            </GenericButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default ReplaceValue;
