import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikValues } from 'formik';
import { DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { RadioGroup, TextField } from 'formik-material-ui';
import { PopoverContext } from './index';
import GenericButton from '../../../../../shared/components/GenericButton';

interface Props {
  onSubmit: (token: string) => void;
}

function Average(props: Props) {
  const { onSubmit } = props;

  const { onClose } = useContext(PopoverContext);

  const schema = Yup.object({
    type: Yup.string().required('To pole nie może być puste.'),
    windowWidth: Yup.number().when('type', {
      is: 'movingAverage',
      then: Yup.number()
        .required('Wybierz liczbę lat')
        .integer('Wartość musi być liczbą całkowitą')
        .positive('Wartość musi być dodatnia'),
    }),
  });

  const getToken = (values: FormikValues) => {
    const { type, windowWidth } = values;

    if (type === 'ktsAverage') {
      return 'AVG[TIME] (';
    }
    if (type === 'timeAverage') {
      return 'AVG[KTS] (';
    }
    if (type === 'movingAverage') {
      return `AVG_N[${windowWidth}] (`;
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        type: '',
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        const token = getToken(values);
        if (token) {
          onSubmit(token);
        }
        if (onClose) {
          onClose();
        }
      }}
    >
      {({ values, dirty, isValid, isSubmitting, submitForm }) => (
        <Form>
          <DialogTitle disableTypography>
            <Typography variant="body2">Dodaj średnią</Typography>
          </DialogTitle>
          <DialogContent>
            <Field component={RadioGroup} name="type">
              <FormControlLabel
                value="ktsAverage"
                control={<Radio color="primary" />}
                label="Średnia dla jednostek"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="timeAverage"
                control={<Radio color="primary" />}
                label="Średnia dla lat"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="movingAverage"
                control={<Radio color="primary" />}
                label="Średnia krocząca"
                disabled={isSubmitting}
              />
            </Field>
            <Field
              component={TextField}
              name="windowWidth"
              InputProps={{ disableUnderline: true }}
              placeholder="Liczba lat"
              type="text"
              fullWidth
              disabled={values.type !== 'movingAverage'}
            />
          </DialogContent>
          <DialogActions>
            <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
              Dodaj
            </GenericButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default Average;
