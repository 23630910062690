import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../../shared/components/Title';
import IndicatorAnalysis from './index';
import { Indicator } from '../../../shared/types';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  indicator: Indicator;
};

function IndicatorAnalysisModal(props: Props) {
  const { open, onClose, indicator } = props;

  const classes = useStyles();

  return (
    <Dialog fullWidth fullScreen open={open} onClose={onClose}>
      <DialogTitle disableTypography classes={classes}>
        <Title variant="h1" modal title={`Analiza wskaźnika ${indicator.name}`} verticalCenter />
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <IndicatorAnalysis indicator={indicator} />
      </DialogContent>
    </Dialog>
  );
}

export default IndicatorAnalysisModal;
