import React, { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Placeholder from './Placeholder';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    header: {
      padding: '20px 40px 20px 40px',
      borderBottom: `1px solid ${theme.colors.offWhite}`,
    },
    container: {
      flexGrow: 1,
    },
    left: {
      padding: '40px',
      borderRight: `1px solid ${theme.colors.offWhite}`,
      height: '100%',
    },
    right: {
      padding: '40px',
      height: '100%',
    },
  }),
);

interface Props {
  header: ReactNode;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  leftPanelSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  rightPanelSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  placeholder?: string;
}

function RightPanelLayout(props: Props) {
  const { header, contentLeft, contentRight, rightPanelSize, leftPanelSize, placeholder } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <div className={classes.header}>{header}</div>

      {!(contentLeft || contentRight) ? (
        <Placeholder background={placeholder} />
      ) : (
        <Grid container className={classes.container}>
          <Grid className={classes.left} item xs={leftPanelSize}>
            {contentLeft}
          </Grid>
          <Grid className={classes.right} item xs={rightPanelSize}>
            {contentRight}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default RightPanelLayout;
