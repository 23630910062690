import React from 'react';
import { useQuery } from 'react-query';
import { useField } from 'formik';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import warehouseAPI from '../../../shared/api';
import { getUnitName } from '../../../shared/utils/helpers';
import UnitTooltip from './UnitTooltip';

interface Props {
  unitId: number;
}

function Unit(props: Props) {
  const { unitId } = props;

  const [field, , helpers] = useField('administrativeUnits');

  const { data: unit } = useQuery(['fetchUnitDetails', unitId], () =>
    warehouseAPI.administrativeUnit.fetchDetails(unitId),
  );

  const isChecked = _.includes(field.value, unitId);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (unit) {
      if (event.target.checked) {
        helpers.setValue(_.union(field.value, [unit.id]));
      } else {
        helpers.setValue(_.without(field.value, unit.id));
      }
    }
  };

  return unit ? (
    <UnitTooltip
      unit={unit}
      child={
        <FormControlLabel
          control={<Checkbox checked={isChecked} color="primary" onChange={handleChange} />}
          label={getUnitName(unit)}
        />
      }
    />
  ) : (
    <Skeleton />
  );
}

export default Unit;
