import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GenericButton from '../../shared/components/GenericButton';
import Check from '../../shared/components/icons/Check';
import GroupList from '../GroupPickerModal/GroupList';
import GroupDetails from '../GroupPickerModal/GroupDetails';
import { AdministrativeGroup, AdministrativeGroupList } from '../../shared/types';
import { TerritoryGroup, TerritoryGroupList } from '../../shared/types';
import TerritoryList from '../TerritoryPickerModal/TerritoryList';
import TerritoryDetails from '../TerritoryPickerModal/TerritoryDetails';
import AddTerritoryListModal from '../AddTerritoryListModal';
import AddGroupListModal from '../AddGroupListModal';
import useGroupStore from '../groupStore';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Title from '../../shared/components/Title';

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    height: '65%',
  },
  container: {
    height: '100%',
  },
  column: {
    height: '100%',
  },
  columnContent: {
    height: 'calc(50vh - 70px)',
    overflowY: 'auto',
  },
});

type Props = {
  open: boolean;
  onClose: () => void;
  type?: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function GroupTerritoryPickerModal(props: Props) {
  const { open, onClose, type } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [currentGroup, setCurrentGroup] = useState(null as AdministrativeGroup | null);
  const [currentList, setCurrentList] = useState(null as AdministrativeGroupList | null as TerritoryGroupList);

  const [openModal, setModalOpen] = useState(false);

  const theme = useTheme();
  const classes = useStyles();

  const setIsTerritory = useGroupStore((state) => state.setIsTerritory);
  const setCurrentGroupId = useGroupStore((state) => state.setCurrentGroup);
  const setCurrentGroupListId = useGroupStore((state) => state.setCurrentComparisonGroupList);
  const setCurrentAggregateGroupList = useGroupStore((state) => state.setCurrentAggregateGroupList);

  const handleChoice = () => {
    if (currentGroup && currentGroup.id) {
      setCurrentGroupId(currentGroup.id);
      onClose();
    }
    if (currentList && currentList.id) {
      if (type && type === 'aggregation') {
        setCurrentAggregateGroupList(currentList.id);
      } else if (type && type === 'comparison') {
        setCurrentGroupListId(currentList.id);
      }
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={type ? 'sm' : 'lg'}
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.root }}
    >
      <DialogTitle style={{ display: 'flex', width: '100%', padding: '0', borderBottom: 1, justifyContent: 'center' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          style={{ width: '100%' }}
          centered
        >
          <Tab label="Grupy" />
          <Tab label="Obszary" />
        </Tabs>
      </DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={6} className={classes.column}>
              <Title variant="h2" modal title="Lista grup" />
              <Box className={classes.columnContent}>
                <GroupList
                  currentGroup={currentGroup}
                  setCurrentGroup={setCurrentGroup}
                  currentList={currentList}
                  setCurrentList={setCurrentList}
                  setIsTerritory={setIsTerritory}
                  type={type || null}
                />
              </Box>
            </Grid>
            {!type && (
              <Grid item xs={6} className={classes.column}>
                <Title variant="h2" modal title="Skład grupy" />
                <Box className={classes.columnContent}>
                  <GroupDetails groupId={currentGroup?.id} />
                </Box>
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={6} className={classes.column}>
              <Title variant="h2" modal title="Lista obszarów" />
              <Box className={classes.columnContent}>
                <TerritoryList
                  currentTerritory={currentGroup as TerritoryGroup}
                  setCurrentGroup={setCurrentGroup}
                  currentList={currentList}
                  setCurrentList={setCurrentList}
                  setIsTerritory={setIsTerritory}
                  type={type || null}
                />
              </Box>
            </Grid>
            {!type && (
              <Grid item xs={6} className={classes.column}>
                <Title variant="h2" modal title="Skład obszaru" />
                <Box className={classes.columnContent}>
                  <TerritoryDetails territoryId={currentGroup?.id} />
                </Box>
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!type && (
          <GenericButton variant="secondary" onClick={() => setModalOpen(true)}>
            Dodaj listę
          </GenericButton>
        )}
        <GenericButton
          variant="primary"
          disabled={false}
          icon={<Check color={theme.colors.white} />}
          onClick={handleChoice}
        >
          Wybierz
        </GenericButton>
      </DialogActions>
      <AddGroupListModal open={value === 0 ? openModal : false} onClose={() => setModalOpen(false)} />
      <AddTerritoryListModal open={value === 1 ? openModal : false} onClose={() => setModalOpen(false)} />
    </Dialog>
  );
}

export default GroupTerritoryPickerModal;
