import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { createStyles, Grid, ListItemText, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import _ from 'lodash';
import { useField } from 'formik';
import { useActiveUnitStore, useUnitSearchStore } from '../index';
import warehouseAPI from '../../../shared/api';
import UnitCheckbox from './UnitCheckbox';
import GenericButton from '../../../shared/components/GenericButton';

interface Params {
  // Select single administrative unit
  multi?: boolean;
}

export default function UnitPicker({ multi = true }: Params) {
  const [selectedAll, setSelectedAll] = useState(false);
  const activeUnitId = useActiveUnitStore((state) => state.activeUnitId);
  const [filter, setFilter] = useState('');
  const [field, , helpers] = useField(multi ? 'administrativeUnits' : 'administrativeUnit');

  const { data: childUnits } = useQuery(
    ['fetchChildUnits', activeUnitId, filter],
    warehouseAPI.administrativeUnit.children,
  );

  const searchResults = useUnitSearchStore((state) => state.searchResults);

  const units = _.isEmpty(searchResults) ? childUnits : searchResults;
  // Select All / Deselect All
  function select() {
    const ids: Array<number> = [];
    units?.forEach((unit) => ids.push(unit.id));
    if (!selectedAll) {
      helpers.setValue(_.union(field.value, ids));
    } else {
      helpers.setValue(_.without(field.value, ...ids));
    }
    setSelectedAll(!selectedAll);
  }

  // Select unit type
  const unitTypes = [
    { type: '', name: 'Bez filtrów' },
    { type: 'POWIAT_CITY', name: 'Miasto na prawach powiatu' },
    { type: 'URBAN_GMINA', name: 'Gmina miejska' },
    { type: 'RURAL_GMINA', name: 'Gmina wiejska' },
    { type: 'MIXED_GMINA', name: 'Gmina miejsko-wiejska' },
    { type: 'POWIAT', name: 'Powiat' },
    { type: 'VOIEVODSHIP', name: 'Województwo' },
  ];

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as string);
    setSelectedAll(false);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      btns: {
        width: '40%',
        marginLeft: '5px',
        padding: '0',
        verticalAlign: 'bottom',
        marginTop: theme.spacing(1),
      },
      select: {
        width: '57%',
        height: '28px',
        marginTop: theme.spacing(1),
      },
    }),
  );
  const classes = useStyles();

  return (
    <>
      {activeUnitId ? (
        <Select labelId="select-label" className={classes.select} value={filter} onChange={handleChange}>
          {unitTypes.map((unit) => (
            <MenuItem key={unit.type} value={unit.type}>
              <ListItemText primary={unit.name} />
            </MenuItem>
          ))}
        </Select>
      ) : null}
      {multi && activeUnitId ? (
        <GenericButton variant="secondary" onClick={select} className={classes.btns}>
          {selectedAll ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'}
        </GenericButton>
      ) : null}

      <div style={{ overflow: 'auto', height: 'calc(50vh - 70px)' }}>
        <Grid container>
          {units &&
            units.map((unit) => (
              <Grid key={unit.id} item xs={12}>
                <UnitCheckbox administrativeUnit={unit} multi={multi} />
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
}
