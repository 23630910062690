import React from 'react';
import _ from 'lodash';
import { List, ListItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { History, Info } from '@material-ui/icons';
import { getUnitName } from '../../shared/utils/helpers';
import warehouseAPI from '../../shared/api';
import UnitTooltip from '../GroupEditorModal/ChosenUnits/UnitTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listTitle: {
      color: theme.colors.black,
      fontWeight: 600,
      fontSize: '20px',
      letterSpacing: '0px',
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '15px',
      letterSpacing: '0px',
      color: theme.colors.darkGray,
      opacity: 1,
      position: 'relative',
    },
    unitCount: {
      background: `${theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      opacity: 1,
      width: '27px',
      textAlign: 'center',
      color: theme.colors.darkGray,
    },
  }),
);

interface Props {
  territoryId?: number;
}

function GroupDetails(props: Props) {
  const { territoryId } = props;

  const { data: units } = useQuery(['fetchTerritoryUnits', territoryId], warehouseAPI.territoryGroup.units);
  const classes = useStyles();

  return (
    <List>
      {units &&
        units.map((unit) => (
          <UnitTooltip
            unit={unit}
            keyValue={unit.id}
            key={unit.id}
            child={
              <ListItem key={unit.id} className={classes.listItem}>
                {getUnitName(unit)}
                {unit.comment ? <Info /> : !_.isEmpty(unit.historicalTerytCodes) && <History />}
              </ListItem>
            }
          />
        ))}
    </List>
  );
}

export default GroupDetails;
