import React from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import warehouseAPI from '../../../shared/api';
import IndicatorItem from './IndicatorItem';
import { sortByName } from '../../../shared/utils/helpers';

interface Props {
  folderId: number;
  indicatorCount: number;
}

function IndicatorList(props: Props) {
  const { folderId, indicatorCount } = props;

  const { data: indicators = [] } = useQuery(['fetchFolderIndicators', folderId], warehouseAPI.reportFolder.indicators);

  return (
    <>
      {_.isEmpty(indicators)
        ? _.range(indicatorCount).map((index) => <Skeleton key={index} animation="wave" />)
        : sortByName(indicators).map((indicator) => <IndicatorItem key={indicator.id} indicator={indicator} />)}
    </>
  );
}

export default IndicatorList;
