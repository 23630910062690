import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryCache } from 'react-query';
import { Indicator, IndicatorCategory } from '../../shared/types';
import IndicatorList from './IndicatorList';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import warehouseAPI from '../../shared/api';
import { sortByName } from '../../shared/utils/helpers';
import useModalStore from './modalStore';

type StyleProps = {
  open: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.colors.black,
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '13px',
      letterSpacing: '0px',
      color: (props: StyleProps) => (props.open ? theme.colors.black : theme.colors.darkGray),
      opacity: 1,
      position: 'relative',
      fontFamily: 'Poppins',
      '&:hover': {
        color: theme.colors.black,
      },
    },
    cubesCount: {
      background: (props: StyleProps) =>
        `${props.open ? theme.colors.darkGray : theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      opacity: 1,
      width: '27px',
      textAlign: 'center',
      color: (props: StyleProps) => (props.open ? theme.colors.white : theme.colors.darkGray),
    },
    active: {
      color: theme.colors.blue,
    },
    deleteButton: {
      padding: 0,
    },
    editButton: {
      padding: 0,
    },
    copyButton: {
      padding: 0,
    },
  }),
);

type Props = {
  folder: IndicatorCategory;
  onIndicatorClick?: (indicator: Indicator) => void;
};

function IndicatorCategoryListItem(props: Props) {
  const { folder, onIndicatorClick } = props;
  const [open, setOpen] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const editIndicatorCategory = useModalStore((state) => state.editIndicatorCategory);
  const copyIndicatorCategory = useModalStore((state) => state.copyIndicatorCategory);

  const [mutate] = useMutation(warehouseAPI.indicatorCategory.delete);
  const queryCache = useQueryCache();

  const { enqueueSnackbar } = useSnackbar();

  const hasIndicators = Boolean(folder.indicatorCount && folder.indicatorCount > 0);
  const classes = useStyles({ open });

  const showDeleteIcon = showControls && !hasIndicators && _.isEmpty(folder.children);

  return (
    <>
      <ListItem
        className={classes.listItem}
        button
        onClick={() => setOpen(!open)}
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        <ListItemText primary={`${folder.name}`} />
        {showControls && (
          <>
            <Tooltip title="Edytuj">
              <IconButton className={classes.editButton} onClick={() => editIndicatorCategory(folder)}>
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Kopiuj">
              <IconButton className={classes.copyButton} onClick={() => copyIndicatorCategory(folder)}>
                <FileCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {showDeleteIcon && (
          <>
            <IconButton className={classes.deleteButton} onClick={() => setOpenConfirmModal(true)}>
              <DeleteOutlinedIcon />
            </IconButton>
            <ConfirmationModal
              open={openConfirmModal}
              title="Usuń folder"
              message="Czy na pewno usunąć folder?"
              onConfirm={async () => {
                try {
                  await mutate(folder.id);
                  await queryCache.invalidateQueries('fetchIndicatorTree');
                  setOpenConfirmModal(false);
                } catch (e) {
                  enqueueSnackbar('Błąd przy usuwaniu folderu', { variant: 'error' });
                }
              }}
              onCancel={() => setOpenConfirmModal(false)}
            />
          </>
        )}
        {hasIndicators && (
          <Typography className={classes.cubesCount} variant="caption">
            {folder.indicatorCount}
          </Typography>
        )}
      </ListItem>
      <Collapse in={open} className={classes.nested} unmountOnExit>
        {sortByName(folder.children || []).map((childFolder) => (
          <IndicatorCategoryListItem key={childFolder.id} folder={childFolder} onIndicatorClick={onIndicatorClick} />
        ))}
        {folder.id && hasIndicators && (
          <IndicatorList
            folderId={folder.id}
            indicatorCount={folder.indicatorCount || 0}
            onIndicatorClick={onIndicatorClick}
          />
        )}
      </Collapse>
    </>
  );
}

export default IndicatorCategoryListItem;
