import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Box, CircularProgress, ListSubheader } from '@material-ui/core';
import Title from '../../shared/components/Title';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import warehouseAPI from '../../shared/api';
import ReportList from '../ReportTree/ReportList';

const MainList = withStyles({
  root: {
    padding: '30px',
  },
  subheader: {
    backgroundColor: 'white',
  },
})(List);

const useStyles = makeStyles({
  linkContainer: {
    marginTop: '30px',
    '& a': {
      width: '80%',
    },
  },
  icon: {
    paddingBottom: '4px',
  },
  header: {
    marginTop: '30px',
  },
});

function Sider() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, data: reports = [] } = useQuery('fetchFolderReports', warehouseAPI.reportCategory.reports, {
    onError: () => {
      enqueueSnackbar('Błąd pobierania listy raportów', { variant: 'error' });
    },
  });

  return (
    <MainList
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <div className={classes.header}>
            <Title variant="h2" title="Raporty" />
          </div>
        </ListSubheader>
      }
      disablePadding
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={60} />
        </Box>
      ) : (
        <ReportList reportCount={reports.length || 0} />
      )}
    </MainList>
  );
}

export default Sider;
