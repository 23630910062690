import React, { useState } from 'react';
import { Button, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import Groups from '../../../shared/components/icons/Groups';
import IndicatorFolderModal from './IndicatorFolderModal';
import warehouseAPI from '../../../shared/api';

type FormFields = {
  folder: number;
};

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
  },
});

type Props = {
  toShowDropdown?: boolean;
};

const FolderPicker: React.FC<Props> = ({ toShowDropdown = true }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const {
    values: { folder: categoryId },
    setFieldValue,
  } = useFormikContext<FormFields>();

  const { data: folder } = useQuery(['fetchIndicatorCategory', categoryId], () =>
    warehouseAPI.indicatorCategory.fetchDetails(categoryId),
  );

  return (
    <>
      <Button
        className={classes.button}
        startIcon={<Groups color={theme.colors.blue} />}
        onClick={() => setModalOpen(true)}
      >
        {folder ? folder.name : 'Wybierz folder'}
      </Button>
      <IndicatorFolderModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={(value: number) => setFieldValue('folder', value)}
        toShowDropdown={toShowDropdown}
      />
    </>
  );
};

export default FolderPicker;
