import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Cube, CubeCategory } from '../../shared/types';
import CubeList from './CubeList';
import { cubeParentsIds } from '../dataBrowserSlice';
import { sortByNativeId } from '../../shared/utils/helpers';

type StyleProps = {
  open: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.colors.black,
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '13px',
      letterSpacing: '0px',
      color: (props: StyleProps) => (props.open ? theme.colors.black : theme.colors.darkGray),
      opacity: 1,
      position: 'relative',
      fontFamily: 'Poppins',
      '&:hover': {
        color: theme.colors.black,
      },
    },
    cubesCount: {
      background: (props: StyleProps) =>
        `${props.open ? theme.colors.darkGray : theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      opacity: 1,
      width: '27px',
      textAlign: 'center',
      color: (props: StyleProps) => (props.open ? theme.colors.white : theme.colors.darkGray),
    },
  }),
);

type Props = {
  category: CubeCategory;
  onCubeClick?: (cube: Cube) => void;
};

function CubeCategoryListItem(props: Props) {
  const { category, onCubeClick } = props;
  const [open, setOpen] = useState(false);
  const hasCubes = category.cubesCount > 0;
  const handleClick = () => setOpen(!open);
  const classes = useStyles({ open });

  const parents = useSelector(cubeParentsIds);

  useEffect(() => {
    if (parents.includes(category.id)) {
      setOpen(true);
    }
  }, [category.id, parents]);

  return (
    <>
      <ListItem className={classes.listItem} button onClick={handleClick}>
        {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        <ListItemText primary={`${category.nativeId} - ${category.name}`} />
        {hasCubes && (
          <Typography className={classes.cubesCount} variant="caption">
            {category.cubesCount}
          </Typography>
        )}
      </ListItem>
      <Collapse in={open} className={classes.nested} timeout={100} unmountOnExit>
        {hasCubes ? (
          <CubeList
            cubesCount={category.cubesCount}
            categoryId={category.id || -1}
            opened={open}
            onCubeClick={onCubeClick}
          />
        ) : (
          sortByNativeId(category.children).map((childCategory) => (
            <List key={childCategory.id} component="div">
              <CubeCategoryListItem key={childCategory.id} category={childCategory} onCubeClick={onCubeClick} />
            </List>
          ))
        )}
      </Collapse>
    </>
  );
}

export default CubeCategoryListItem;
