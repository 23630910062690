import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Form, Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Button, Grid, MenuItem } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Report, ReportFormResult } from '../../../shared/types';
import GenericButton from '../../../shared/components/GenericButton';
import Confirm from '../../../shared/components/icons/Confirm';
import Cancel from '../../../shared/components/icons/Cancel';
import TextInput from '../../../shared/components/forms/TextInput';
import warehouseAPI from '../../../shared/api';
import SelectInput from '../../../shared/components/forms/SelectInput';
import FolderPicker from '../../../IndicatorBrowser/IndicatorEditor/IndicatorForm/FolderPicker';

import useReportStore from '../../reportStore';
import Title from '../../../shared/components/Title';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: '10px',
    },
    label: {
      color: theme.colors.darkGray,
      fontSize: '15px',
      fontWeight: 400,
      marginBottom: '10px',
    },
    title: {
      marginBottom: '25px',
    },
    flexRow: {
      display: 'flex',
      gap: '10px',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    fieldLabel: {
      margin: '0px',
      color: '#7C7E83',
      fontSize: '15px',
      fontWeight: 400,
      marginBottom: '10px',
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
  }),
);

type Params = {
  id: string;
};

type Fields = {
  name: string;
  description?: string;
  folder?: number;
  years: number[];
  logo?: string;
};

const yearsRange = (start: number, end: number) => Array.from(Array(end - start + 1).keys()).map((x) => x + start);

const getLogoDefaultName = (val: Report): string => {
  if (val.logo) {
    const list = val.logo.split('/');
    return list[list.length - 1];
  }
  return '';
};

const ReportForm: React.FC = () => {
  const history = useHistory();

  const classes = useStyles();

  const { id } = useParams<Params>();

  const { enqueueSnackbar } = useSnackbar();

  const { data: report } = useQuery(['fetchReportDetails', Number(id)], () =>
    warehouseAPI.report.fetchDetails(Number(id)),
  );

  const [isChanged] = useReportStore((state) => [state.isChanged]);

  const navigateReport = (reportId: number) => history.push(`/reports/${reportId}`);

  const [logoFile, setLogoFile] = useState<File | undefined>(undefined);

  const [createReport] = useMutation(warehouseAPI.report.formCreateReport, {
    onSuccess: (data) => {
      if (data.data.id) {
        navigateReport(data.data.id);
      }
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });

  const [editReport] = useMutation(warehouseAPI.report.formEditReport, {
    onSuccess: (data) => {
      if (data.data.id) {
        navigateReport(data.data.id);
      }
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });

  const validationSchema = Yup.object({
    name: Yup.string().required('To pole nie może być puste.'),
    description: Yup.string(),
    folder: Yup.number(),
    years: Yup.array().required('To pole nie może być puste.'),
  });

  const submitForm = (values: Fields) => {
    const toSave: ReportFormResult = {
      name: values.name,
      description: values.description,
      indicator_category: values.folder,
      years_range: values.years,
      logo: logoFile,
    };
    if (report) {
      toSave.id = report.id;
      toSave.folder = report.folder.id;
      editReport(toSave);
    } else {
      createReport(toSave);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    if (files?.length) {
      setLogoFile(files[0]);
    }
  };

  return (
    <>
      <Formik
        initialValues={
          report && report.yearsRange
            ? {
                name: report.name,
                description: report.description,
                years: report.yearsRange,
                logo: getLogoDefaultName(report),
              }
            : {
                name: '',
                description: '',
                years: [],
                logo: '',
              }
        }
        validationSchema={validationSchema}
        onSubmit={(values) => submitForm(values)}
        enableReinitialize
      >
        {({ values, dirty, isValid, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput fieldId="name" label="Nazwa raportu" name="name" placeholder="Podaj nazwę" />
              </Grid>
              {!report && (
                <Grid item xs={12}>
                  <Title variant="h3" title="Folder" className={classes.label} />
                  <FolderPicker toShowDropdown={false} />
                </Grid>
              )}
              <Grid item xs={12}>
                <p className={classes.fieldLabel}> Logo: </p>
                <div className={classes.flexRow}>
                  <label htmlFor="contained-button-file">
                    <input
                      style={{ display: 'none' }}
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.files) {
                          setFieldValue('logo', event.target.files[0].name);
                          handleFileUpload(event);
                        }
                      }}
                    />
                    <Button variant="contained" component="span">
                      Wyślij
                    </Button>
                  </label>
                  <p>{values.logo}</p>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextInput fieldId="description" label="Opis" name="description" rows={5} placeholder="Podaj opis" />
              </Grid>
              <Grid item xs={12}>
                <SelectInput multiple fieldId="years" label="Lata" name="years" placeholder="Wybierz lata">
                  {yearsRange(1995, 2023).map((year) => (
                    <MenuItem
                      key={year}
                      value={year}
                      selected={report && report.yearsRange ? report.yearsRange.includes(year) : false}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <GenericButton
                    className={classes.button}
                    disabled={!((dirty && isValid) || isChanged)}
                    variant="primary"
                    shape="circle"
                    onClick={() => submitForm(values)}
                    icon={
                      <Icon>
                        <Confirm color="#ffffff" />
                      </Icon>
                    }
                  >
                    Zapisz raport
                  </GenericButton>
                  <Link to="/reports">
                    <GenericButton
                      variant="destructiveSecondary"
                      disabled={false}
                      shape="circle"
                      icon={
                        <Icon>
                          <Cancel color="#D53E3E" />
                        </Icon>
                      }
                    >
                      Odrzuć zmiany
                    </GenericButton>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ReportForm;
