import React, { useState } from 'react';
import { Button, useTheme } from '@material-ui/core';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import Groups from '../../../../../shared/components/icons/Groups';
import warehouseAPI from '../../../../../shared/api';
import CubeModal from './CubeModal';
import { useFormikContext } from 'formik';

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
  },
  root: {
    height: '60%',
  },
});

type FormFields = {
  sourceCube: number;
};

const CubePicker: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);

  const {
    values: { sourceCube: cubeId },
  } = useFormikContext<FormFields>();

  const { data: cube } = useQuery(['fetchCubeDetails', cubeId], () => warehouseAPI.cube.fetchDetails(cubeId));

  return (
    <>
      <Button
        className={classes.button}
        startIcon={<Groups color={theme.colors.blue} />}
        onClick={() => setModalOpen(true)}
      >
        {cube ? cube.name : 'Wybierz kostke'}
      </Button>
      <CubeModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default CubePicker;
