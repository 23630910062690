import React from 'react';
import RightPanelLayout from '../../shared/components/RightPanelLayout';
import Header from './Header';
import ComponentForm from './ComponentForm';
import IndicatorForm from './IndicatorForm';

function ContentPanel() {
  return (
    <RightPanelLayout
      header={<Header />}
      contentLeft={<ComponentForm />}
      contentRight={<IndicatorForm />}
      leftPanelSize={4}
      rightPanelSize={8}
    />
  );
}

export default ContentPanel;
