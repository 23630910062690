import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Collapse, List, Theme, ListItemText, ListItem } from '@material-ui/core';
import _ from 'lodash';
import TerritoryListItem from './TerritoryListItem';
import warehouseAPI from '../../../shared/api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TerritoryGroupList } from '../../../shared/types';
import { useUnitSearchStore } from '../index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topLevel: {
      fontSize: '15px',
      fontWeight: 600,
      color: theme.colors.black,
      textTransform: 'uppercase',
    },
    hidden: {
      visibility: 'hidden',
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '13px',
      letterSpacing: '0px',
      opacity: 1,
      position: 'relative',
      fontFamily: 'Poppins',
      '&:hover': {
        color: theme.colors.black,
      },
    },
    active: {
      color: theme.colors.blue,
    },
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.colors.black,
    },
  }),
);

function TerritoryListPicker() {
  const { data: lists } = useQuery<TerritoryGroupList[]>('fetchTerritoryLists', warehouseAPI.territoryList.fetchList);
  const [open, setOpen] = useState(false);

  const searchResults = useUnitSearchStore((state) => state.searchResults);

  const classes = useStyles();

  const renderExpandIcon = () => (open ? <ExpandMoreIcon /> : <ChevronRightIcon />);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={_.isEmpty(searchResults) ? '' : classes.hidden}>
        <List>
          <ListItem className={classes.listItem} button onClick={handleClick}>
            {renderExpandIcon()}
            <ListItemText primary={'Obszary'} className={classes.topLevel} classes={{ primary: classes.topLevel }} />
          </ListItem>

          <Collapse className={classes.nested} in={open} timeout={100} unmountOnExit>
            {lists?.map((territoryList) => (
              <TerritoryListItem key={territoryList.id} territory={territoryList} open={open} />
            ))}
          </Collapse>
        </List>
      </div>
    </>
  );
}

export default TerritoryListPicker;
