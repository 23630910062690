import React from 'react';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, List, Theme } from '@material-ui/core';
import _ from 'lodash';
import RegionListItem from './RegionListItem';
import warehouseAPI from '../../../shared/api';
import { AdministrativeUnit } from '../../../shared/types';
import { useUnitSearchStore } from '../index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topLevel: {
      fontSize: '15px',
      fontWeight: 600,
      color: theme.colors.black,
      textTransform: 'uppercase',
    },
    hidden: {
      visibility: 'hidden',
    },
  }),
);

function RegionPicker() {
  const { data: topLevelUnits } = useQuery<AdministrativeUnit[]>(
    'fetchAdministrativeUnits',
    warehouseAPI.administrativeUnit.topLevel,
  );

  const searchResults = useUnitSearchStore((state) => state.searchResults);

  const classes = useStyles();

  return (
    <>
      <div className={_.isEmpty(searchResults) ? '' : classes.hidden} style={{ overflow: 'auto' }}>
        <List>
          {topLevelUnits?.map((unit) => <RegionListItem key={unit.id} className={classes.topLevel} region={unit} />)}
        </List>
      </div>
    </>
  );
}

export default RegionPicker;
