import React from 'react';
import { List } from '@material-ui/core';
import { useQuery } from 'react-query';
import TerritoryListItem from './TerritoryListItem';
import { TerritoryGroup, TerritoryGroupList } from '../../shared/types';
import warehouseAPI from '../../shared/api';

interface Props {
  currentTerritory: TerritoryGroup | null;
  setCurrentGroup: React.Dispatch<any>;
  currentList: TerritoryGroupList | null;
  setCurrentList: React.Dispatch<any>;
  setIsTerritory: React.Dispatch<any>;
  type: string | null;
}

function TerritoryList(props: Props) {
  const { currentTerritory, setCurrentGroup, currentList, setCurrentList, setIsTerritory, type } = props;

  const { data: lists } = useQuery('fetchTerritoryLists', warehouseAPI.territoryList.fetchList);

  return (
    <List>
      {lists &&
        lists.map((list, index) => (
          <TerritoryListItem
            key={list.id}
            list={list}
            type={type}
            currentList={currentList}
            setCurrentList={setCurrentList}
            currentTerritory={currentTerritory}
            setCurrentGroup={setCurrentGroup}
            setIsTerritory={setIsTerritory}
            initialOpen={index === 0}
          />
        ))}
    </List>
  );
}

export default TerritoryList;
