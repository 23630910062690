import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { auth } from '../Auth/authSlice';
import Auth from '../Auth';
import DataBrowser from '../DataBrowser';
import IndicatorList from '../IndicatorBrowser/IndicatorList';
import IndicatorForm from '../IndicatorBrowser/IndicatorEditor';
import ReportList from '../ReportBrowser/ReportList';
import ReportForm from '../ReportBrowser/ReportEditor';
import GeneratedReportEditor from '../ReportBrowser/ReportList/GeneratedReports/GeneratedReportForm';

function Routes() {
  const { token } = useSelector(auth);

  return (
    <Switch>
      <Route exact path="/login" component={Auth} />
      {!token && <Redirect to="/login" />}
      <Route exact path="/">
        <Redirect to="/data" />
      </Route>
      <Route exact path="/data" component={DataBrowser} />
      <Route exact path="/indicators" component={IndicatorList} />
      <Route exact path="/indicators/add" component={IndicatorForm} />
      <Route exact path="/indicators/:id" component={IndicatorList} />
      <Route exact path="/indicators/:id/edit" component={IndicatorForm} />
      <Route exact path="/reports" component={ReportList} />
      <Route exact path="/reports/add" component={ReportForm} />
      <Route exact path="/reports/:id" component={ReportList} />
      <Route exact path="/reports/:id/edit" component={ReportForm} />
      <Route exact path="/reports/generated_report/:id" component={GeneratedReportEditor} />
      <Redirect to="/data" />
    </Switch>
  );
}

export default Routes;
