import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { createStyles, Theme, Typography } from '@material-ui/core';
import Title from '../../../../shared/components/Title';
import ComponentCard from '../../../IndicatorComponentCard';
import useIndicatorStore from '../../../indicatorStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '25px',
    },
    placeholder: {
      color: theme.colors.darkGray,
    },
  }),
);

function IndicatorComponents() {
  const components = useIndicatorStore((state) => state.editorComponents);

  const classes = useStyles();

  return (
    <>
      <Title className={classes.title} variant="h2" title="Składowe" />
      {_.isEmpty(components) ? (
        <Typography className={classes.placeholder} variant="body1">
          Dodaj składowe ze źródła danych
        </Typography>
      ) : (
        components.map((component, index) => (
          <ComponentCard key={component.label} index={index} component={component} showControls />
        ))
      )}
    </>
  );
}

export default IndicatorComponents;
