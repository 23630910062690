import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { IndicatorCategory } from '../../shared/types';

type ModalStore = {
  activeIndicatorCategory?: IndicatorCategory;
  openEditorModal: boolean;
  setOpenEditorModal: (v: boolean) => void;
  editIndicatorCategory: (category: IndicatorCategory) => void;
  closeEditorModal: () => void;
  openCopyModal: boolean;
  setOpenCopyModal: (v: boolean) => void;
  closeCopyModal: () => void;
  copyIndicatorCategory: (category: IndicatorCategory) => void;
};

const useModalStore = create<ModalStore>(
  devtools((set) => ({
    activeIndicatorCategory: undefined,
    openEditorModal: false,
    setOpenEditorModal: (v) => set(() => ({ openEditorModal: v })),
    editIndicatorCategory: (category) => set(() => ({ activeIndicatorCategory: category, openEditorModal: true })),
    closeEditorModal: () => set(() => ({ openEditorModal: false, activeIndicatorCategory: undefined })),
    openCopyModal: false,
    setOpenCopyModal: (v) => set(() => ({ openCopyModal: v })),
    closeCopyModal: () => set(() => ({ openCopyModal: false, activeIndicatorCategory: undefined })),
    copyIndicatorCategory: (category) => set(() => ({ activeIndicatorCategory: category, openCopyModal: true })),
  })),
);

export default useModalStore;
