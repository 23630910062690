import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {
  variant: 'h1' | 'h2' | 'h3' | 'h4';
  title: string;
  modal?: boolean;
  verticalCenter?: boolean;
  className?: string;
}

Title.defaultProps = {
  modal: false,
  className: '',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'left',
      color: theme.colors.black,
    },
    h1: {
      fontSize: (props: Props) => (props.modal ? '28px' : '32px'),
      letterSpacing: (props: Props) => (props.modal ? '-0.56px' : '-0.72px'),
      display: (props: Props) => (props.verticalCenter ? 'flex' : undefined),
      alignItems: (props: Props) => (props.verticalCenter ? 'center' : undefined),
    },
    h2: {
      letterSpacing: '0px',
    },
    h3: {
      letterSpacing: '0px',
    },
    h4: {
      letterSpacing: '0px',
      fontSize: (props: Props) => (props.modal ? '20px' : '13px'),
    },
  }),
);

function Title(props: Props) {
  const { className, title, variant } = props;
  const classes = useStyles(props);

  return (
    <Typography className={`${classes.root} ${classes[variant]} ${className}`} variant={variant}>
      {title}
    </Typography>
  );
}

export default Title;
