import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import RightPanelLayout from '../shared/components/RightPanelLayout';
import Background from '../shared/assets/data_background.png';
import Header from './Header';
import CubeDetails from './CubeDetails';
import CubeAnalysis from './CubeAnalysis';
import { currentCube, fetchCubeData } from './dataBrowserSlice';
import { AppDispatch } from '../app/store';
import { TableData } from '../shared/types';
import useGroupStore from '../Groups/groupStore';

const useStyles = makeStyles({
  form: {
    height: '100%',
  },
});

function ContentPanel() {
  const cube = useSelector(currentCube);

  const [table, setTable] = useState<TableData | null>(null);

  useEffect(() => setTable(null), [cube]);

  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();

  const [currentGroupId, isTerritory] = useGroupStore((state) => [state.currentGroupId, state.isTerritory]);
  const { enqueueSnackbar } = useSnackbar();

  const cubeHasCategoricalDimension = cube?.dimensions.some((dimension) => dimension.type === 'CATEGORICAL');

  const schema = Yup.object().shape({
    dimensionValues: cubeHasCategoricalDimension
      ? Yup.array()
          .of(
            Yup.object().shape({
              dimension: Yup.number().required('Wymiar musi mieć ID'),
              value: Yup.string().required('Wymiar musi mieć wartość'),
            }),
          )
          .required('Pole wymagane')
      : Yup.array(),
    years: Yup.array().of(Yup.number()).required(),
    measure: Yup.number().required('Wybierz miarę'),
  });

  if (!cube) {
    return <div />;
  }

  return (
    <Formik
      initialValues={{
        dimensionValues: cube?.dimensions
          .filter((dimension) => dimension.type === 'CATEGORICAL')
          .map((dimension) => ({ dimension: dimension.id, value: dimension.values[0] })),
        years: cube?.yearsAvailability || [],
        measure: cube?.measures[0].id || '',
      }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        if (cube) {
          try {
            const result = await dispatch(
              fetchCubeData({
                cubeId: cube.id,
                postData: { ...values, territory: isTerritory, group: currentGroupId },
              }),
            );
            setTable(unwrapResult(result));
          } catch (error) {
            const err = error as AxiosError;
            if (err.message.includes('400')) {
              enqueueSnackbar('Miara nie ma przypisanego sposobu agregacji', { variant: 'error' });
            } else {
              enqueueSnackbar('Błąd pobierania danych z kostki', { variant: 'error' });
            }
          }
        }
        setSubmitting(false);
      }}
      enableReinitialize
    >
      <Form className={classes.form}>
        <RightPanelLayout
          header={<Header />}
          contentLeft={cube && <CubeDetails cube={cube} />}
          contentRight={cube && <CubeAnalysis cube={cube} table={table} />}
          leftPanelSize={4}
          rightPanelSize={8}
          placeholder={Background}
        />
      </Form>
    </Formik>
  );
}

export default ContentPanel;
