import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import { getUnitHistoricalTerytCodesDescription } from '../../../shared/utils/helpers';
import { AdministrativeUnit } from '../../../shared/types';

interface Props {
  unit?: AdministrativeUnit;
  child: React.ReactElement;
  keyValue?: string | number;
}

function UnitTooltip(props: Props) {
  const { unit, child, keyValue } = props;

  const title = useMemo(() => (unit ? unit.comment || getUnitHistoricalTerytCodesDescription(unit) : ''), [unit]);

  return (
    <Tooltip interactive key={keyValue} title={title} disableHoverListener={!title}>
      {child}
    </Tooltip>
  );
}

export default UnitTooltip;
