import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import TextInput from '../../shared/components/forms/TextInput';
import GenericButton from '../../shared/components/GenericButton';
import warehouseAPI from '../../shared/api';
import Title from '../../shared/components/Title';
import { ReportFolder } from '../../shared/types';

type Props = {
  open: boolean;
  onClose: () => void;
  parent?: number;
  folder?: ReportFolder;
};

function FolderEditorModal(props: Props) {
  const { open, onClose, parent, folder } = props;

  const [mutate] = useMutation(warehouseAPI.reportFolder.save);
  const queryCache = useQueryCache();

  const { enqueueSnackbar } = useSnackbar();

  const schema = Yup.object({
    name: Yup.string().required('To pole nie może być puste.'),
    shortName: Yup.string().required('To pole nie może być puste.'),
    description: Yup.string(),
    parent: Yup.number().optional(),
  });

  const initialValues = () => {
    if (folder) {
      return { ...folder, parent: folder.parent || undefined };
    }

    return parent
      ? {
          name: '',
          shortName: '',
          description: '',
          parent,
        }
      : {
          name: '',
          shortName: '',
          description: '',
          parent: undefined,
        };
  };

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await mutate(values);
          await queryCache.invalidateQueries(['fetchReportFolder']);
          onClose();
        } catch (e) {
          enqueueSnackbar('Błąd zapisywania folderu', { variant: 'error' });
        }
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ dirty, isValid, submitForm }) => (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
          <DialogTitle disableTypography>
            <Title variant="h1" modal title={folder?.id ? 'Edytuj folder' : 'Dodaj folder'} />
          </DialogTitle>
          <DialogContent>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput fieldId="folderName" label="Nazwa" name="name" placeholder="Podaj nazwę" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fieldId="folderShortName"
                    label="Nazwa skrócona"
                    name="shortName"
                    placeholder="Podaj nazwę skróconą"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fieldId="folderDescription"
                    label="Opis"
                    name="description"
                    rows={5}
                    placeholder="Podaj opis"
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
              {folder?.id ? 'Zapisz' : 'Dodaj'}
            </GenericButton>
            <GenericButton variant="destructiveSecondary" onClick={onClose}>
              Anuluj
            </GenericButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

export default FolderEditorModal;
