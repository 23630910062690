import React, { useState } from 'react';
import { Report } from '../../shared/types';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from 'react-router-dom';
import useReportStore from '../reportStore';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import { useMutation, useQueryCache } from 'react-query';
import warehouseAPI from '../../shared/api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      color: theme.colors.blue,
    },
    deleteButton: {
      padding: 0,
    },
  }),
);

interface Props {
  report: Report;
  onReportClick?: (report: Report) => void;
}

function ReportItem(props: Props) {
  const { report, onReportClick } = props;

  const classes = useStyles();

  const [showDelete, setShowDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const { activeReport, setActiveReport } = useReportStore((state) => ({
    activeReport: state.activeReport,
    setActiveReport: state.setActiveReport,
  }));

  const [mutate] = useMutation(warehouseAPI.report.delete);
  const queryCache = useQueryCache();

  return (
    <>
      <ListItem
        key={report.id}
        button
        onClick={() => {
          setActiveReport(report);
          if (onReportClick) {
            onReportClick(report);
          }
        }}
        className={`${activeReport?.id === report.id ? classes.active : ''}`}
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
      >
        <ListItemText primary={`• ${report.name}`} />
        {showDelete && (
          <IconButton className={classes.deleteButton} onClick={() => setOpenConfirmModal(true)}>
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </ListItem>
      <ConfirmationModal
        open={openConfirmModal}
        title="Usuń raport"
        message="Czy na pewno usunąć raport?"
        onConfirm={async () => {
          if (report) {
            try {
              await mutate(report.id);
              await queryCache.invalidateQueries('fetchFolderReports');
              history.push('/reports');
              setActiveReport(undefined);
              setOpenConfirmModal(false);
            } catch (e) {
              enqueueSnackbar('Błąd przy usuwaniu raportu', { variant: 'error' });
            }
          }
        }}
        onCancel={() => setOpenConfirmModal(false)}
      />
    </>
  );
}

export default ReportItem;
