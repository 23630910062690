import React from 'react';

type Props = {
  color: string;
};

function Indicators(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-302 -75)">
        <rect style={{ opacity: 0 }} width="16" height="16" transform="translate(302 75)" />
        <g transform="translate(-328 -501)">
          <path
            style={{ fill: color }}
            d="M646.965,601.965h-5.585l-1.743-1.742a1,1,0,0,0-1.414,1.414l2.035,2.036.014.012a1.026,1.026,0,0,0,.138.113c.026.017.054.029.081.044a.923.923,0,0,0,.092.048.947.947,0,0,0,.1.032c.028.008.055.019.083.024a1,1,0,0,0,.2.02h6a1,1,0,0,0,0-2Z"
            transform="translate(-3.965 -11.965)"
          />
          <path
            style={{ fill: color }}
            d="M656.929,584h-2a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z"
            transform="translate(-11.965 -4)"
          />
          <path
            style={{ fill: color }}
            d="M635,590h0a.993.993,0,0,0,.559-.171.963.963,0,0,0,.148-.122A1,1,0,0,0,636,589v-4a1.045,1.045,0,0,0-.28-.692.185.185,0,0,0-.013-.016A1,1,0,0,0,635,584h-4a1,1,0,0,0-.39.078c-.011.005-.021.012-.031.018a.916.916,0,0,0-.138.075A1,1,0,0,0,630,585v4a1,1,0,0,0,1,1h4Zm-3-4h2v2h-2Z"
            transform="translate(0 -4)"
          />
          <path
            style={{ fill: color }}
            d="M647.636,579.742,649.379,578h3.585a1,1,0,0,0,0-2h-4a.991.991,0,0,0-.2.02c-.029.005-.055.016-.082.024a1.078,1.078,0,0,0-.1.033c-.031.013-.061.031-.091.047s-.055.027-.081.044a1.019,1.019,0,0,0-.135.11c-.005.005-.013.009-.018.014l-2.035,2.036a1,1,0,0,0,1.414,1.414Z"
            transform="translate(-7.965)"
          />
          <path style={{ fill: color }} d="M651,596h-4a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z" transform="translate(-8 -10)" />
        </g>
      </g>
    </svg>
  );
}

export default Indicators;
