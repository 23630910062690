import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, createStyles, Theme, Typography } from '@material-ui/core';
import { Field, useField } from 'formik';
import _ from 'lodash';
import Title from '../../../../shared/components/Title';
import GenericButton from '../../../../shared/components/GenericButton';
import operations from './operations';
import FormulaInput from './FormulaInput';
import FunctionPicker from './FunctionPicker';
import Average from './FunctionPicker/Average';
import ConstValue from './FunctionPicker/ConstValue';
import Difference from './FunctionPicker/Difference';
import Dynamic from './FunctionPicker/Dynamic';
import Standardization from './FunctionPicker/Standardization';
import ReplaceValue from './FunctionPicker/ReplaceValue';
import useIndicatorStore from '../../../indicatorStore';
import Sum from './FunctionPicker/Sum';
import Shift from './FunctionPicker/Shift';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '25px',
    },
    label: {
      color: theme.colors.darkGray,
    },
    token: {
      minWidth: '40px',
      height: '40px',
      marginRight: '10px',
      marginBottom: '20px',
    },
    container: {
      minHeight: '40px',
    },
  }),
);

interface Props {
  error?: boolean;
}

function IndicatorFormula(props: Props) {
  const { error = false } = props;

  const components = useIndicatorStore((state) => state.editorComponents);

  const [activeToken, setActiveToken] = useState<number | undefined>(undefined);

  const classes = useStyles();

  const [{ value: currentFormula }, , { setValue: setFormula }] = useField('formula');

  const addToken = (token: string) => {
    const formulaTokens = currentFormula.split(' ');
    if (_.isNumber(activeToken)) {
      if (activeToken === 0) {
        formulaTokens.unshift(token);
      } else {
        formulaTokens.splice(activeToken + 1, 0, token);
        setActiveToken(activeToken + 1);
      }
    } else {
      formulaTokens.push(token);
    }
    setFormula(formulaTokens.join(' '));
  };

  return (
    <>
      <Title className={classes.title} variant="h2" title="Formuła" />
      <Field name="formula">
        {() => (
          <div>
            <FormulaInput activeToken={activeToken} setActiveToken={setActiveToken} error={error} />
            <Typography className={classes.label} variant="body1">
              Operacje:
            </Typography>
            <Box className={classes.container} display="flex" justifyContent="flex-start" flexWrap="wrap">
              {operations.map((operation) => (
                <GenericButton
                  key={operation.name}
                  className={classes.token}
                  variant="tertiary"
                  onClick={() => addToken(operation.token)}
                >
                  {operation.label}
                </GenericButton>
              ))}
              <FunctionPicker
                functionName="Stała"
                className={classes.token}
                popoverContent={<ConstValue onSubmit={(token: string) => addToken(token)} />}
              />
              <FunctionPicker
                functionName="Średnia"
                className={classes.token}
                popoverContent={<Average onSubmit={(token: string) => addToken(token)} />}
              />
              <FunctionPicker
                functionName="Różnica"
                className={classes.token}
                popoverContent={<Difference onSubmit={(token: string) => addToken(token)} />}
              />
              <FunctionPicker
                functionName="Dynamika"
                className={classes.token}
                popoverContent={<Dynamic onSubmit={(token: string) => addToken(token)} />}
              />
              <FunctionPicker
                functionName="Standaryzacja"
                className={classes.token}
                popoverContent={<Standardization onSubmit={(token: string) => addToken(token)} />}
              />
              <FunctionPicker
                functionName="Suma"
                className={classes.token}
                popoverContent={<Sum onSubmit={(token: string) => addToken(token)} />}
              />
              <FunctionPicker
                functionName="Przesunięcie"
                className={classes.token}
                popoverContent={<Shift onSubmit={(token: string) => addToken(token)} />}
              />
              <FunctionPicker
                functionName="Uzupełnienie"
                className={classes.token}
                popoverContent={<ReplaceValue onSubmit={(token: string) => addToken(token)} />}
              />
              <GenericButton className={classes.token} variant="destructiveSecondary" onClick={() => setFormula('')}>
                Wyczyść
              </GenericButton>
            </Box>
            <Typography className={classes.label} variant="body1">
              Składowe:
            </Typography>
            <Box className={classes.container} display="flex" justifyContent="flex-start">
              {components.map((component) => (
                <GenericButton
                  key={component.label}
                  className={classes.token}
                  variant="secondary"
                  onClick={() => component.label && addToken(component.label)}
                >
                  {component.label}
                </GenericButton>
              ))}
            </Box>
          </div>
        )}
      </Field>
    </>
  );
}

export default IndicatorFormula;
