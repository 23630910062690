import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { TableData } from '../types';
import useGroupStore from '../../Groups/groupStore';
import useTerritoryStore from '../../Groups/groupStore';
import warehouseAPI from '../../shared/api';
import { useQuery } from 'react-query';
import UnitTooltip from '../../Groups/GroupEditorModal/ChosenUnits/UnitTooltip';
import { History, Info } from '@material-ui/icons';

const useStyles = makeStyles({
  numberValue: {
    fontFamily: 'Inconsolata',
  },
  tableHeaderLabel: {
    fontWeight: 'bold',
  },
});

interface Props {
  data: TableData;
  precision?: number;
}

type Order = 'asc' | 'desc';

function DataTable(props: Props) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('Jednostka');

  const {
    data: { header, rows },
    precision = 2,
  } = props;

  const currentGroupId = useGroupStore((state) => state.currentGroupId);
  const isTerritory = useTerritoryStore((state) => state.isTerritory);
  const { data: units } = useQuery(['fetchGroupUnits', currentGroupId], warehouseAPI.administrativeGroup.units);

  const classes = useStyles();

  const formatCellValue = (value: string | number | null) => {
    if (typeof value === 'string') {
      return value;
    }
    if (_.isNumber(value)) {
      // French locale used because pl-PL doesn't have thousand separators below 10 000
      return value.toLocaleString('fr-FR', { minimumFractionDigits: precision, maximumFractionDigits: precision });
    }
    return null;
  };

  const sortRows = () => {
    const headerIndex = header.findIndex((item) => item === orderBy);
    return _.orderBy(rows, [(item) => item[headerIndex]], [order]);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {header.map((column, index) => (
            <TableCell key={column} align="center" style={{ width: index === 0 ? 300 : undefined }}>
              <TableSortLabel
                className={classes.tableHeaderLabel}
                style={{ width: index === 0 ? 300 : undefined }}
                active={orderBy === column}
                direction={orderBy === column ? order : 'asc'}
                onClick={() => {
                  const inverseOrder = (order: Order) => {
                    const inverseOrderDict = {
                      asc: 'desc',
                      desc: 'asc',
                    };
                    return inverseOrderDict[order] as Order;
                  };
                  setOrder(orderBy === column ? inverseOrder(order) : 'asc');
                  setOrderBy(column);
                }}
              >
                {column}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {units && !isTerritory
          ? sortRows().map((row) => (
              <TableRow hover key={row[0]}>
                {row.map((value, index) => {
                  if (index === 0) {
                    const unit = units?.[0].find((u) => (value as string).includes(u.name));
                    return (
                      <UnitTooltip
                        unit={unit}
                        key={`${row[0]}-${header[index]}`}
                        keyValue={`${row[0]}-${header[index]}`}
                        child={
                          <TableCell
                            key={`${row[0]}-${header[index]}`}
                            align={index === 0 ? 'left' : 'right'}
                            className={index !== 0 ? classes.numberValue : ''}
                          >
                            {formatCellValue(value)}
                            {unit?.comment ? <Info /> : !_.isEmpty(unit?.historicalTerytCodes) && <History />}
                          </TableCell>
                        }
                      />
                    );
                  }
                  return (
                    <TableCell
                      key={`${row[0]}-${header[index]}`}
                      align={index === 0 ? 'left' : 'right'}
                      className={index !== 0 ? classes.numberValue : ''}
                    >
                      {formatCellValue(value)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          : null}
        {isTerritory
          ? sortRows().map((row) => (
              <TableRow hover key={row[0]}>
                {row.map((value, index) =>
                  index === 0 ? (
                    <TableCell
                      key={`${row[0]}-${header[index]}`}
                      align={index === 0 ? 'left' : 'right'}
                      className={index !== 0 ? classes.numberValue : ''}
                    >
                      {formatCellValue(value)}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={`${row[0]}-${header[index]}`}
                      align={index === 0 ? 'left' : 'right'}
                      className={index !== 0 ? classes.numberValue : ''}
                    >
                      {formatCellValue(value)}
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))
          : null}
      </TableBody>
    </Table>
  );
}

export default DataTable;
