import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikValues } from 'formik';
import { DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import { PopoverContext } from './index';
import GenericButton from '../../../../../shared/components/GenericButton';

interface Props {
  onSubmit: (token: string) => void;
}

function Standardization(props: Props) {
  const { onSubmit } = props;

  const { onClose } = useContext(PopoverContext);

  const schema = Yup.object({
    type: Yup.string().required('To pole nie może być puste.'),
  });

  const getToken = (values: FormikValues) => {
    const { type } = values;

    if (type === 'ktsStandardization') {
      return 'STD[TIME] (';
    }
    if (type === 'timeStandardization') {
      return 'STD[KTS] (';
    }
    if (type === 'mrlStandardization') {
      return 'STD[MRL] (';
    }
    return null;
  };

  return (
    <Formik
      initialValues={{
        type: '',
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        const token = getToken(values);
        if (token) {
          onSubmit(token);
        }
        if (onClose) {
          onClose();
        }
      }}
    >
      {({ dirty, isValid, isSubmitting, submitForm }) => (
        <Form>
          <DialogTitle disableTypography>
            <Typography variant="body2">Dodaj standaryzację</Typography>
          </DialogTitle>
          <DialogContent>
            <Field component={RadioGroup} name="type">
              <FormControlLabel
                value="ktsStandardization"
                control={<Radio color="primary" />}
                label="Standaryzacja dla jednostek"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="timeStandardization"
                control={<Radio color="primary" />}
                label="Standaryzacja dla lat"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="mrlStandardization"
                control={<Radio color="primary" />}
                label="Standaryzacja MRL"
                disabled={isSubmitting}
              />
            </Field>
          </DialogContent>
          <DialogActions>
            <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
              Dodaj
            </GenericButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default Standardization;
