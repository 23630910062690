import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { API_URL } from '../../../shared/utils/config';
import { useUnitSearchStore } from '../index';

export default function UnitSearchBar() {
  const [value, setValue] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const [updateSearchResults, clearSearchResults] = useUnitSearchStore((state) => [
    state.updateSearchResults,
    state.clearSearchResults,
  ]);

  const handleRequestSearch = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/units`, { params: { search: value } });
      updateSearchResults(response.data.results);
    } catch (e) {
      enqueueSnackbar('Błąd wyszukiwania', { variant: 'error' });
    }
  }, [value, enqueueSnackbar, updateSearchResults]);

  const handleClear = () => {
    setValue('');
    clearSearchResults();
  };

  const handleInput = (e: any) => setValue(e.target.value);

  const handleKeyUp = React.useCallback(
    (e) => {
      if (e.charCode === 13 || e.key === 'Enter') {
        handleRequestSearch();
      }
    },
    [handleRequestSearch],
  );

  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleRequestSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder="Wyszukaj jednostki"
      type="text"
      fullWidth
      onKeyUp={handleKeyUp}
      onChange={handleInput}
      value={value}
    />
  );
}
