import React, { useState } from 'react';
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Theme,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import warehouseAPI from '../../../../shared/api';
import IndicatorCategoryListItem from './IndicatorCategoryListItem';
import GenericButton from '../../../../shared/components/GenericButton';
import Check from '../../../../shared/components/icons/Check';
import CategoryEditorModal from '../../../CategoryEditorModal';
import Title from '../../../../shared/components/Title';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '60%',
    },
    addButton: {
      width: '100%',
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: 'white',
      '&:hover': {
        boxShadow: `0px 6px 10px ${theme.colors.darkGray}`,
        backgroundColor: theme.colors.blue,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    arrow: {
      fontSize: '16px',
      marginLeft: '8px',
    },
  }),
);

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (id: number | null) => void;
  toShowDropdown?: boolean;
  allowEmptySelection?: boolean;
};

const IndicatorFolderModal: React.FC<Props> = ({
  open,
  onClose,
  onSelect,
  toShowDropdown = true,
  allowEmptySelection = false,
}) => {
  const [openEditorModal, setOpenEditor] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null);

  const { data: categoryTree = [], isLoading } = useQuery('fetchIndicatorTree', warehouseAPI.indicatorCategory.tree);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} PaperProps={{ className: classes.root }}>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Title variant="h1" modal title="Wybierz folder" />
          {toShowDropdown && (
            <GenericButton
              variant="primary"
              shape="circle"
              onClick={() => {
                setActiveCategoryId(null);
                setOpenEditor(true);
              }}
            >
              Dodaj nowy
            </GenericButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={200} />
          </Box>
        ) : (
          <List disablePadding>
            {categoryTree?.map((category) => (
              <IndicatorCategoryListItem
                toShowDropdown={toShowDropdown}
                key={category.id}
                category={category}
                activeId={activeCategoryId}
                setActive={setActiveCategoryId}
                openFolderEditor={() => setOpenEditor(true)}
              />
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <GenericButton
          variant="primary"
          disabled={!(allowEmptySelection || activeCategoryId)}
          icon={<Check color={theme.colors.white} />}
          onClick={() => {
            if (allowEmptySelection || typeof activeCategoryId === 'number') {
              onSelect(activeCategoryId);
              setActiveCategoryId(null);
              onClose();
            }
          }}
        >
          Wybierz
        </GenericButton>
        <GenericButton variant="destructiveSecondary" onClick={onClose}>
          Anuluj
        </GenericButton>
      </DialogActions>

      <CategoryEditorModal
        open={openEditorModal}
        onClose={() => setOpenEditor(false)}
        parent={activeCategoryId || undefined}
      />
    </Dialog>
  );
};

export default IndicatorFolderModal;
