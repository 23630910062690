import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { Formik, Form, Field, FieldArray, FieldProps } from 'formik';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import warehouseAPI from '../../../../shared/api';
import { TipTap } from './TipTap';
import GenericButton from '../../../../shared/components/GenericButton';
import { ReportContent } from '../../../../shared/types';

type Params = {
  id: string;
};

const {
  report: { editContent },
} = warehouseAPI;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btns: {
      width: '10%',
      display: 'flex',
      padding: '0',
      verticalAlign: 'bottom',
      marginTop: theme.spacing(1),
    },
    select: {
      width: '57%',
      height: '28px',
      marginTop: theme.spacing(1),
    },
    main: {
      // backgroundColor: theme.colors.white,
      height: 'calc(80vh - 50px)',
      overflow: 'auto',
      margin: 'auto',
      padding: '1rem',
      paddingBottom: '0',
    },
    mainWrapper: {
      height: '95%',
      backgroundColor: theme.colors.white,
      width: '90%',
      boxShadow: '14px 13px 24px -11px rgba(66, 68, 90, 1);',
      margin: 'auto',
      justifyContent: 'center',
    },
  }),
);

const GeneratedReportEditor: React.FC = () => {
  const { id } = useParams<Params>();
  const queryCache = useQueryCache();

  const { data: report } = useQuery(['fetchGeneratedReport', Number(id)], () =>
    warehouseAPI.report.generatedReport(Number(id)),
  );
  const { enqueueSnackbar } = useSnackbar();
  interface editRequest {
    reportId: number;
    content: ReportContent;
  }

  const [mutate] = useMutation((data: editRequest) => editContent(data.reportId, data.content), {
    onSuccess: () => {
      enqueueSnackbar('Raport dodany do kolejki.', {
        variant: 'success',
        autoHideDuration: 3000,
        disableWindowBlurListener: true,
      });
      history.push('/reports');
      queryCache.invalidateQueries(['fetchGeneratedReport', Number(id)]);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });
  const onSubmit = (reportId?: number, content?: ReportContent) => {
    if (content && reportId) {
      mutate({ reportId, content });
    }
  };

  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <h1>Edycja raportu {report?.content?.title.join(' ')}</h1>

      {report?.content && (
        <Formik
          enableReinitialize
          initialValues={report}
          onSubmit={(values) => onSubmit(report.id, values.content)}
          render={({ values, setFieldValue, submitForm, isSubmitting }) => (
            <Form>
              <div className={classes.mainWrapper}>
                <div className={classes.main}>
                  <FieldArray
                    name="subareas"
                    render={(_arrayHelpers) => (
                      <div>
                        {values?.content?.areas && values?.content?.areas?.length > 0
                          ? values?.content?.areas.map((area, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{
                                    margin: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    width: '80%',
                                  }}
                                >
                                  <h2>{area.name}</h2>
                                  <Field name={`content.areas[${i}].description`}>
                                    {({ field }: FieldProps) => (
                                      <div>
                                        <TipTap
                                          onContentChange={(value) => setFieldValue(field.name, value)}
                                          initialContent={area.description}
                                        />
                                      </div>
                                    )}
                                  </Field>

                                  {area.subareas.map((subarea, subareaIndex) => (
                                    <>
                                      <h3>{values?.content?.areas[i].subareas[subareaIndex].name}</h3>
                                      <Field name={`content.areas[${i}].subareas[${subareaIndex}].description`}>
                                        {({ field }: FieldProps) => (
                                          <div>
                                            <TipTap
                                              onContentChange={(value) => setFieldValue(field.name, value)}
                                              initialContent={subarea.description}
                                            />
                                          </div>
                                        )}
                                      </Field>
                                    </>
                                  ))}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    )}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    columnGap: '20px',
                    padding: '0',
                    alignItems: 'center',
                    height: '5rem',
                    marginRight: '2rem',
                  }}
                >
                  <GenericButton
                    className={classes.btns}
                    variant="primary"
                    shape="square"
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    Zapisz zmiany
                  </GenericButton>
                  <GenericButton
                    variant="destructivePrimary"
                    disabled={false}
                    shape="square"
                    className={classes.btns}
                    onClick={() => history.push('/reports')}
                  >
                    Odrzuć zmiany
                  </GenericButton>
                </div>
              </div>
            </Form>
          )}
        />
      )}
    </>
  );
};

export default GeneratedReportEditor;
