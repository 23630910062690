import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, IconButton, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryCache } from 'react-query';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IndicatorCategory } from '../../../../shared/types';
import warehouseAPI from '../../../../shared/api';
import ConfirmationModal from '../../../../shared/components/ConfirmationModal';

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.colors.black,
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '13px',
      letterSpacing: '0px',
      color: (props: StyleProps) => (props.open ? theme.colors.black : theme.colors.darkGray),
      opacity: 1,
      position: 'relative',
      fontFamily: 'Poppins',
      '&:hover': {
        color: theme.colors.black,
      },
    },
    childrenCount: {
      background: (props: StyleProps) =>
        `${props.open ? theme.colors.darkGray : theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      opacity: 1,
      width: '27px',
      textAlign: 'center',
      color: (props: StyleProps) => (props.open ? theme.colors.white : theme.colors.darkGray),
    },
    active: {
      color: theme.colors.blue,
    },
    addButton: {
      '&:hover': {
        color: theme.colors.blue,
      },
    },
  }),
);

interface Props {
  category: IndicatorCategory;
  activeId: number | null;
  setActive: (id: number | null) => void;
  openFolderEditor: () => void;
  toShowDropdown?: boolean;
}

const IndicatorCategoryListItem: React.FC<Props> = ({
  category,
  activeId,
  setActive,
  openFolderEditor,
  toShowDropdown = true,
}) => {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const classes = useStyles({ open });

  const [mutate] = useMutation(warehouseAPI.indicatorCategory.delete);
  const queryCache = useQueryCache();

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    if (category.id) {
      category.id === activeId ? setActive(null) : setActive(category.id);
    }
    setOpen(!open);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <ListItem className={classes.listItem} button onClick={handleClick}>
          <ListItemText className={category.id === activeId ? classes.active : ''} primary={`${category.name}`} />
          {category.children && (
            <Typography className={classes.childrenCount} variant="caption">
              {category.children.length}
            </Typography>
          )}
        </ListItem>
        {toShowDropdown && (
          <IconButton
            onClick={() => {
              if (category.id) {
                setActive(category.id);
              }
              openFolderEditor();
            }}
          >
            <AddCircleOutlineIcon className={classes.addButton} />
          </IconButton>
        )}
        {toShowDropdown && (
          <IconButton
            disabled={category.indicatorCount !== 0 || !_.isEmpty(category.children)}
            onClick={() => setOpenDelete(true)}
          >
            <DeleteOutlinedIcon className={classes.addButton} />
          </IconButton>
        )}
        <ConfirmationModal
          open={openDelete}
          title="Usuń folder"
          message="Czy na pewno usunąć folder?"
          onConfirm={async () => {
            try {
              await mutate(category.id);
              await queryCache.invalidateQueries('fetchIndicatorTree');
              setOpenDelete(false);
            } catch (e) {
              enqueueSnackbar('Błąd przy usuwaniu folderu', { variant: 'error' });
            }
          }}
          onCancel={() => setOpenDelete(false)}
        />
      </Box>
      {toShowDropdown && category.children && (
        <Collapse in={open} className={classes.nested} unmountOnExit>
          {category.children.map((childCategory) => (
            <IndicatorCategoryListItem
              key={childCategory.id}
              category={childCategory}
              activeId={activeId}
              setActive={setActive}
              openFolderEditor={openFolderEditor}
              toShowDropdown={toShowDropdown}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default IndicatorCategoryListItem;
