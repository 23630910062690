import React from 'react';

type Props = {
  color: string;
};

function Cancel(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-354 -39)">
        <rect style={{ opacity: 0 }} width="16" height="16" transform="translate(354 39)" />
        <g transform="translate(-4 2)">
          <g transform="translate(-326 -540)">
            <path
              style={{ fill: color }}
              d="M981.186,454.546l-3.414-3.414,3.38-3.38a1,1,0,1,0-1.415-1.414l-3.38,3.38-3.414-3.414a1,1,0,0,0-1.414,1.414l3.414,3.414-3.448,3.448a1,1,0,0,0,1.415,1.414l3.447-3.448,3.414,3.414a1,1,0,1,0,1.415-1.414Z"
              transform="translate(-284.203 133.988)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Cancel;
