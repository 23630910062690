import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../../shared/components/Title';
import useIndicatorStore from '../../indicatorStore';
import { IndicatorComponent } from '../../../shared/types';
import CubeComponentForm from './CubeComponentForm';
import IndicatorComponentForm from './IndicatorComponentForm';

const useStyles = makeStyles({
  title: {
    marginBottom: '25px',
  },
});

function ComponentForm() {
  const classes = useStyles();

  const component = useIndicatorStore((state) => state.activeComponent);

  const renderComponent = (indicatorComponent: IndicatorComponent) => {
    if (indicatorComponent.type === 'CUBE') {
      return <CubeComponentForm cubeId={indicatorComponent.sourceCube as number} />;
    }
    if (indicatorComponent.type === 'INDICATOR') {
      return <IndicatorComponentForm indicatorId={indicatorComponent.sourceIndicator as number} />;
    }
    return null;
  };

  return (
    <>
      <Title className={classes.title} variant="h2" title={component ? 'Podgląd źródła' : 'Wybierz źródło danych'} />
      {component && renderComponent(component)}
    </>
  );
}

export default ComponentForm;
