import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import GenericButton from './GenericButton';

interface Props {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmationModal(props: Props) {
  const { open, onConfirm, onCancel, title, message } = props;

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <GenericButton variant="primary" onClick={onConfirm}>
          Ok
        </GenericButton>
        <GenericButton variant="destructiveSecondary" onClick={onCancel}>
          Anuluj
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
