import React from 'react';
import RightPanelLayout from '../../shared/components/RightPanelLayout';
import Header from './Header';
import ReportForm from './ReportForm';

function ContentPanel() {
  return <RightPanelLayout header={<Header />} contentLeft={<ReportForm />} leftPanelSize={8} rightPanelSize={1} />;
}

export default ContentPanel;
