import React from 'react';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import './style.css';

interface Props {
  initialContent?: string;
  onContentChange: (content: string) => void;
  changeDebounceTime?: number;
}

export const TipTap: React.FC<Props> = ({ initialContent, onContentChange, changeDebounceTime = 1000 }) => {
  const [changeSubject] = useState(new Subject<string | undefined>());
  const editor = useEditor({
    extensions: [
      StarterKit,
      Document,
      Bold,
      Italic,
      Underline,
      OrderedList,
      BulletList,
      Text,
      Paragraph,
      TextStyle,
      ListItem,
    ],
    content: initialContent,
    onUpdate: ({ editor: currentEditor }) => changeSubject.next(currentEditor.getHTML()),
  });

  useEffect(() => {
    if (initialContent && editor) {
      editor.commands.setContent(initialContent);
    }
  }, [editor, initialContent]);

  useEffect(() => {
    const sub = changeSubject.pipe(debounceTime(changeDebounceTime), distinctUntilChanged()).subscribe((newContent) => {
      if (newContent && newContent.length > 0) {
        onContentChange(newContent);
      }
    });
    return () => sub.unsubscribe();
  }, [changeDebounceTime, changeSubject, onContentChange]);

  return (
    <div className="tiptap">
      {editor && <TipTapToolBar editor={editor} />}
      <EditorContent editor={editor} className="tiptap-editor" />
    </div>
  );
};

interface ToolBarProps {
  editor: Editor;
}

const TipTapToolBar: React.FC<ToolBarProps> = ({ editor }) => {
  return (
    <div className="tip-tap-toolbar">
      <button
        className={editor.isActive('bold') ? 'active' : undefined}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          editor.commands.toggleBold();
        }}
      >
        B
      </button>
      <button
        className={editor.isActive('italic') ? 'active' : undefined}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          editor.commands.toggleItalic();
        }}
      >
        I
      </button>
      <button
        className={editor.isActive('underline') ? 'active' : undefined}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          editor.commands.toggleUnderline();
        }}
      >
        U
      </button>
      <button
        className={editor.isActive('orderedList') ? 'active' : undefined}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          editor.commands.toggleOrderedList();
        }}
      >
        <FormatListNumberedIcon />
      </button>
      <button
        className={editor.isActive('bulletList') ? 'active' : undefined}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          editor.commands.toggleBulletList();
        }}
      >
        <FormatListBulletedIcon />
      </button>
    </div>
  );
};
