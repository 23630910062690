import React from 'react';

type Props = {
  color: React.CSSProperties['color'];
};

function Groups(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-354 -39)">
        <rect style={{ opacity: 0 }} className="a" width="16" height="16" transform="translate(354 39)" />
        <g transform="translate(-380.186 -491.93)">
          <path
            style={{ fill: color }}
            className="b"
            d="M1.013,0H8.562a1.013,1.013,0,0,1,0,2.026H1.013A1.013,1.013,0,0,1,0,1.013H0A1.013,1.013,0,0,1,1.013,0Z"
            transform="translate(739.978 541.699) rotate(-45)"
          />
          <path
            style={{ fill: color }}
            className="b"
            d="M1.013,1.414h0A1.013,1.013,0,0,1,2.026,2.427V6.376A1.013,1.013,0,1,1,0,6.376V2.427A1.013,1.013,0,0,1,1.013,1.414Z"
            transform="translate(736.186 537.908) rotate(-45)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Groups;
