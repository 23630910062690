import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CubeForm from './CubeForm';
import Title from '../../shared/components/Title';
import { Cube } from '../../shared/types';

const useStyles = makeStyles({
  title: {
    marginBottom: '25px',
  },
});

interface Props {
  cube: Cube;
}

function CubeDetails({ cube }: Props) {
  const classes = useStyles();

  return (
    <>
      <Title className={classes.title} variant="h2" title="Szczegóły" />
      <Grid container spacing={3}>
        <CubeForm cube={cube} />
      </Grid>
    </>
  );
}

export default CubeDetails;
