import React, { useState } from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import _ from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TerritoryGroupList } from '../../../shared/types';
import { useActiveUnitStore, useTerritoryStore } from '../index';

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.colors.black,
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '13px',
      letterSpacing: '0px',
      color: (props: StyleProps) => (props.open ? theme.colors.black : theme.colors.darkGray),
      opacity: 1,
      position: 'relative',
      fontFamily: 'Poppins',
      '&:hover': {
        color: theme.colors.black,
      },
    },
    active: {
      color: theme.colors.blue,
    },
    addButton: {
      '&:hover': {
        color: theme.colors.blue,
      },
    },
  }),
);

interface Props {
  territory: TerritoryGroupList;
  open: boolean;
}

function TerritoryListItem(props: Props) {
  const { territory } = props;

  const [open, setOpen] = useState(false);

  const classes = useStyles({ open: !_.isEmpty(territory) && open });

  const setActiveUnitId = useActiveUnitStore((state) => state.setActiveUnitId);
  const [activeTerritoryId, setActiveTerritoryId] = useTerritoryStore((state) => [
    state.activeTerritoryId,
    state.setActiveTerritoryId,
  ]);

  const handleClick = () => {
    setActiveUnitId(undefined);
    setActiveTerritoryId(territory.id);
    setOpen(!open);
  };

  return (
    <>
      <ListItem className={classes.listItem} button onClick={handleClick}>
        <ListItemText className={territory.id === activeTerritoryId ? classes.active : ''} primary={territory.name} />
      </ListItem>
    </>
  );
}

export default TerritoryListItem;
