import React from 'react';

type Props = {
  color: string;
};

function IndicatorAdd(props: Props) {
  const { color } = props;
  return (
    <svg style={{ fill: '#3E75D5' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-354 -39)">
        <rect style={{ opacity: 1 }} width="16" height="16" transform="translate(354 39)" />
        <g transform="translate(-172 -541)">
          <path
            style={{ fill: color }}
            d="M527,586a1,1,0,0,0,1-1v-1h1a1,1,0,0,0,0-2h-2a1,1,0,0,0-.39.078c-.011.005-.021.012-.031.018a.916.916,0,0,0-.138.075A1,1,0,0,0,526,583v2A1,1,0,0,0,527,586Z"
          />
          <path
            style={{ fill: color }}
            d="M529,600h-1v-1a1,1,0,0,0-2,0v2a1,1,0,0,0,.078.39c0,.011.012.021.017.031a1.02,1.02,0,0,0,.075.138A1,1,0,0,0,527,602h2a1,1,0,0,0,0-2Z"
            transform="translate(0 -8)"
          />
          <path
            style={{ fill: color }}
            d="M541,588.952h-2V587a1,1,0,0,0-2,0v1.952h-2a1,1,0,0,0,0,2h2V593a1,1,0,0,0,2,0v-2.048h2a1,1,0,0,0,0-2Z"
            transform="translate(-4 -2)"
          />
          <path
            style={{ fill: color }}
            d="M553,598a1,1,0,0,0-1,1v1h-1a1,1,0,0,0,0,2h2a1,1,0,0,0,.389-.078c.011-.005.021-.012.032-.018a1.014,1.014,0,0,0,.138-.075A1,1,0,0,0,554,601v-2A1,1,0,0,0,553,598Z"
            transform="translate(-12 -8)"
          />
          <path
            style={{ fill: color }}
            d="M551,584h1v1a1,1,0,0,0,2,0v-2a1,1,0,0,0-.078-.389c-.005-.011-.013-.021-.018-.032a.941.941,0,0,0-.075-.138A1,1,0,0,0,553,582h-2a1,1,0,0,0,0,2Z"
            transform="translate(-12)"
          />
        </g>
      </g>
    </svg>
  );
}

export default IndicatorAdd;
