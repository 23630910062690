import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Grid, Theme, Typography } from '@material-ui/core';
import { Dimension } from '../../shared/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.colors.darkGray,
      fontSize: '13px',
      fontWeight: 400,
    },
    value: {
      color: theme.colors.black,
      fontSize: '13px',
      fontWeight: 600,
    },
  }),
);

interface Props {
  dimension: Dimension;
  value: string;
}

function DimensionValuesItem(props: Props) {
  const { dimension, value } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography className={classes.label}>{dimension.name}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Grid>
  );
}

export default DimensionValuesItem;
