import React from 'react';

type Props = {
  color: string;
};

function Reports(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-328 -75)">
        <rect style={{ opacity: 0 }} width="16" height="16" transform="translate(328 75)" />
        <path
          style={{ fill: color }}
          d="M850.577,472.185a1.051,1.051,0,0,0-.1-.125l-3.535-3.535A2.639,2.639,0,0,0,846,468h-4a1.009,1.009,0,0,0-1,1v3h-3a1.009,1.009,0,0,0-1,1v10a1.011,1.011,0,0,0,1,1h8a1,1,0,0,0,.389-.078c.011-.005.021-.012.032-.018a.935.935,0,0,0,.138-.075A1,1,0,0,0,847,483v-3h3a1,1,0,0,0,.389-.078c.011-.005.021-.012.032-.018a.935.935,0,0,0,.138-.075A1,1,0,0,0,851,479v-6A1,1,0,0,0,850.577,472.185ZM846,478h-3v-8h2.586L849,473.414V478Zm-1,4h-6v-8h2v5a1.011,1.011,0,0,0,1,1h3Z"
          transform="translate(-508 -393)"
        />
      </g>
    </svg>
  );
}

export default Reports;
