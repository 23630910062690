import {
  Box,
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Theme,
  createStyles,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import {
  CloudDownloadOutlined,
  Done,
  Cached,
  Error,
  Assignment,
  Edit,
  HourglassEmpty,
  Warning,
  Settings,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import warehouseApi from '../../../shared/api';
import { ReportContent } from '../../../shared/types';

const {
  report: { generatedReportsList, download, refreshGeneratedReport },
} = warehouseApi;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    generatedReportsBox: {
      boxSizing: 'border-box',
      flexGrow: 1,
      height: '80%',
      background: `white 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      position: 'relative',
    },
    generatedReportsBoxHeader: {
      padding: '20px 40px',
      borderBottom: `1px solid #F6F6F6`,
      boxSizing: 'border-box',
      height: '86px',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    generatedReportsBoxContent: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      padding: '30px',
      overflowY: 'auto',
      maxHeight: 'calc(50vh - 70px)',
      maxWidth: '100%',
    },
    generatedReportsList: {
      height: 'auto',
      width: '100%',
      padding: '0px 0px',
    },
    generatedReportsListItem: {
      padding: '10px 0px',
      '&:first-of-type': {
        padding: '0px 0px 10px 0px',
      },
    },
    title: {
      fontSize: '32px',
    },
    generatedReportDownloadButton: {
      root: {
        maxHeight: '33px',
      },
    },
    iconButton: {
      padding: '0px',
      width: '24px',
      height: '24px',
      minWidth: '30px',
      marginLeft: 'auto',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    iconButtonV2: {
      minWidth: '12px',
    },
    iconStyle: {
      outline: 'none',
      fontSize: '24px',
      color: 'black',
    },
    ready: {
      color: 'green',
    },
    error: {
      color: theme.colors.red,
    },
    progress: {
      color: theme.colors.blue,
    },
    queue: {
      color: theme.colors.black,
    },
  }),
);

interface Params {
  reportId: number;
}

const GeneratedReports: React.FC<Params> = ({ reportId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, isSuccess } = useQuery(['fetchGeneratedReports', reportId], () =>
    generatedReportsList(reportId),
  );
  const downloadHTML = (id: number) => download.html(id);
  const downloadPDF = (id: number) => download.pdf(id);
  const history = useHistory();
  const queryCache = useQueryCache();

  interface refreshRequest {
    reportId: number;
    content?: ReportContent;
  }

  const [mutate] = useMutation((data: refreshRequest) => refreshGeneratedReport(data.reportId, data.content), {
    onSuccess: () => {
      queryCache.invalidateQueries(['fetchGeneratedReports']);
      setAnchorEl(null);
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });

  const refreshReport = (reportId: number, content: ReportContent | undefined) => {
    mutate({ reportId, content });
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleClick = (index: number) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIcon = (status: string, errorMessage?: string) => {
    switch (status) {
      case 'READY': {
        return (
          <Tooltip title="Gotowy">
            <IconButton>
              <Done className={classes.ready} />
            </IconButton>
          </Tooltip>
        );
      }
      case 'FAILED': {
        return (
          <Tooltip title={`Wystąpił błąd ${errorMessage ?? ''}`}>
            <IconButton>
              <Error className={classes.error} />
            </IconButton>
          </Tooltip>
        );
      }
      case 'IN_QUEUE': {
        return (
          <Tooltip title="W kolejce">
            <IconButton>
              <HourglassEmpty className={classes.queue} />
            </IconButton>
          </Tooltip>
        );
      }
      case 'IN_PROGRESS': {
        return (
          <Tooltip title="W trakcie przetwarzania">
            <IconButton>
              <Cached className={classes.progress} />
            </IconButton>
          </Tooltip>
        );
      }
      case 'WARNING': {
        return (
          <Tooltip title={`Wystąpił błąd we wskaźnikach: ${errorMessage ?? ''}`}>
            <IconButton>
              <Warning className={classes.error} />
            </IconButton>
          </Tooltip>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <Box className={classes.generatedReportsBox}>
      <Box className={classes.generatedReportsBoxContent}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Jednostka/Obszar/Grupa</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="left">Data</TableCell>
              <TableCell align="left">Autor</TableCell>
              <TableCell align="left">Czas</TableCell>
              <TableCell align="center">Akcje</TableCell>
            </TableRow>
          </TableHead>

          {data && data.length && !isLoading && isSuccess
            ? data.map((generatedReport, index) => (
                <TableBody>
                  <TableRow key={generatedReport.id}>
                    <TableCell align="left">
                      {generatedReport.administrativeUnitName || generatedReport?.group?.name}
                    </TableCell>
                    <TableCell align="center">{getIcon(generatedReport.status, generatedReport.error)}</TableCell>
                    <TableCell align="left">{dayjs(generatedReport.updatedAt).format('YYYY/MM/DD HH:mm')}</TableCell>
                    <TableCell align="left">{generatedReport.createdBy.username}</TableCell>

                    <TableCell align="left">
                      {new Date(Number(generatedReport.evaluationTime) * 1000).toISOString().substr(11, 8)}
                    </TableCell>
                    <TableCell align="center" size="small">
                      <Box
                        gridGap={10}
                        display="flex"
                        alignItems="center"
                        alignContent="center"
                        justifyContent="center"
                      >
                        <IconButton onClick={handleClick(index)}>
                          <Settings />
                        </IconButton>
                        <Menu
                          open={Boolean(anchorEl) && index === currentIndex}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                          {generatedReport.hasHtml && (
                            <MenuItem onClick={() => downloadHTML(generatedReport.id)}>
                              <ListItemIcon>
                                <CloudDownloadOutlined />
                              </ListItemIcon>
                              <ListItemText>Html</ListItemText>
                            </MenuItem>
                          )}
                          {generatedReport.hasPdf && (
                            <MenuItem onClick={() => downloadPDF(generatedReport.id)}>
                              <ListItemIcon>
                                <CloudDownloadOutlined />
                              </ListItemIcon>
                              <ListItemText>Pdf</ListItemText>
                            </MenuItem>
                          )}
                          {generatedReport.hasHtml && generatedReport.hasPdf && generatedReport.status === 'READY' && (
                            <MenuItem onClick={() => history.push(`/reports/generated_report/${generatedReport.id}`)}>
                              <ListItemIcon>
                                <Edit />
                              </ListItemIcon>
                              <ListItemText>Edytuj</ListItemText>
                            </MenuItem>
                          )}
                          {generatedReport && (
                            <MenuItem onClick={() => refreshReport(generatedReport.id, generatedReport.content)}>
                              <ListItemIcon>
                                <Cached />
                              </ListItemIcon>
                              <ListItemText>Odśwież</ListItemText>
                            </MenuItem>
                          )}
                          {generatedReport.tableName && (
                            <Tooltip placement="top" title={generatedReport.tableName}>
                              <MenuItem
                                onClick={() => {
                                  window.navigator.clipboard
                                    .writeText(generatedReport.tableName)
                                    .then(() => {
                                      enqueueSnackbar('Nazwa tabeli skopiowana do schowka.', {
                                        variant: 'success',
                                        autoHideDuration: 3000,
                                        disableWindowBlurListener: true,
                                      });
                                    })
                                    .catch(() => {
                                      enqueueSnackbar('Nie można było skopiować nazwy tabeli.', {
                                        variant: 'error',
                                        autoHideDuration: 3000,
                                        disableWindowBlurListener: true,
                                      });
                                    });
                                }}
                              >
                                <ListItemIcon>
                                  <Assignment />
                                </ListItemIcon>
                                <ListItemText>Nazwa tabeli</ListItemText>
                              </MenuItem>
                            </Tooltip>
                          )}
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))
            : null}
        </Table>
      </Box>
    </Box>
  );
};

export default GeneratedReports;
