import React, { useState } from 'react';
import { Box, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../../../../shared/components/Title';
import GenericButton from '../../../../../shared/components/GenericButton';
import CubeTree from '../../../../../DataBrowser/CubeTree';
import SourceCubeForm from './SourceCubeForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '60%',
    },
    addButton: {
      width: '100%',
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: 'white',
      '&:hover': {
        boxShadow: `0px 6px 10px ${theme.colors.darkGray}`,
        backgroundColor: theme.colors.blue,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    arrow: {
      fontSize: '16px',
      marginLeft: '8px',
    },
    column: {
      flex: 1,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      overflowY: 'auto',
      width: '50%',
    },
    columnContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(3),
    },
  }),
);

type Props = {
  open: boolean;
  onClose: () => void;
  allowEmptySelection?: boolean;
};

const CubeModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const [activeCubeId, setActiveCubeId] = useState<number | null>(null);

  return (
    <>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose} PaperProps={{ className: classes.root }}>
        <DialogTitle disableTypography>
          <Box display="flex" justifyContent="space-between">
            <Title variant="h1" modal title="Wybierz kostkę" />
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.columnContainer}>
            <div className={classes.column}>
              <CubeTree onCubeClick={(cube) => setActiveCubeId(cube.id)} />
            </div>
            <div className={classes.column}>
              {activeCubeId ? <SourceCubeForm cubeId={activeCubeId} onClose={onClose} /> : null}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <GenericButton variant="destructiveSecondary" onClick={onClose}>
            Anuluj
          </GenericButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CubeModal;
