import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './app/store';
import { getAuthToken, removeAuthToken } from './shared/utils/storage';
import { setToken } from './Auth/authSlice';

const token = getAuthToken();
if (token) {
  store.dispatch(setToken(token));
  axios.defaults.headers.common = {
    Authorization: `Token ${token}`,
    'Accept-Language': 'pl-PL',
  };
}
// axios will set xsrf headers for us in all future requests
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

axios.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    removeAuthToken();
    store.dispatch(setToken(null));
    axios.defaults.headers.common = {
      'Accept-Language': 'pl-PL',
    };
  }
  return Promise.reject(error);
});

const render = () => {
  const App = require('./app/App').default;

  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}
