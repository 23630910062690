import React from 'react';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  background?: string;
}

function Placeholder(props: Props) {
  const { background } = props;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      placeholder: {
        width: '100%',
        height: '80%',
        backgroundImage: background ? `url(${background})` : '',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },
    }),
  );

  const classes = useStyles();

  return <div className={classes.placeholder} />;
}

export default Placeholder;
