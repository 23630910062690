import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import { useField } from 'formik';
import { useTerritoryStore } from '../index';
import warehouseAPI from '../../../shared/api';
import UnitCheckbox from './UnitCheckbox';
import GenericButton from '../../../shared/components/GenericButton';

interface Params {
  // Select single administrative unit
  multi?: boolean;
}

export default function UnitPicker({ multi = true }: Params) {
  const [selectedAll, setSelectedAll] = useState(false);
  const activeTerritoryId = useTerritoryStore((state) => state.activeTerritoryId);
  const [field, , helpers] = useField(multi ? 'territoryUnits' : 'territoryUnit');

  const { data: childUnits } = useQuery(
    ['fetchListTerritories', activeTerritoryId],
    () => warehouseAPI.territoryList.territories(activeTerritoryId),
    {},
  );

  const units = childUnits;

  // Select All / Deselect All
  function select() {
    const ids: Array<number | undefined> = [];
    childUnits?.forEach((unit) => ids.push(unit.id));
    if (!selectedAll) {
      helpers.setValue(_.union(field.value, ids));
    } else {
      helpers.setValue(_.without(field.value, ...ids));
    }
    setSelectedAll(!selectedAll);
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      btns: {
        width: '40%',
        marginLeft: '5px',
        padding: '0',
        verticalAlign: 'bottom',
        marginTop: theme.spacing(1),
      },
      select: {
        width: '57%',
        height: '28px',
        marginTop: theme.spacing(1),
      },
    }),
  );
  const classes = useStyles();
  return (
    <>
      {multi && activeTerritoryId ? (
        <GenericButton variant="secondary" onClick={select} className={classes.btns}>
          {selectedAll ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'}
        </GenericButton>
      ) : null}
      <div style={{ overflow: 'auto', height: 'calc(50vh - 70px)' }}>
        <Grid container>
          {units &&
            units.map((unit) => (
              <Grid key={unit.id} item xs={12}>
                <UnitCheckbox territory={unit} multi={multi} />
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
}
