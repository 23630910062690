import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, ListItem, ListItemText, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      padding: 0,
      paddingLeft: (props: Props) => theme.spacing(2 * props.index),
    },
    category: {
      color: theme.colors.darkGray,
      fontSize: '13px',
      fontWeight: 400,
    },
    source: {
      color: theme.colors.black,
      fontSize: '13px',
      '& span': {
        fontWeight: 600,
      },
    },
  }),
);

interface Props {
  category: boolean;
  name: string;
  index: number;
}

function ParentTreeItem(props: Props) {
  const { category, name } = props;

  const classes = useStyles(props);

  return (
    <ListItem className={`${classes.nested} ${category ? classes.category : classes.source}`}>
      {category ? <ExpandMoreIcon /> : <ChevronRightIcon />}
      <ListItemText className={category ? classes.category : classes.source} primary={name} />
    </ListItem>
  );
}

export default ParentTreeItem;
