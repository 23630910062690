import React, { useState } from 'react';
import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import { useQuery } from 'react-query';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import _ from 'lodash';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AdministrativeUnit } from '../../../shared/types';
import { getUnitName } from '../../../shared/utils/helpers';
import warehouseAPI from '../../../shared/api';
import { useActiveUnitStore, useTerritoryStore } from '../index';

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(2),
      color: theme.colors.black,
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '13px',
      letterSpacing: '0px',
      color: (props: StyleProps) => (props.open ? theme.colors.black : theme.colors.darkGray),
      opacity: 1,
      position: 'relative',
      fontFamily: 'Poppins',
      '&:hover': {
        color: theme.colors.black,
      },
    },
    active: {
      color: theme.colors.blue,
    },
    addButton: {
      '&:hover': {
        color: theme.colors.blue,
      },
    },
  }),
);

interface Props {
  region: AdministrativeUnit;
  className?: string;
}

function RegionListItem(props: Props) {
  const { region, className } = props;

  const { data } = useQuery(['fetchChildUnits', region.id], warehouseAPI.administrativeUnit.children);

  const children = data ? data.filter((child) => child.hasChildren) : [];

  const [open, setOpen] = useState(false);

  const classes = useStyles({ open: !_.isEmpty(children) && open });

  const [activeUnitId, setActiveUnitId] = useActiveUnitStore((state) => [state.activeUnitId, state.setActiveUnitId]);

  const setActiveTerritoryId = useTerritoryStore((state) => state.setActiveTerritoryId);

  const handleClick = () => {
    setActiveTerritoryId(undefined);
    setActiveUnitId(region.id);
    setOpen(!open);
  };

  const renderExpandIcon = () => (open ? <ExpandMoreIcon /> : <ChevronRightIcon />);

  return (
    <>
      <ListItem className={classes.listItem} button onClick={handleClick}>
        {!_.isEmpty(children) && renderExpandIcon()}
        <ListItemText
          className={region.id === activeUnitId ? classes.active : ''}
          primary={getUnitName(region)}
          primaryTypographyProps={{ className }}
        />
      </ListItem>
      {!_.isEmpty(children) && (
        <Collapse className={classes.nested} in={open} timeout={100} unmountOnExit>
          {children.map((childUnit) => (
            <RegionListItem key={childUnit.id} region={childUnit} />
          ))}
        </Collapse>
      )}
    </>
  );
}

export default RegionListItem;
