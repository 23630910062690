import React, { useMemo, useState } from 'react';
import { Button, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import Groups from '../shared/components/icons/Groups';
import GroupTerritoryPickerModal from './GroupTerritoryPickerModal';
import warehouseAPI from '../shared/api';
import useGroupStore from './groupStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupButton: {
      fontWeight: 400,
      color: theme.colors.darkGray,
      textTransform: 'none',
    },
  }),
);

function GroupTerritoryPicker() {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const currentGroupId = useGroupStore((state) => state.currentGroupId);
  const isTerritory = useGroupStore((state) => state.isTerritory);

  const { data: group } = useQuery(
    ['fetchGroupDetails', currentGroupId],
    () => warehouseAPI.administrativeGroup.fetchDetails(currentGroupId),
    { enabled: !isTerritory },
  );
  const { data: territory } = useQuery(
    ['fetchTerritoryDetails', currentGroupId],
    () => warehouseAPI.territoryGroup.fetchDetails(currentGroupId),
    { enabled: isTerritory },
  );

  const labelText = useMemo(() => {
    if (!isTerritory && group) {
      return `Wybrana grupa: ${group.name}`;
    }
    if (isTerritory && territory) {
      return `Wybrany obszar: ${territory.name}`;
    }
    return 'Wybierz grupę/obszar';
  }, [isTerritory, group, territory]);

  return (
    <>
      <Button
        className={classes.groupButton}
        startIcon={<Groups color={theme.colors.blue} />}
        onClick={() => setOpen(!open)}
      >
        {labelText}
      </Button>
      <GroupTerritoryPickerModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}

export default GroupTerritoryPicker;
