import React from 'react';
import LeftSiderLayout from '../shared/components/LeftSiderLayout';
import CubeTree from './CubeTree';
import ContentPanel from './ContentPanel';

function DataBrowser() {
  return <LeftSiderLayout sider={<CubeTree />} content={<ContentPanel />} />;
}

export default DataBrowser;
