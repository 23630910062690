import React, { useMemo } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import { FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import { TerritoryGroup } from '../../../shared/types';
import UnitTooltip from '../ChosenUnits/UnitTooltip';

interface Props {
  territory: TerritoryGroup;
  multi?: boolean;
}

const TerritoryCheckbox: React.FC<Props> = ({ territory, multi = true }) => {
  const [field, , helpers] = useField(multi ? 'territoryUnits' : 'territoryUnit');
  const [, , administrativeUnitHelpers] = useField('administrativeUnit');
  const [, , territoryHelper] = useField('territory');

  const isChecked = useMemo(() => {
    if (multi) {
      return _.includes(field.value, territory.id);
    }
    return field.value === territory.id;
  }, [field.value, multi, territory.id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (multi) {
      if (event.target.checked) {
        helpers.setValue(_.union(field.value, [territory.id]));
      } else {
        helpers.setValue(_.without(field.value, territory.id));
      }
    } else {
      helpers.setValue(territory.id);
      administrativeUnitHelpers.setValue(territory.id);
      territoryHelper.setValue(true);
    }
  };

  return (
    <UnitTooltip
      child={
        <FormControlLabel
          control={<Checkbox checked={isChecked} color="primary" onChange={handleChange} />}
          label={territory.name}
        />
      }
    />
  );
};

export default TerritoryCheckbox;
