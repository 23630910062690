import React, { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Footer from './Footer';

const PanelContent = withStyles({
  root: {
    overflow: 'auto',
    flexGrow: 1,
    height: '100%',
    background: `white 0% 0% no-repeat padding-box`,
    borderRadius: '10px',
  },
})(Box);

const SiderContent = withStyles({
  root: {
    height: 'calc(100% - 50px)',
    overflow: 'auto',
  },
})(Box);

const PanelContainer = withStyles({
  root: {
    height: '100%',
  },
})(Grid);

const Panel = withStyles({
  root: {
    height: '100%',
  },
})(Grid);

type Props = {
  sider: ReactNode;
  content: ReactNode;
  reportEditMode?: boolean;
};

function LeftSiderLayout(props: Props) {
  const { content, sider, reportEditMode = false } = props;

  return (
    <PanelContainer container spacing={5}>
      {!reportEditMode && (
        <Panel item xs={3}>
          <PanelContent display="flex" flexDirection="column">
            <SiderContent>{sider}</SiderContent>
            <Footer />
          </PanelContent>
        </Panel>
      )}
      <Panel item xs={reportEditMode ? 12 : 9}>
        <PanelContent>{content}</PanelContent>
      </Panel>
    </PanelContainer>
  );
}

export default LeftSiderLayout;
