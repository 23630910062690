import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation, queryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import GenericButton from '../../../shared/components/GenericButton';
import Title from '../../../shared/components/Title';
import api from '../../../shared/api';
import GroupList from '../../../Groups/GroupPickerModal/GroupList';
import GroupDetails from '../../../Groups/GroupPickerModal/GroupDetails';

import { AdministrativeGroup, AdministrativeGroupList, TerritoryGroupList } from '../../../shared/types';

const {
  report: { generatev2 },
} = api;

interface Props {
  isOpen: boolean;
  reportId: number;
  onClose: () => void;
}

const useStyles = makeStyles({
  root: {
    height: '65%',
  },
  container: {
    height: '100%',
  },
  column: {
    height: '100%',
  },
  columnContent: {
    height: 'calc(50vh - 70px)',
    overflowY: 'auto',
  },
});

function GenerateGroupReportModal(props: Props) {
  const { isOpen, onClose, reportId } = props;

  const [currentGroup, setCurrentGroup] = useState(null as AdministrativeGroup | null);
  const [currentList, setCurrentList] = useState(null as AdministrativeGroupList | null as TerritoryGroupList);
  const [, setIsTerritory] = useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(generatev2, {
    onSuccess: () => {
      enqueueSnackbar('Raport dodany do kolejki.', {
        variant: 'success',
        autoHideDuration: 3000,
        disableWindowBlurListener: true,
      });
      queryCache.invalidateQueries(['fetchGeneratedReports']);
      onClose();
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });

  const onSubmit = () => {
    if (reportId && currentGroup) {
      mutate({ reportId: reportId, administrativeGroupId: currentGroup.id });
    }
  };

  return (
    <Dialog fullWidth maxWidth={'lg'} open={isOpen} onClose={onClose} PaperProps={{ className: classes.root }}>
      <DialogTitle style={{}}>Generuj dane dla grupy</DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={6} className={classes.column}>
            <Title variant="h2" modal title="Lista grup" />
            <Box className={classes.columnContent}>
              <GroupList
                currentGroup={currentGroup}
                setCurrentGroup={setCurrentGroup}
                currentList={currentList}
                setCurrentList={setCurrentList}
                setIsTerritory={setIsTerritory}
                type={null}
              />
            </Box>
          </Grid>
          {
            <Grid item xs={6} className={classes.column}>
              <Title variant="h2" modal title="Skład grupy" />
              <Box className={classes.columnContent}>
                <GroupDetails groupId={currentGroup?.id} />
              </Box>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <GenericButton disabled={!currentGroup} variant="primary" onClick={() => onSubmit()}>
          Generuj
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
}

export default GenerateGroupReportModal;
