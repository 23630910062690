import React from 'react';
import { useQuery } from 'react-query';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import warehouseAPI from '../../shared/api';
import ReportItem from './ReportItem';
import { Report } from '../../shared/types';
import { sortByName } from '../../shared/utils/helpers';

interface Props {
  reportCount: number;
  onReportClick?: (report: Report) => void;
}

function ReportList(props: Props) {
  const { reportCount, onReportClick } = props;

  const { data: reports = [] } = useQuery(['fetchFolderReports'], warehouseAPI.reportCategory.reports);

  return (
    <>
      {_.isEmpty(reports)
        ? _.range(reportCount).map((index) => <Skeleton key={index} animation="wave" />)
        : sortByName(reports).map((report) => (
            <ReportItem key={report.id} report={report} onReportClick={onReportClick} />
          ))}
    </>
  );
}

export default ReportList;
