import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import LeftSiderLayout from '../../shared/components/LeftSiderLayout';
import ContentPanel from './ContentPanel';
import useReportStore from '../reportStore';
import warehouseAPI from '../../shared/api';
import Sider from './Sider';

function ReportEditor() {
  const { id } = useParams();

  const [setActiveReport, editReport, editNewReport] = useReportStore((state) => [
    state.setActiveReport,
    state.editReport,
    state.editNewReport,
  ]);

  const { data: report } = useQuery(['fetchReportDetails', id], () => warehouseAPI.report.fetchDetails(id));

  useEffect(() => {
    if (report) {
      setActiveReport(report);
      editReport(report);
    }
    if (!id) {
      editNewReport();
    }
  }, [report, id, setActiveReport, editReport, editNewReport]);
  return <LeftSiderLayout reportEditMode sider={<Sider />} content={<ContentPanel />} />;
}

export default ReportEditor;
