import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { Report } from '../shared/types';

type ReportStore = {
  activeReport?: Report;
  setActiveReport: (report?: Report) => void;
  isChanged: boolean;
  editNewReport: () => void;
  editReport: (report: Report) => void;
};

const useReportStore = create<ReportStore>(
  devtools((set) => ({
    activeReport: undefined,
    setActiveReport: (report) => set(() => ({ activeReport: report })),
    isChanged: false,
    editNewReport: () =>
      set(() => ({
        isChanged: false,
      })),
    editReport: (report) => set(() => ({ activeReport: report, isChanged: false })),
  })),
);

export default useReportStore;
