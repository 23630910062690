import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Data from '../shared/components/icons/Data';
import Groups from '../shared/components/icons/Groups';
import Indicators from '../shared/components/icons/Indicators';
import Reports from '../shared/components/icons/Reports';

type Props = {
  label: string;
  path: string;
  icon: 'data' | 'groups' | 'indicators' | 'reports';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '33px',
      opacity: 1,
      textAlign: 'left',
      fontWeight: 600,
      fontSize: '13px/20px',
      letterSpacing: '0px',
      textTransform: 'uppercase',
      marginLeft: '8px',
      marginRight: '8px',
      borderRadius: '18px',
      padding: '15px',
    },
    navActive: {
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: theme.colors.white,
      '&.Mui-disabled': {
        color: theme.colors.white,
      },
      '&:hover': {
        background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
        opacity: '0.8',
        boxShadow: '0px 4px 8px #7C7E8333',
      },
    },
    nav: {
      color: theme.colors.blue,
      '&:hover': {
        boxShadow: '0px 4px 8px #7C7E8333',
      },
    },
    icon: {
      height: '100%',
      paddingBottom: '4px',
    },
    link: {
      textDecoration: 'none',
    },
  }),
);

function getButtonIcon(icon: string, active: boolean) {
  switch (icon) {
    case 'data':
      return <Data color={active ? '#ffffff' : '#3E75D5'} />;
    case 'groups':
      return <Groups color={active ? '#ffffff' : '#3E75D5'} />;
    case 'indicators':
      return <Indicators color={active ? '#ffffff' : '#3E75D5'} />;
    case 'reports':
      return <Reports color={active ? '#ffffff' : '#3E75D5'} />;
    default:
      return null;
  }
}

const HeadButton = withStyles({
  label: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
})(Button);

function HeaderButton(props: Props) {
  const { label, path, icon } = props;
  const classes = useStyles();

  const location = useLocation();

  const isActive = location.pathname.includes(path);

  return (
    <Link to={path} className={classes.link}>
      <HeadButton
        startIcon={<Icon className={classes.icon}>{getButtonIcon(icon, isActive)}</Icon>}
        className={`${classes.root} ${isActive ? classes.navActive : classes.nav}`}
      >
        {label}
      </HeadButton>
    </Link>
  );
}

export default HeaderButton;
