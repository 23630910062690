import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { AccountCircle, Lock, Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { auth, loginUser } from './authSlice';

function Auth() {
  const { didLoginFail, token } = useSelector(auth);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      history.push('/');
    }
  }, [history, token]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const useStyles = makeStyles({
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '20rem',
      margin: 'auto',
      height: '100%',
    },
    loginForm: {
      width: '100%',
      marginTop: 1,
    },
    loginSubmit: {
      margin: '3px 0px 2px',
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.loginContainer}>
      <Typography component="h1" variant="h5">
        Logowanie
      </Typography>
      <div className={classes.loginForm}>
        {didLoginFail && <Alert severity="error">Nieprawidłowe dane logowania.</Alert>}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(loginUser({ username, password }));
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Login"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Hasło"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.loginSubmit}
            type="submit"
            disabled={username === '' && password === ''}
          >
            Zaloguj
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Auth;
