import React from 'react';

type Props = {
  color: React.CSSProperties['color'];
};

function Groups(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-276 -75)">
        <rect style={{ opacity: 0 }} width="16" height="16" transform="translate(276 75)" />
        <g transform="translate(-462 -400)">
          <path
            style={{ fill: color }}
            d="M744,476h-5a1,1,0,0,0-1,1v4a1.011,1.011,0,0,0,1,1h5a1,1,0,0,0,.389-.078c.011-.005.021-.012.032-.018a.935.935,0,0,0,.138-.075A1,1,0,0,0,745,481v-4A1,1,0,0,0,744,476Zm-4,2h3v2h-3Z"
          />
          <path style={{ fill: color }} d="M757,478h5a1,1,0,0,0,0-2h-5a1,1,0,0,0,0,2Z" transform="translate(-9)" />
          <path style={{ fill: color }} d="M760,484h-3a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2Z" transform="translate(-9 -4)" />
          <path
            style={{ fill: color }}
            d="M744,492h-5a1,1,0,0,0-1,1v4a1.011,1.011,0,0,0,1,1h5a1,1,0,0,0,.389-.078c.011-.005.021-.012.032-.018a.935.935,0,0,0,.138-.075A1,1,0,0,0,745,497v-4A1,1,0,0,0,744,492Zm-4,2h3v2h-3Z"
            transform="translate(0 -8)"
          />
          <path style={{ fill: color }} d="M762,492h-5a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Z" transform="translate(-9 -8)" />
          <path style={{ fill: color }} d="M760,500h-3a1,1,0,0,0,0,2h3a1,1,0,0,0,0-2Z" transform="translate(-9 -12)" />
        </g>
      </g>
    </svg>
  );
}

export default Groups;
