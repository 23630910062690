import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import List from '@material-ui/core/List';
import IndicatorAdd from '../../shared/components/icons/IndicatorAdd';
import ReportList from '../ReportTree/ReportList';
import warehouseAPI from '../../shared/api';
import Title from '../../shared/components/Title';

const MainList = withStyles({
  root: {
    padding: '30px',
  },
  subheader: {
    backgroundColor: 'white',
  },
})(List);

const useStyles = makeStyles({
  linkContainer: {
    marginTop: '30px',
    '& a': {
      width: '80%',
    },
  },
  icon: {
    paddingBottom: '4px',
  },
  header: {
    padding: '20px 40px',
    borderBottom: `1px solid #F6F6F6`,
    boxSizing: 'border-box',
    height: '86px',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    fontSize: '32px',
  },
});

const BootstrapButton = withStyles({
  root: {
    width: '100%',
    minHeight: '40px',
    textAlign: 'left',
    borderRadius: '6px',
    background: '#3E75D5 0% 0% no-repeat padding-box',
    color: '#FFFFFF',
    '&:hover': {
      boxShadow: '0px 6px 10px #7C7E834D',
      backgroundColor: '#3E75D5',
    },
    '&:disabled': {
      backgroundColor: '#F6F6F6',
      color: '#7C7E83',
    },
  },
  label: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
})(Button);

function Sider() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, data: reports = [] } = useQuery('fetchFolderReports', warehouseAPI.reportCategory.reports, {
    onError: () => {
      enqueueSnackbar('Błąd pobierania listy raportów', { variant: 'error' });
    },
  });

  return (
    <Box display="flex" flexDirection="column">
      <div className={classes.header}>
        <Title variant="h2" title="Raporty" />
      </div>
      <Box className={classes.linkContainer} display="flex" justifyContent="center">
        <Link to="reports/add" className={classes.link}>
          <BootstrapButton
            startIcon={
              <Icon className={classes.icon}>
                <IndicatorAdd color="#ffffff" />
              </Icon>
            }
          >
            Dodaj nowy raport
          </BootstrapButton>
        </Link>
      </Box>
      <MainList aria-labelledby="nested-list-subheader" disablePadding>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress size={60} />
          </Box>
        ) : (
          <ReportList reportCount={reports.length || 0} />
        )}
      </MainList>
    </Box>
  );
}

export default Sider;
