import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Field, Form, Formik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Theme } from '@material-ui/core/styles';
import { Box, FormControlLabel, Grid, MenuItem } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { Indicator } from '../../../shared/types';
import GenericButton from '../../../shared/components/GenericButton';
import Confirm from '../../../shared/components/icons/Confirm';
import Cancel from '../../../shared/components/icons/Cancel';
import Title from '../../../shared/components/Title';
import IndicatorComponents from './IndicatorComponents';
import IndicatorFormula from './IndicatorFormula';
import IndicatorFolderPicker from './IndicatorFolderPicker';
import TextInput from '../../../shared/components/forms/TextInput';
import warehouseAPI from '../../../shared/api';
import useIndicatorStore from '../../indicatorStore';
import SelectInput from '../../../shared/components/forms/SelectInput';
import { indicatorCharacters, partnershipValueTypes } from '../../../shared/utils/helpers';
import { Switch } from 'formik-material-ui';
import { AxiosError } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: '10px',
    },
    label: {
      color: theme.colors.darkGray,
      fontSize: '15px',
      fontWeight: 400,
      marginBottom: '10px',
    },
    title: {
      marginBottom: '25px',
    },
  }),
);

function IndicatorForm() {
  const history = useHistory();

  const classes = useStyles();

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { data: indicator } = useQuery(['fetchIndicatorDetails', id], () => warehouseAPI.indicator.fetchDetails(id));

  const [isChanged, components] = useIndicatorStore((state) => [state.isChanged, state.editorComponents]);

  const [mutate, { error }] = useMutation(warehouseAPI.indicator.save);
  const err = error as AxiosError;
  const formulaError = err ? 'formula' in err?.response?.data : false;

  if (formulaError) {
    enqueueSnackbar(err?.response?.data['formula'], { variant: 'error' });
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('To pole nie może być puste.'),
    shortName: Yup.string().required('To pole nie może być puste.'),
    folders: Yup.array().of(Yup.number().required('To pole nie może być puste.')).min(1, 'To pole nie może być puste.'),
    description: Yup.string(),
    measure: Yup.string(),
    includedInSynthetic: Yup.boolean(),
    extrapolated: Yup.boolean(),
    copyMissingValues: Yup.boolean(),
    fillInPowiatCityData: Yup.boolean(),
    precision: Yup.number()
      .integer('Wartość musi być liczbą całkowitą')
      .min(0, 'Wartośc nie może być ujemna')
      .typeError('Wartość musi być liczbą całkowitą'),
  });

  return (
    <>
      <Formik
        initialValues={
          indicator
            ? {
                ...indicator,
              }
            : {
                name: '',
                shortName: '',
                description: '',
                folders: [],
                formula: '',
                includedInSynthetic: true,
                extrapolated: false,
                fillInPowiatCityData: false,
                copyMissingValues: false,
              }
        }
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const savedIndicator = await mutate({ ...values, components } as unknown as Indicator);
            if (savedIndicator) {
              history.push(`/indicators/${savedIndicator.id}`);
            }
          } catch (e) {
            enqueueSnackbar('Błąd zapisu wskaźnika', { variant: 'error' });
          }
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ values, dirty, isValid, submitForm }) => (
          <Form>
            <Title className={classes.title} variant="h2" title="Szczegóły wskaźnika" />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput fieldId="name" label="Nazwa wskaźnika" name="name" placeholder="Podaj nazwę" />
              </Grid>
              <Grid item xs={12}>
                <TextInput fieldId="name" label="Nazwa skrócona" name="shortName" placeholder="Podaj skróconą nazwę" />
              </Grid>
              <Grid item xs={12}>
                <Title variant="h3" title="Foldery" className={classes.label} />
                <IndicatorFolderPicker toShowDropdown={true} />
              </Grid>
              <Grid item xs={12}>
                <TextInput fieldId="description" label="Opis" name="description" rows={5} placeholder="Podaj opis" />
              </Grid>
              <Grid item xs={12}>
                <TextInput fieldId="measure" label="Miara wskaźnika" name="measure" placeholder="Podaj miarę" />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fieldId="precision"
                  label="Precyzja wskaźnika"
                  name="precision"
                  placeholder="Podaj precyzję"
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  fieldId="character"
                  label="Charakter"
                  name="character"
                  placeholder="Wybierz charakter wskaźnika"
                >
                  {indicatorCharacters.map((indicatorCharacter) => (
                    <MenuItem key={indicatorCharacter.label} value={indicatorCharacter.value}>
                      {indicatorCharacter.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  fieldId="partnershipValueType"
                  label="Typ wartości dla partnerstwa"
                  name="partnershipValueType"
                  placeholder="Wybierz typ wartości"
                >
                  {partnershipValueTypes.map((valueType) => (
                    <MenuItem key={valueType.label} value={valueType.value}>
                      {valueType.label}
                    </MenuItem>
                  ))}
                </SelectInput>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      color="primary"
                      type="checkbox"
                      name="includedInSynthetic"
                      checked={values.includedInSynthetic}
                    />
                  }
                  label="Włącz do wskaźnika syntetycznego"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      color="primary"
                      type="checkbox"
                      name="copyMissingValues"
                      checked={values.copyMissingValues}
                    />
                  }
                  label="Uzupełnij brakujące wartości dla kolejnych lat (portale)."
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      color="primary"
                      type="checkbox"
                      name="extrapolated"
                      checked={values.extrapolated}
                    />
                  }
                  label="Ekstrapoluj dla wyższych poziomów"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      color="primary"
                      type="checkbox"
                      name="fillInPowiatCityData"
                      checked={values.fillInPowiatCityData}
                    />
                  }
                  label="Uzupełnij brakujące dane dla MNPP"
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <GenericButton
                    className={classes.button}
                    disabled={!((dirty && isValid) || isChanged)}
                    variant="primary"
                    shape="circle"
                    onClick={submitForm}
                    icon={
                      <Icon>
                        <Confirm color="#ffffff" />
                      </Icon>
                    }
                  >
                    Zapisz wskaźnik
                  </GenericButton>
                  <Link to="/indicators">
                    <GenericButton
                      variant="destructiveSecondary"
                      disabled={false}
                      shape="circle"
                      icon={
                        <Icon>
                          <Cancel color="#D53E3E" />
                        </Icon>
                      }
                    >
                      Odrzuć zmiany
                    </GenericButton>
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <IndicatorFormula error={formulaError} />
              </Grid>
              <Grid item xs={12}>
                <IndicatorComponents />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default IndicatorForm;
