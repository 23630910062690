import React, { useState } from 'react';
import { ReportIndicator } from '../../../shared/types';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { useMutation, useQueryCache } from 'react-query';
import warehouseAPI from '../../../shared/api';
import { useSnackbar } from 'notistack';
import { EditOutlined } from '@material-ui/icons';
import ReportIndicatorForm from './ReportIndicatorForm/ReportIndicatorForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      color: theme.colors.blue,
    },
    deleteButton: {
      padding: 0,
    },
  }),
);

interface Props {
  indicator: ReportIndicator;
}

function IndicatorItem(props: Props) {
  const { indicator } = props;

  const classes = useStyles();

  const [showDelete, setShowDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(warehouseAPI.reportIndicator.delete);
  const queryCache = useQueryCache();

  return (
    <>
      <ListItem
        key={indicator.id}
        button
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
      >
        <ListItemText primary={`• ${indicator.name}`} />
        {showDelete && (
          <>
            <IconButton className={classes.deleteButton} onClick={() => setOpenEditModal(true)}>
              <EditOutlined />
            </IconButton>
            <IconButton className={classes.deleteButton} onClick={() => setOpenConfirmModal(true)}>
              <DeleteOutlinedIcon />
            </IconButton>
          </>
        )}
      </ListItem>
      <ConfirmationModal
        open={openConfirmModal}
        title="Usuń wskaźnik"
        message="Czy na pewno usunąć wskaźnik?"
        onConfirm={async () => {
          if (indicator) {
            try {
              await mutate(indicator.id);
              await queryCache.invalidateQueries(['fetchFolderIndicators']);
              await queryCache.invalidateQueries('fetchReportFolder');
              setOpenConfirmModal(false);
            } catch (e) {
              enqueueSnackbar('Błąd przy usuwaniu wskaźnika', { variant: 'error' });
            }
          }
        }}
        onCancel={() => setOpenConfirmModal(false)}
      />
      <ReportIndicatorForm
        open={openEditModal}
        indicator={indicator}
        folder={indicator.folder as number}
        onClose={() => setOpenEditModal(false)}
      />
    </>
  );
}

export default IndicatorItem;
