import React from 'react';
import { useQuery } from 'react-query';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import Title from '../../shared/components/Title';
import warehouseAPI from '../../shared/api';
import { Cube } from '../../shared/types';
import CubeSourceTree from './CubeSourceTree';
import { useSnackbar } from 'notistack';

const MainList = withStyles({
  root: {
    padding: '30px',
  },
  subheader: {
    backgroundColor: 'white',
  },
})(List);

interface Props {
  onCubeClick?: (cube: Cube) => void;
}

function CubeTree(props: Props) {
  const { onCubeClick } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, data: cubeTree } = useQuery('fetchCubeCategoryTree', warehouseAPI.cubeCategory.tree, {
    onError: () => {
      enqueueSnackbar('Błąd pobierania hierachii hurtowni danych', { variant: 'error' });
    },
  });

  return (
    <MainList
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader style={{ marginBottom: 10 }} id="nested-list-subheader">
          <Title variant="h2" title="Hurtownia danych" />
        </ListSubheader>
      }
      disablePadding
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={60} />
        </Box>
      ) : (
        cubeTree?.map((source) => <CubeSourceTree key={source.name} sourceTree={source} onCubeClick={onCubeClick} />)
      )}
    </MainList>
  );
}

export default CubeTree;
