import React from 'react';
import { useSelector } from 'react-redux';
import { header } from './dataBrowserSlice';
import Title from '../shared/components/Title';
import GroupTerritoryPicker from '../Groups/GroupTerritoryPicker';

function Header() {
  const { title } = useSelector(header);

  const renderContent = (titleText: string) => (
    <>
      <Title variant="h1" title={titleText} />
      <GroupTerritoryPicker />
    </>
  );

  return title ? renderContent(title) : <Title variant="h1" title="Wybierz źródło danych aby zobaczyć podgląd" />;
}

export default Header;
