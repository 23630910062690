import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import LeftSiderLayout from '../../shared/components/LeftSiderLayout';
import Header from './Header';
import IndicatorDetails from './IndicatorDetails';
import RightPanelLayout from '../../shared/components/RightPanelLayout';
import Background from '../../shared/assets/indicator_background.png';
import IndicatorComponents from './IndicatorComponents';
import warehouseAPI from '../../shared/api';
import useIndicatorStore from '../indicatorStore';
import Sider from './Sider';

function IndicatorBrowser() {
  const { id } = useParams();

  const setActiveIndicator = useIndicatorStore((state) => state.setActiveIndicator);

  const { data: indicator } = useQuery(['fetchIndicatorDetails', id], () => warehouseAPI.indicator.fetchDetails(id));

  useEffect(() => {
    if (indicator) {
      setActiveIndicator(indicator);
    }
  }, [indicator, setActiveIndicator]);

  return (
    <LeftSiderLayout
      sider={<Sider />}
      content={
        <RightPanelLayout
          header={<Header indicator={indicator} />}
          contentLeft={
            indicator && <IndicatorComponents components={indicator.components} formula={indicator.formula} />
          }
          contentRight={indicator && <IndicatorDetails indicator={indicator} />}
          leftPanelSize={8}
          rightPanelSize={4}
          placeholder={Background}
        />
      }
    />
  );
}

export default IndicatorBrowser;
