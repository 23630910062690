import React, { useState } from 'react';
import { Button, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Groups from '../../../shared/components/icons/Groups';
import IndicatorFolderModal from './IndicatorFolderModal';
import { Box, CircularProgress, IconButton, List } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import warehouseAPI from '../../../shared/api';
import { useQuery } from 'react-query';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

type FormFields = {
  folders: number[];
};
const useStyles = makeStyles({
  button: {
    textTransform: 'none',
  },
});

type Props = {
  toShowDropdown?: boolean;
};

export const IndicatorFolderPicker: React.FC<Props> = ({ toShowDropdown = true }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const {
    values: { folders: categoryId },
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<FormFields>();

  const { data: foldersDetails, isLoading } = useQuery(['fetchIndicatorCategory', categoryId], () => {
    if (categoryId) {
      return warehouseAPI.indicatorCategory.fetchListDetails(categoryId);
    }
  });

  const handleDelete = (folderId: number) => {
    if (categoryId.includes(folderId)) {
      const updated = categoryId.filter((value) => value !== folderId);
      setFieldTouched('folders', true);
      setFieldValue('folders', updated);
    }
  };

  return (
    <>
      <Button
        className={classes.button}
        startIcon={<Groups color={theme.colors.blue} />}
        onClick={() => setModalOpen(true)}
      >
        Dodaj folder
      </Button>
      {isLoading ? (
        <Box>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <List>
          {foldersDetails &&
            foldersDetails.map((folder) => (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <ListItem>
                  <ListItemText primary={folder.name} />
                </ListItem>
                <IconButton
                  onClick={() => {
                    if (folder.id) {
                      handleDelete(folder.id);
                    }
                  }}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            ))}
        </List>
      )}

      <IndicatorFolderModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={(value: number) => {
          if (!categoryId.includes(value)) {
            const updated = [...categoryId, value];
            setFieldTouched('folders', true);
            setFieldValue('folders', updated);
          }
        }}
        toShowDropdown={toShowDropdown}
      />
    </>
  );
};

export default IndicatorFolderPicker;
