import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _ from 'lodash';
import warehouseAPI from '../../../shared/api';
import useIndicatorStore from '../../indicatorStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: '25px',
    },
    name: {
      fontWeight: 400,
    },
    button: {
      width: '100%',
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: 'white',
      '&:hover': {
        boxShadow: `0px 6px 10px ${theme.colors.darkGray}`,
        backgroundColor: theme.colors.blue,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    arrow: {
      fontSize: '16px',
      marginLeft: '8px',
    },
  }),
);

interface Props {
  indicatorId: number;
}

function IndicatorComponentForm(props: Props) {
  const { indicatorId } = props;

  const classes = useStyles();

  const { data: indicator } = useQuery(['fetchIndicatorDetails', indicatorId], () =>
    warehouseAPI.indicator.fetchDetails(indicatorId),
  );

  const [addComponent, components] = useIndicatorStore((state) => [
    state.addIndicatorComponent,
    state.editorComponents,
  ]);

  const handleClick = () => {
    if (indicator) {
      addComponent({
        type: 'INDICATOR',
        sourceIndicator: indicator.id,
        aggregationLevel: indicator.aggregationLevel,
      });
    }
  };

  const { id } = useParams();

  const isDisabled =
    !indicator ||
    _.isEmpty(indicator.components) ||
    !_.chain(components)
      .find({
        type: 'INDICATOR',
        sourceIndicator: indicatorId,
      })
      .isUndefined()
      .value() ||
    (id && parseInt(id, 10) === indicatorId);

  return indicator ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">Nazwa</Typography>
        <Typography className={classes.name} variant="body1">
          {indicator.name}
        </Typography>
      </Grid>
      {indicator.description && (
        <Grid item xs={12}>
          <Typography variant="body2">Opis</Typography>
          <Typography className={classes.name} variant="body1">
            {indicator.description}
          </Typography>
        </Grid>
      )}
      {Boolean(indicator.aggregationLevel) && (
        <Grid item xs={12}>
          <Typography variant="body2">Poziom agregacji</Typography>
          <Typography className={classes.name} variant="body1">
            {`KTS-${indicator.aggregationLevel}`}
          </Typography>
        </Grid>
      )}
      {!_.isEmpty(indicator.yearsAvailability) && (
        <Grid item xs={12}>
          <Typography variant="body2">Dostępność w latach</Typography>
          <Typography className={classes.name} variant="body1">
            {indicator.yearsAvailability?.join(', ')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button className={classes.button} onClick={handleClick} disabled={isDisabled}>
          Dodaj do wskaźnika
          <ArrowForwardIcon className={classes.arrow} />
        </Button>
      </Grid>
    </Grid>
  ) : null;
}

export default IndicatorComponentForm;
