import React, { useState } from 'react';
import { Box, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import Title from '../../shared/components/Title';
import GenericButton from '../../shared/components/GenericButton';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import warehouseAPI from '../../shared/api';
import { Report } from '../../shared/types';
import useReportStore from '../reportStore';
import GenerateReportModalV2 from './GenerateReportModalV2/GenerateReportModalV2';
import GenerateGroupReportModal from './GenerateGroupReportModal/GenerateGroupReportModal';

interface Props {
  report?: Report;
}

const useStyles = makeStyles({
  button: {
    height: 'calc(28px + 2*6px)',
    textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    minHeight: '86px',
  },
  headerSection: {
    minHeight: '86px',
  },
});

function Header(props: Props) {
  const { report } = props;
  const [openDelete, setOpenDelete] = useState(false);
  const [openGenerateGroup, setOpenGenerateGroup] = useState(false);
  const [openGenerateV2, setOpenGenerateV2] = useState(false);
  const [mutate] = useMutation(warehouseAPI.report.delete);
  const { setActiveReport } = useReportStore((state) => ({
    setActiveReport: state.setActiveReport,
  }));
  const queryCache = useQueryCache();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return report ? (
    <Box display="flex" flexDirection="column">
      <Box display="flex" className={classes.headerSection} flexDirection="row">
        <Title variant="h1" title={report.name} />
        <Box display="flex" justifyContent="flex-end" gridColumnGap={10} marginLeft="auto">
          <Link className={classes.link} to={`/reports/${report.id}/edit`}>
            <GenericButton variant="primary" disabled={false} className={classes.button}>
              Edytuj
            </GenericButton>
          </Link>
          {report.yearsRange && report.yearsRange.length ? (
            <>
              <GenericButton className={classes.button} variant="primary" onClick={handleClick}>
                Generuj raport
              </GenericButton>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <MenuItem onClick={() => setOpenGenerateV2(true)}>dla JST/Obszaru</MenuItem>
                <MenuItem onClick={() => setOpenGenerateGroup(true)}>dla grupy</MenuItem>
              </Menu>
            </>
          ) : null}
          <GenericButton className={classes.button} variant="destructiveSecondary" onClick={() => setOpenDelete(true)}>
            Usuń
          </GenericButton>
          <ConfirmationModal
            open={openDelete}
            title="Usuń raport"
            message="Czy na pewno usunąć raport?"
            onConfirm={async () => {
              if (report) {
                try {
                  await mutate(report.id);
                  await queryCache.invalidateQueries('fetchFolderReports');
                  history.push('/reports');
                  setActiveReport(undefined);
                  setOpenDelete(false);
                } catch (e) {
                  enqueueSnackbar('Błąd przy usuwaniu raporta', { variant: 'error' });
                }
              }
            }}
            onCancel={() => setOpenDelete(false)}
          />
        </Box>
      </Box>
      {report && report.id !== undefined ? (
        <GenerateReportModalV2 reportId={report.id} isOpen={openGenerateV2} onClose={() => setOpenGenerateV2(false)} />
      ) : null}
      {report && report.id !== undefined ? (
        <GenerateGroupReportModal
          reportId={report.id}
          isOpen={openGenerateGroup}
          onClose={() => setOpenGenerateGroup(false)}
        />
      ) : null}
    </Box>
  ) : (
    <Title className={classes.title} variant="h2" title="Wybierz raport aby zobaczyć podgląd" />
  );
}

export default Header;
