import React, { useState } from 'react';
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Theme,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import warehouseAPI from '../../../../../shared/api';
import GenericButton from '../../../../../shared/components/GenericButton';
import Check from '../../../../../shared/components/icons/Check';
import Title from '../../../../../shared/components/Title';
import IndicatorCategoryListItem from '../../../../../IndicatorBrowser/IndicatorTree/IndicatorCategoryItem';
import { Indicator } from '../../../../../shared/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '60%',
    },
    addButton: {
      width: '100%',
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: 'white',
      '&:hover': {
        boxShadow: `0px 6px 10px ${theme.colors.darkGray}`,
        backgroundColor: theme.colors.blue,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    arrow: {
      fontSize: '16px',
      marginLeft: '8px',
    },
  }),
);

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (indicator: Indicator | null) => void;
  toShowDropdown?: boolean;
  allowEmptySelection?: boolean;
};

const ReportIndicatorModal: React.FC<Props> = ({ open, onClose, onSelect, allowEmptySelection = false }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [activeIndicator, setActiveIndicator] = useState<Indicator | null>(null);

  const { data: categoryTree = [] } = useQuery('fetchReportIndicatorTree', warehouseAPI.indicatorCategory.tree);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} PaperProps={{ className: classes.root }}>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Title variant="h1" modal title="Wybierz wskaźnik" />
        </Box>
      </DialogTitle>
      <DialogContent>
        <List disablePadding>
          {categoryTree?.map((category) => (
            <IndicatorCategoryListItem
              key={category.id}
              folder={category}
              onIndicatorClick={(value) => setActiveIndicator(value ?? null)}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <GenericButton
          variant="primary"
          disabled={!(allowEmptySelection || activeIndicator)}
          icon={<Check color={theme.colors.white} />}
          onClick={() => {
            if (allowEmptySelection || typeof activeIndicator === 'object') {
              onSelect(activeIndicator);
              setActiveIndicator(null);
              onClose();
            }
          }}
        >
          Wybierz
        </GenericButton>
        <GenericButton variant="destructiveSecondary" onClick={onClose}>
          Anuluj
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReportIndicatorModal;
