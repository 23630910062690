import React, { useMemo } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import { FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import { AdministrativeUnit } from '../../../shared/types';
import { getUnitName } from '../../../shared/utils/helpers';
import UnitTooltip from '../ChosenUnits/UnitTooltip';

interface Props {
  administrativeUnit: AdministrativeUnit;
  multi?: boolean;
}

const UnitCheckbox: React.FC<Props> = ({ administrativeUnit, multi = true }) => {
  const [field, , helpers] = useField(multi ? 'administrativeUnits' : 'administrativeUnit');
  const [, , territoryHelper] = useField('territory');

  const isChecked = useMemo(() => {
    if (multi) {
      return _.includes(field.value, administrativeUnit.id);
    }
    return field.value === administrativeUnit.id;
  }, [field.value, multi, administrativeUnit.id]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (multi) {
      if (event.target.checked) {
        helpers.setValue(_.union(field.value, [administrativeUnit.id]));
      } else {
        helpers.setValue(_.without(field.value, administrativeUnit.id));
      }
    } else {
      helpers.setValue(administrativeUnit.id);
      territoryHelper.setValue(false);
    }
  };

  return (
    <UnitTooltip
      unit={administrativeUnit}
      child={
        <FormControlLabel
          control={<Checkbox checked={isChecked} color="primary" onChange={handleChange} />}
          label={getUnitName(administrativeUnit)}
        />
      }
    />
  );
};

export default UnitCheckbox;
