import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
  FormControlLabel,
} from '@material-ui/core';
import { Switch } from 'formik-material-ui';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import TextInput from '../../../../shared/components/forms/TextInput';
import GenericButton from '../../../../shared/components/GenericButton';
import warehouseAPI from '../../../../shared/api';
import Title from '../../../../shared/components/Title';
import { ReportIndicator } from '../../../../shared/types';
import ReportIndicatorPicker from './ReportIndicatorPicker/ReportIndicatorPicker';
import CubePicker from './CubePicker/CubePicker';
import SelectInput from '../../../../shared/components/forms/SelectInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.colors.darkGray,
      fontSize: '15px',
      fontWeight: 400,
      marginBottom: '10px',
      marginTop: '10px',
    },
  }),
);

type Props = {
  open: boolean;
  onClose: () => void;
  folder?: number;
  indicator?: ReportIndicator;
};

function ReportIndicatorForm(props: Props) {
  const { open, onClose, folder, indicator } = props;

  const [mutate] = useMutation(warehouseAPI.reportIndicator.save);
  const queryCache = useQueryCache();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const schema = Yup.object().shape(
    {
      name: Yup.string().required('To pole nie może być puste.'),
      shortName: Yup.string().required('To pole nie może być puste.'),
      description: Yup.string(),
      folder: Yup.number(),
      precision: Yup.number().required('To pole nie może być puste.'),
      type: Yup.string(),
      sourceIndicator: Yup.number().when(['type', 'sourceCube'], {
        is: (type, sourceCube) => type === 'INDICATOR' && sourceCube !== undefined,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable(),
      }),
      sourceCube: Yup.number().when(['type', 'sourceIndicator'], {
        is: (type, sourceIndicator) => type === 'CUBE' && sourceIndicator !== undefined,
        then: Yup.number().required(),
        otherwise: Yup.number().nullable(),
      }),
      extrapolated: Yup.boolean(),
      hideForPartnership: Yup.boolean(),
      measure: Yup.number().nullable(),
    },
    [['sourceCube', 'sourceIndicator']],
  );

  const initialValues = () => {
    if (indicator) {
      return {
        ...indicator,
        sourceCube: indicator.sourceCube ?? undefined,
        sourceIndicator: indicator.sourceIndicator ?? undefined,
      };
    }

    return {
      name: '',
      shortName: '',
      description: '',
      type: '',
      precision: 2,
      hideForPartnership: false,
      folder: folder ?? undefined,
    };
  };
  const cubeSelect = {
    name: 'Kostka',
    value: 'CUBE',
  };
  const indicatorSelect = {
    name: 'Wskaźnik',
    value: 'INDICATOR',
  };
  const selectValues = [cubeSelect, indicatorSelect];

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          if (folder) {
            setSubmitting(true);
            await mutate({ ...values, folder: folder });
            await queryCache.invalidateQueries(['fetchFolderIndicators']);
            await queryCache.invalidateQueries('fetchReportFolder');
            enqueueSnackbar('Zapisano wskaźnik', { variant: 'success' });
            resetForm();
            onClose();
          } else {
            enqueueSnackbar('Błąd zapisywania wskaźnika', { variant: 'error' });
            onClose();
          }
        } catch (e) {
          console.log(e);
          enqueueSnackbar('Błąd zapisywania wskaźnika', { variant: 'error' });
          onClose();
        }
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ dirty, isValid, submitForm, values }) => (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
          <DialogTitle disableTypography>
            <Title variant="h1" modal title={indicator?.id ? 'Edytuj wskaźnik' : 'Dodaj wskaźnik'} />
          </DialogTitle>
          <DialogContent>
            <Form>
              <SelectInput fieldId="type" name="type" label="Źródło danych">
                {selectValues.map((object) => (
                  <MenuItem key={object.name} value={object.value}>
                    {object.name}
                  </MenuItem>
                ))}
              </SelectInput>
              <Grid item xs={12}>
                {values.type === 'INDICATOR' && (
                  <>
                    <Title variant="h3" title="Wskaźnik źródłowy" className={classes.label} />
                    <ReportIndicatorPicker toShowDropdown={false} />
                  </>
                )}
                {values.type === 'CUBE' && (
                  <>
                    <Title variant="h3" title="Dane źródłowe" className={classes.label} />
                    <CubePicker />
                  </>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput fieldId="folderName" label="Nazwa" name="name" placeholder="Podaj nazwę" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fieldId="folderShortName"
                    label="Nazwa skrócona"
                    name="shortName"
                    placeholder="Podaj nazwę skróconą"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fieldId="folderDescription"
                    label="Opis"
                    name="description"
                    rows={5}
                    placeholder="Podaj opis"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fieldId="precision"
                    label="Precyzja wskaźnika"
                    name="precision"
                    placeholder="Podaj precyzję"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        color="primary"
                        type="checkbox"
                        name="hideForPartnership"
                        checked={values.hideForPartnership}
                      />
                    }
                    label="Ukryj dla partnerstw"
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
              {indicator?.id ? 'Zapisz' : 'Dodaj'}
            </GenericButton>
            <GenericButton variant="destructiveSecondary" onClick={onClose}>
              Anuluj
            </GenericButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

export default ReportIndicatorForm;
