import React from 'react';

type Props = {
  color: string;
};

function Data(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-250 -75)">
        <rect width="16" height="16" transform="translate(250 75)" style={{ opacity: 0 }} />
        <g transform="translate(-326 -403)">
          <path
            style={{ fill: color }}
            d="M601,491a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,601,491Z"
            transform="translate(-12 -3)"
          />
          <path
            style={{ fill: color }}
            d="M593,492a1,1,0,0,0,1-1v-2a1,1,0,0,0-2,0v2A1,1,0,0,0,593,492Z"
            transform="translate(-8 -4)"
          />
          <path
            style={{ fill: color }}
            d="M585,490a1,1,0,0,0,1-1v-4a1,1,0,0,0-2,0v4A1,1,0,0,0,585,490Z"
            transform="translate(-4 -2)"
          />
          <path
            style={{ fill: color }}
            d="M591,490H578v-9a1,1,0,0,0-2,0v10h0a1,1,0,0,0,.154.527,1.06,1.06,0,0,0,.113.148c.01.01.017.022.026.031A1,1,0,0,0,577,492h14a1,1,0,0,0,0-2Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Data;
