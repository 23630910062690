import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { CircularProgress, Grid, MenuItem, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import * as XLSX from 'xlsx';
import { AxiosError } from 'axios';
import SelectInput from '../../../shared/components/forms/SelectInput';
import GenericButton from '../../../shared/components/GenericButton';
import DataTable from '../../../shared/components/DataTable';
import { Indicator, TableData } from '../../../shared/types';
import GroupTerritoryPicker from '../../../Groups/GroupTerritoryPicker';
import useGroupStore from '../../../Groups/groupStore';

import warehouseAPI from '../../../shared/api';

interface Props {
  indicator: Indicator;
}

function IndicatorAnalysis(props: Props) {
  const { indicator } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [table, setTable] = useState<TableData | null>(null);

  const [mutate] = useMutation(warehouseAPI.indicator.tableData, {
    onError: (e: AxiosError) => {
      if (e.request.status === 400) {
        enqueueSnackbar('Nie wszystkie miary komponentów wskaźnika mają przypisany sposób agregacji', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Błąd pobierania danych wskaźnika', { variant: 'error' });
      }
    },
  });

  const [currentGroupId, isTerritory] = useGroupStore((state) => [state.currentGroupId, state.isTerritory]);
  const schema = Yup.object().shape({
    years: Yup.array().of(Yup.number()).required(),
    group: Yup.number().positive().required('Wybierz grupę terytorialną'),
    territory: Yup.boolean(),
  });

  const saveExcel = (indicatorName: string) => {
    if (table !== null) {
      const data = [table?.header, ...table?.rows];
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Dane');
      XLSX.writeFile(workbook, `${indicatorName.replace(' ', '_')}.xlsx`);
    }
  };

  return (
    indicator && (
      <>
        <Formik
          initialValues={{
            years: indicator.yearsAvailability,
            territory: isTerritory,
            group: currentGroupId,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting }) => {
            if (indicator.id) {
              values.territory = isTerritory;
              values.group = currentGroupId;
              const result = await mutate({ id: indicator.id, postData: values });
              if (result) {
                setTable(result);
              }
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, submitForm, isValid }) => (
            <Form>
              <Grid container spacing={3} style={{ overflow: 'hidden' }}>
                <Grid item xs={6}>
                  <SelectInput multiple fieldId="years" label="Lata" name="years" placeholder="Wybierz lata">
                    {indicator.yearsAvailability?.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">Grupa/Obszar</Typography>
                  <GroupTerritoryPicker />
                </Grid>
                <Grid item xs={12}>
                  <GenericButton variant="primary" disabled={!isValid || isSubmitting} onClick={submitForm}>
                    {isSubmitting ? <CircularProgress size={24} /> : 'Analizuj'}
                  </GenericButton>
                </Grid>
                <Grid item xs={12}>
                  <GenericButton variant="primary" disabled={!table} onClick={() => saveExcel(indicator.name)}>
                    Pobierz xlsx
                  </GenericButton>
                </Grid>
                <Grid item xs={12} style={{ maxHeight: 'calc(80vh - 70px)', overflow: 'auto' }}>
                  {table && <DataTable data={table} precision={indicator.precision} />}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
    )
  );
}

export default IndicatorAnalysis;
