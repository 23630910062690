import React, { useState } from 'react';
import { Box, Collapse, IconButton, ListItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import { useMutation, useQueryCache, useQuery } from 'react-query';
import Skeleton from '@material-ui/lab/Skeleton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { AdministrativeGroup, AdministrativeGroupList } from '../../shared/types';
import warehouseAPI from '../../shared/api';
import GroupEditorModal from '../GroupEditorModal';
import ConfirmationModal from '../../shared/components/ConfirmationModal';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupList: {
      color: theme.colors.black,
      fontWeight: 600,
      fontSize: '20px',
      letterSpacing: '0px',
      width: 'auto',
    },
    listItem: {
      fontWeight: 'normal',
      fontSize: '15px',
      letterSpacing: '0px',
      color: theme.colors.darkGray,
      opacity: 1,
      position: 'relative',
      width: 'auto',
    },
    groupsCount: {
      background: `${theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      borderRadius: '10px',
      opacity: 1,
      width: '27px',
      textAlign: 'center',
      color: theme.colors.darkGray,
    },
    activeItem: {
      color: theme.colors.blue,
    },
    addButton: {
      '&:hover': {
        color: theme.colors.blue,
      },
    },
    removeButton: {
      '&:hover': {
        color: theme.colors.red,
      },
    },
  }),
);

interface Props {
  list: AdministrativeGroupList;
  currentGroup: AdministrativeGroup | null;
  setCurrentGroup: React.Dispatch<any>;
  currentList: AdministrativeGroupList | null;
  setCurrentList: React.Dispatch<any>;
  setIsTerritory: React.Dispatch<any>;
  initialOpen: boolean;
  type: string | null;
}

function GroupListItem(props: Props) {
  const {
    list: { name, groupsCount, id },
    currentGroup,
    setCurrentGroup,
    currentList,
    setCurrentList,
    initialOpen = false,
    setIsTerritory,
    type,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(initialOpen);
  const [openEditor, setEditorOpen] = useState(false);
  const [editorGroup, setEditorGroup] = useState<AdministrativeGroup | undefined>(undefined);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const hasGroups = Boolean(groupsCount && groupsCount > 0);

  const { data: groups } = useQuery(['fetchListGroups', id], () => warehouseAPI.groupList.groups(id), {
    enabled: open,
  });

  const [mutate] = useMutation(warehouseAPI.administrativeGroup.delete);
  const queryCache = useQueryCache();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => setOpen(!open);

  const handleGroupChoice = (group: AdministrativeGroup, list: AdministrativeGroupList) => {
    setCurrentGroup(group);
    setCurrentList(list);
    setIsTerritory(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <ListItem
          className={`${classes.groupList} ${type && currentList?.id === id && classes.activeItem}`}
          button
          onClick={type ? () => setCurrentList({ name, groupsCount, id } as AdministrativeGroupList) : handleClick}
        >
          {name}
          <Typography className={classes.groupsCount} variant="caption">
            {groupsCount}
          </Typography>
        </ListItem>
        <IconButton
          onClick={() => {
            setEditorOpen(true);
          }}
          size="small"
        >
          {!type && <AddCircleOutlineIcon className={classes.addButton} />}
        </IconButton>
      </Box>
      {hasGroups && !type && (
        <Collapse in={open} timeout={100} unmountOnExit>
          {_.isEmpty(groups)
            ? _.range(groupsCount as number).map((index) => <Skeleton key={index} animation="wave" />)
            : (groups as AdministrativeGroup[]).map((group) => (
                <Box key={group.id} display="flex" justifyContent="flex-start" alignItems="center">
                  <ListItem
                    className={`${classes.listItem} ${currentGroup?.id === group.id && classes.activeItem}`}
                    button
                    onClick={() => handleGroupChoice(group, { name, groupsCount, id } as AdministrativeGroupList)}
                  >
                    {group.name}
                  </ListItem>
                  {currentGroup?.id === group.id && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEditorGroup(group);
                        setEditorOpen(true);
                      }}
                    >
                      <EditOutlinedIcon color="primary" fontSize="small" />
                    </IconButton>
                  )}
                  {currentGroup?.id === group.id && (
                    <IconButton
                      onClick={() => {
                        setOpenConfirmModal(true);
                      }}
                      size="small"
                    >
                      <DeleteOutlinedIcon className={classes.addButton} />
                    </IconButton>
                  )}
                </Box>
              ))}
        </Collapse>
      )}
      {id && (
        <GroupEditorModal open={openEditor} onClose={() => setEditorOpen(false)} listId={id} group={editorGroup} />
      )}
      {currentGroup?.id && (
        <ConfirmationModal
          open={openConfirmModal}
          title="Usuń grupę"
          message="Czy na pewno usunąć grupę?"
          onConfirm={async () => {
            if (currentGroup?.id) {
              try {
                await mutate(currentGroup?.id);
                await queryCache.invalidateQueries('fetchListGroups');
                setOpenConfirmModal(false);
              } catch (e) {
                enqueueSnackbar('Błąd przy usuwaniu obszaru', { variant: 'error' });
              }
            }
          }}
          onCancel={() => setOpenConfirmModal(false)}
        />
      )}
    </>
  );
}

export default GroupListItem;
