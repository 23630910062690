import create from 'zustand';

type GroupStore = {
  currentGroupId: number;
  setCurrentGroup: (id: number) => void;
  currentComparisonGroupListId: number;
  setCurrentComparisonGroupList: (id: number) => void;
  currentAggregateGroupListId: number;
  setCurrentAggregateGroupList: (id: number) => void;
  isTerritory: boolean;
  setIsTerritory: (bool: boolean) => void;
};

const useGroupStore = create<GroupStore>((set) => ({
  currentGroupId: 0,
  setCurrentGroup: (id) => set(() => ({ currentGroupId: id })),
  currentComparisonGroupListId: 0,
  setCurrentComparisonGroupList: (id) => set(() => ({ currentComparisonGroupListId: id })),
  currentAggregateGroupListId: 0,
  setCurrentAggregateGroupList: (id) => set(() => ({ currentAggregateGroupListId: id })),
  isTerritory: false,
  setIsTerritory: (bool) => set(() => ({ isTerritory: bool })),
}));

export default useGroupStore;
