export default [
  {
    name: 'add',
    label: '+',
    token: '+',
  },
  {
    name: 'subtract',
    label: '-',
    token: '-',
  },
  {
    name: 'multiply',
    label: '*',
    token: '*',
  },
  {
    name: 'divide',
    label: '/',
    token: '/',
  },
  {
    name: 'bracketLeft',
    label: '(',
    token: '(',
  },
  {
    name: 'bracketRight',
    label: ')',
    token: ')',
  },
  {
    name: 'union',
    label: 'Scalenie',
    token: 'UNION',
  },
];
