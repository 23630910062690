import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';

type Props = {
  variant: 'primary' | 'secondary' | 'tertiary' | 'destructivePrimary' | 'destructiveSecondary';
  icon?: React.ReactNode;
  shape?: 'square' | 'circle';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  href?: string;
  form?: string;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100px',
      minHeight: '40px',
      textAlign: (props: Props) => (props.icon ? 'left' : 'center'),
      textDecoration: 'none',
    },
    square: {
      borderRadius: '6px',
    },
    circle: {
      borderRadius: '20px',
    },
    primary: {
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: 'white',
      '&:hover': {
        boxShadow: '0px 6px 10px #7C7E834D',
        backgroundColor: theme.colors.blue,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    secondary: {
      background: `${theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      color: theme.colors.blue,
      '&:hover': {
        boxShadow: '0px 6px 10px #7C7E834D',
        backgroundColor: theme.colors.offWhite,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    tertiary: {
      background: `${theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      color: theme.colors.darkGray,
      '&:hover': {
        boxShadow: '0px 6px 10px #7C7E834D',
        backgroundColor: theme.colors.offWhite,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    destructivePrimary: {
      background: '#D53E3E 0% 0% no-repeat padding-box',
      color: theme.colors.white,
      '&:hover': {
        boxShadow: '0px 6px 10px #D53E3E66',
        backgroundColor: theme.colors.red,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    destructiveSecondary: {
      background: `${theme.colors.offWhite} 0% 0% no-repeat padding-box`,
      color: theme.colors.red,
      '&:hover': {
        boxShadow: '0px 6px 10px #7C7E834D',
        backgroundColor: theme.colors.offWhite,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
  }),
);

const BootstrapButton = withStyles({
  label: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
})(Button);

function GenericButton(props: Props) {
  const { variant, icon, shape, onClick, onKeyDown, disabled = false, children, form, href, className } = props;
  const classes = useStyles(props);

  return (
    <BootstrapButton
      className={`${classes.root} ${classes[variant]} ${shape ? classes[shape] : classes.square} ${className}`}
      startIcon={icon}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      href={href}
      form={form}
    >
      {children}
    </BootstrapButton>
  );
}

export default GenericButton;
