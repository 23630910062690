import React, { useState } from 'react';
import { Button, useTheme, Typography, Tooltip } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import Groups from '../../../../../shared/components/icons/Groups';
import warehouseAPI from '../../../../../shared/api';
import ReportIndicatorModal from './ReportIndicatorModal';
import { Indicator, DimensionValue } from '../../../../../shared/types';

type FormFields = {
  id?: number;
  sourceIndicator?: number;
  sourceCube?: number | null;
  name: string;
  shortName?: string;
  description?: string;
  measure?: number | null;
  extrapolated: boolean;
  dimensionValues?: DimensionValue[] | null;
  precision?: number;
  type: string;
};

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
  },
  root: {
    height: '60%',
  },

  text: {
    marginBottom: '15px',
  },
});

type Props = {
  toShowDropdown?: boolean;
};

const ReportIndicatorPicker: React.FC<Props> = ({ toShowDropdown = true }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const { values, setValues, setTouched, setFieldTouched } = useFormikContext<FormFields>();

  const { data: indicator } = useQuery(['fetchIndicator', values.sourceIndicator], () =>
    warehouseAPI.indicator.fetchDetails(values.sourceIndicator),
  );

  const { data: folders } = useQuery(['fetchIndicatorCategory', indicator?.folders], () => {
    if (indicator?.folders) {
      return warehouseAPI.indicatorCategory.fetchListDetails(indicator?.folders);
    }
    return [];
  });

  return (
    <>
      <Tooltip
        title={
          folders ? (
            <>
              {indicator && (
                <>
                  <Typography variant="body1">Ścieżka źródłowa wskaźnika:</Typography>
                  {folders.map((folder) => (
                    <Typography key={folder.id} variant="body1" className={classes.text}>
                      {folder.parentPath
                        ? folder.parentPath.map((parent) => parent.name).join('/') + '/' + folder.name
                        : folder.name}
                    </Typography>
                  ))}
                </>
              )}
            </>
          ) : (
            ''
          )
        }
        arrow
      >
        <Button
          className={classes.button}
          startIcon={<Groups color={theme.colors.blue} />}
          onClick={() => setModalOpen(true)}
        >
          {indicator ? indicator.name : 'Wybierz wskaźnik'}
        </Button>
      </Tooltip>
      <ReportIndicatorModal
        toShowDropdown={toShowDropdown}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={(value: Indicator) => {
          setValues({
            id: values.id,
            sourceIndicator: value.id,
            name: value.name,
            shortName: value.shortName,
            description: value.description,
            measure: null,
            sourceCube: null,
            extrapolated: false,
            dimensionValues: null,
            precision: value.precision,
            type: 'INDICATOR',
          });

          setTouched({ sourceIndicator: true });
          setFieldTouched('sourceIndicator', true);
        }}
      />
    </>
  );
};

export default ReportIndicatorPicker;
