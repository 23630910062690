import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import create from 'zustand';
import Title from '../../shared/components/Title';
import GenericButton from '../../shared/components/GenericButton';
import UnitPicker from '../GroupEditorModal/UnitPicker';
import RegionPicker from '../GroupEditorModal/RegionPicker';
import ChosenUnits from '../GroupEditorModal/ChosenUnits';
import UnitSearchBar from '../GroupEditorModal/RegionPicker/UnitSearchBar';
import warehouseAPI from '../../shared/api';
import { TerritoryGroup, AdministrativeUnit } from '../../shared/types';
import { AxiosError } from 'axios';

type UnitStore = {
  activeUnitId?: number;
  setActiveUnitId: (id: number) => void;
};

export const useActiveUnitStore = create<UnitStore>((set) => ({
  activeUnitId: undefined,
  setActiveUnitId: (unitId) => set({ activeUnitId: unitId }),
}));

type SearchStore = {
  searchResults: AdministrativeUnit[];
  updateSearchResults: (results: AdministrativeUnit[]) => void;
  clearSearchResults: () => void;
};

export const useUnitSearchStore = create<SearchStore>((set) => ({
  searchResults: [],
  updateSearchResults: (results) => set({ searchResults: results }),
  clearSearchResults: () => set({ searchResults: [] }),
}));

const useStyles = makeStyles({
  root: {
    height: '70%',
  },
  title: {
    marginRight: '20px',
  },
  container: {
    height: '100%',
  },
  column: {
    height: '100%',
  },
  columnContent: {
    height: 'calc(50vh - 70px)',
  },
});

const validationSchema = Yup.object({
  name: Yup.string().required('To pole nie może być puste.'),
  description: Yup.string(),
  administrativeUnits: Yup.array().of(Yup.number()).required('Wybierz jednostki administracyjne'),
});

type Props = {
  open: boolean;
  onClose: () => void;
  listId: number;
  territory?: TerritoryGroup;
};

function TerritoryEditorModal(props: Props) {
  const { open, onClose, listId, territory } = props;

  const [mutate] = useMutation(warehouseAPI.territoryGroup.save);
  const queryCache = useQueryCache();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const initialValues = territory
    ? {
        ...territory,
        list: listId,
      }
    : {
        name: '',
        description: '',
        administrativeUnits: [],
        list: listId,
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await mutate(values, {
            onSuccess: () => {
              queryCache.invalidateQueries(['fetchListTerritories', listId]);
              resetForm();
              onClose();
            },
            onError: (e: AxiosError) => {
              if (e.request.status === 400) {
                enqueueSnackbar('Nie można zapisać obszaru z jednostkami na różnych poziomach JST', {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar('Błąd zapisywania obszaru terytorialnego', { variant: 'error' });
              }
            },
          });
          await queryCache.invalidateQueries(['fetchTerritoryGroupDetails', listId]);
          await queryCache.invalidateQueries('fetchTerritoryLists');
          if (territory) {
            await queryCache.invalidateQueries(['fetchTerritoryUnits', territory.id]);
          }
        } catch (e) {
          enqueueSnackbar('Błąd zapisywania obszaru terytorialnego', { variant: 'error' });
        }
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, submitForm }) => (
        <Form>
          <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} PaperProps={{ className: classes.root }}>
            <DialogTitle disableTypography>
              <Box display="flex">
                <Title className={classes.title} variant="h2" modal title="Nazwa obszaru" />
                <Field
                  component={TextField}
                  InputProps={{ disableUnderline: true }}
                  name="name"
                  placeholder="Podaj nazwę obszaru"
                  style={{ marginLeft: '20%', width: '50%' }}
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} className={classes.container} style={{ overflow: 'hidden' }}>
                <Grid item xs={4} className={classes.column}>
                  <Box className={classes.columnContent}>
                    <Title variant="h2" modal title="Teryt" />
                    <Box style={{ marginTop: '7px' }}>
                      <UnitSearchBar />
                    </Box>
                    <Box style={{ marginTop: '8px', overflow: 'auto', height: 'calc(50vh - 70px)' }}>
                      <RegionPicker />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4} className={classes.column}>
                  <Box className={classes.columnContent}>
                    <Title variant="h2" modal title="Wybierz jednostki" />
                    <Box className={classes.columnContent}>
                      <UnitPicker />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4} className={classes.column}>
                  <Box className={classes.columnContent}>
                    <Title variant="h2" modal title="Skład obszaru" />
                    <Box
                      className={classes.columnContent}
                      style={{ marginTop: '50px', overflow: 'auto', height: 'calc(50vh - 70px)' }}
                    >
                      <ChosenUnits />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
                Zapisz obszar
              </GenericButton>
              <GenericButton variant="destructiveSecondary" onClick={onClose}>
                Anuluj
              </GenericButton>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}

export default TerritoryEditorModal;
