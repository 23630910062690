import React, { createContext, useState } from 'react';
import { Popover } from '@material-ui/core';
import GenericButton from '../../../../../shared/components/GenericButton';

interface Props {
  functionName: string;
  className: string;
  popoverContent: React.ReactNode;
}

interface Context {
  onClose?: () => void;
}

export const PopoverContext = createContext<Context>({ onClose: undefined });

function FunctionPicker(props: Props) {
  const { className, functionName, popoverContent } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closePopover = () => setAnchorEl(null);
  const isPopoverOpen = Boolean(anchorEl);

  return (
    <>
      <GenericButton className={className} variant="tertiary" onClick={openPopover}>
        {functionName}
      </GenericButton>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverContext.Provider value={{ onClose: closePopover }}>{popoverContent}</PopoverContext.Provider>
      </Popover>
    </>
  );
}

export default FunctionPicker;
