import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Button, Container, Icon, Toolbar } from '@material-ui/core';
import { ThemeProvider, makeStyles, withStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { logout, token } from '../Auth/authSlice';
import Routes from './Routes';
import HeaderButton from './HeaderButton';
import Logout from '../shared/components/icons/Logout';
import theme from './theme';
import Logo from '../shared/assets/mr_logo.svg';
import GroupTerritoryPicker from '../Groups/GroupTerritoryPicker';

const useStyles = makeStyles({
  headerNavRight: {
    display: 'flex',
  },
  headerNavLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: '42px',
    letterSpacing: '-0.2px',
    fontWeight: 700,
  },
  content: {
    height: 'calc(100vh - 64px)',
    overflow: 'hidden',
  },
  '&.MuiButtonBase-root': {
    margin: '0 10px',
  },
  logo: {
    marginRight: '40px',
  },
  groupButton: {
    color: '#3E75D5',
  },
});

const Header = withStyles({
  root: {
    backgroundColor: '#f1f3f8',
    border: 'none',
  },
})(AppBar);

const HeaderToolbar = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
  },
})(Toolbar);

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FunctionComponent = () => {
  const userToken = useSelector(token);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={3}
          autoHideDuration={3000}
        >
          {pathname !== '/login' && userToken && (
            <Header variant="outlined" position="static">
              <HeaderToolbar>
                <div className={classes.headerNavLeft}>
                  <img className={classes.logo} src={Logo} alt="Main logo" />
                  <HeaderButton label="DANE" path="/data" icon="data" />
                  <HeaderButton label="WSKAŹNIKI" path="/indicators" icon="indicators" />
                  <HeaderButton label="RAPORTY" path="/reports" icon="reports" />
                </div>
                <div className={classes.headerNavRight}>
                  <GroupTerritoryPicker />
                  <Button
                    onClick={() => dispatch(logout())}
                    size="medium"
                    startIcon={
                      <Icon>
                        <Logout color="#3E75D5" />
                      </Icon>
                    }
                  >
                    WYLOGUJ
                  </Button>
                </div>
              </HeaderToolbar>
            </Header>
          )}
          <Container maxWidth={false} className={classes.content}>
            <Routes />
          </Container>
        </SnackbarProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryCacheProvider>
  );
};

export default App;
