import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';
import GenericButton from '../../../shared/components/GenericButton';
import warehouseAPI from '../../../shared/api';
import IndicatorFolderModal from '../IndicatorForm/IndicatorFolderModal/index';

type Request = {
  indicatorId: number;
  folderId: number;
};

type Params = {
  id: string;
};

const useStyles = makeStyles({
  button: {
    marginLeft: '10px',
  },
});

const IndicatorToFolderLinkButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mutate] = useMutation((data: Request) => warehouseAPI.indicator.linkToFolder(data.indicatorId, data.folderId));

  const classes = useStyles();
  const queryCache = useQueryCache();
  const { id } = useParams<Params>();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <GenericButton className={classes.button} variant="primary" onClick={() => setModalOpen(true)}>
        Podlinkuj
      </GenericButton>
      <IndicatorFolderModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={async (folderId: number) => {
          try {
            await mutate({ indicatorId: Number(id), folderId });
            await queryCache.invalidateQueries([
              'fetchIndicatorTree',
              'fetchIndicatorDetails',
              'fetchIndicatorCategory',
            ]);
            enqueueSnackbar('Podlinkowano wskaźnik do wybranego folderu.', { variant: 'success' });
          } catch (e) {
            enqueueSnackbar('Wystąpił błąd podczas linkowania wskaźnika do wybranego folderu.', { variant: 'error' });
          }
        }}
      />
    </>
  );
};

export default IndicatorToFolderLinkButton;
