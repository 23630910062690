import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { User } from '../shared/types';
import { removeAuthToken, setAuthToken } from '../shared/utils/storage';
import { API_URL } from '../shared/utils/config';
import { RootState } from '../app/rootReducer';

interface SliceState {
  token: string | null;
  didLoginFail: boolean;
  user: User;
}

const DEFAULT_STATE: SliceState = {
  token: null,
  didLoginFail: false,
  user: {} as User,
};

export type LoginPayload = { username: string; password: string };
export type LoginSuccessPayload = { token: string };

export const loginUser = createAsyncThunk('loginUser', async (payload: LoginPayload) => {
  const response = await axios.post(`${API_URL}/login/`, payload);
  const { token } = response.data;
  setAuthToken(token);
  axios.defaults.headers.common = {
    Authorization: `Token ${token}`,
    'Accept-Language': 'pl-PL',
  };
  return response.data as LoginSuccessPayload;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: DEFAULT_STATE,
  reducers: {
    logout: () => {
      axios.defaults.headers.common = {};
      removeAuthToken();
      return DEFAULT_STATE;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.didLoginFail = false;
      state.token = payload.token;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.didLoginFail = true;
      state.token = null;
    });
  },
});

export const { logout, setToken } = authSlice.actions;

export const authBranch = (state: RootState) => state.auth;
export const token = createSelector(authBranch, (branch) => branch.token);
export const didLoginFail = createSelector(authBranch, (branch) => branch.didLoginFail);
export const user = createSelector(authBranch, (branch) => branch.user || ({} as User));
export const userName = createSelector(user, (user) => user.username || '');

export const auth = createSelector(token, didLoginFail, userName, (token, didLoginFail, userName) => ({
  token,
  didLoginFail,
  userName,
}));

export default authSlice.reducer;
