import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useQuery } from 'react-query';
import warehouseAPI from '../../shared/api';
import useIndicatorStore from '../indicatorStore';
import ParentTreeItem from './ParentTreeItem';
import { IndicatorBasedComponent } from '../../shared/types';
import { useStyles } from './index';

interface Props {
  component: IndicatorBasedComponent;
  showControls?: boolean;
  index: number;
}

function IndicatorCard(props: Props) {
  const {
    component: { sourceIndicator, label },
    showControls,
    index,
  } = props;

  const { data: indicator } = useQuery(['fetchIndicatorDetails', sourceIndicator], () =>
    warehouseAPI.indicator.fetchDetails(sourceIndicator),
  );

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const removeComponent = useIndicatorStore((state) => state.removeIndicatorComponent);

  const handleRemove = (event: any) => {
    event.stopPropagation();
    removeComponent(index);
    setAnchorEl(null);
  };

  return indicator ? (
    <Accordion className={classes.root} defaultExpanded variant="outlined">
      <AccordionSummary
        classes={{ root: classes.summaryRoot, expanded: classes.summaryExpanded }}
        aria-label="Expand"
        expandIcon={<ExpandMoreIcon />}
      >
        <Box display="flex" alignContent="flex-start" alignItems="center">
          {showControls && (
            <IconButton
              className={classes.button}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            >
              <Icon>
                <MoreVertIcon />
              </Icon>
            </IconButton>
          )}
          <Typography variant="h2">{label}</Typography>
          <Typography variant="h2" className={classes.name}>
            {indicator.name}
          </Typography>
        </Box>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleRemove}>Usuń</MenuItem>
        </Menu>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <List className={classes.list}>
              {indicator.parents?.map((parent, parentIndex) => (
                <ParentTreeItem key={parent.id} category name={parent.name} index={parentIndex + 1} />
              ))}
              <ParentTreeItem category={false} name={indicator.name} index={indicator.parents?.length || 0} />
            </List>
          </Grid>
          {indicator.aggregationLevel && (
            <Grid item xs={12}>
              <Typography className={classes.label}>Poziom agregacji</Typography>
              <Typography className={classes.value}>{`KTS-${indicator.aggregationLevel}`}</Typography>
            </Grid>
          )}
          {indicator.yearsAvailability && (
            <Grid item xs={12}>
              <Typography className={classes.label}>Dostępność w latach</Typography>
              <Typography className={classes.value}>{indicator.yearsAvailability.join(', ')}</Typography>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  ) : null;
}

export default IndicatorCard;
