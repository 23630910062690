import { AdministrativeUnit } from '../types';

export const typeMap = {
  COUNTRY: 'kraj',
  MACROREGION: 'makroregion',
  VOIEVODSHIP: 'województwo',
  REGION: 'region',
  SUBREGION: 'podregion',
  POWIAT: 'powiat',
  POWIAT_CITY: 'miasto na prawach powiatu',
  URBAN_GMINA: 'gmina miejska',
  RURAL_GMINA: 'gmina wiejska',
  MIXED_GMINA: 'gmina miejsko-wiejska',
  CITY: 'miasto',
  URBAN_AREA: 'obszar miejski',
  RURAL_AREA: 'obszar wiejski',
  DELEGATION: 'delegatura',
  DISTRICT: 'dzielnica',
};

export const getUnitName = (unit: AdministrativeUnit) =>
  `${unit.name}${unit.TERYTCode ? ` (${unit.TERYTCode})` : ''} - ${typeMap[unit.type]}`;

export const getUnitHistoricalTerytCodesDescription = (unit: AdministrativeUnit) => {
  if (unit.historicalTerytCodes.length === 0) {
    return '';
  }

  const description = unit.historicalTerytCodes.length === 1 ? 'Historyczny kod' : 'Historyczne kody';
  const historicalTerytCodes = unit.historicalTerytCodes.map((historicalUnit) => {
    let content = historicalUnit.TERYTCode;

    if (historicalUnit.effectiveUntil) {
      content += ` (do ${historicalUnit.effectiveUntil})`;
    }

    return content;
  });
  return `${description} TERYT: ${historicalTerytCodes.join(', ')}`;
};

export const indicatorCharacters = [
  { value: 'STIMULANT', label: 'Stymulanta' },
  { value: 'DESTIMULANT', label: 'Destymulanta' },
  { value: 'NOMINAL', label: 'Nominanta' },
  { value: 'NEUTRAL', label: 'Neutralna' },
];

export const partnershipValueTypes = [
  { value: 'SUM', label: 'Suma' },
  { value: 'MEAN', label: 'Średnia' },
];

interface NameSortItem {
  name: string;
}

export const sortByName = <T extends NameSortItem>(items: T[]) => {
  items.sort((a, b) =>
    a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true,
    }),
  );
  return items;
};

interface NativeIdSortItem {
  nativeId: string;
}

export const sortByNativeId = <T extends NativeIdSortItem>(items: T[]) => {
  items.sort((a, b) =>
    a.nativeId.localeCompare(b.nativeId, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true,
    }),
  );
  return items;
};
