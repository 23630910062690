import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import GenericButton from '../../../../../shared/components/GenericButton';
import { PopoverContext } from './index';

interface Props {
  onSubmit: (token: string) => void;
}

function Shift(props: Props) {
  const { onSubmit } = props;

  const { onClose } = useContext(PopoverContext);

  const schema = Yup.object({
    shiftValue: Yup.number().required('To pole nie może być puste.'),
  });

  return (
    <Formik
      initialValues={{
        shiftValue: '',
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit(`SHIFT[${values.shiftValue}] (`);
        if (onClose) {
          onClose();
        }
      }}
    >
      {({ dirty, isValid, submitForm }) => (
        <Form>
          <DialogTitle disableTypography>
            <Typography variant="body2">Dodaj przesunięcie</Typography>
          </DialogTitle>
          <DialogContent>
            <Field
              component={TextField}
              name="shiftValue"
              InputProps={{ disableUnderline: true }}
              placeholder="Podaj wartość"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
              Dodaj
            </GenericButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default Shift;
