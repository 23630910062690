import React, { Dispatch, SetStateAction } from 'react';
import { Box, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'formik';
import _ from 'lodash';
import { ChevronLeft } from '@material-ui/icons';
import GenericButton from '../../../../shared/components/GenericButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    token: {
      minWidth: '40px',
      height: '40px',
      marginRight: '3px',
      padding: '2px',
      '&.Mui-label': {
        padding: 0,
      },
    },
    container: {
      position: 'relative',
      minHeight: '60px',
      border: (props: Props) => `2px solid ${props.error ? theme.colors.red : theme.colors.lightGray}`,
      borderRadius: '6px',
      marginBottom: '20px',
    },
    active: {
      border: `2px solid ${theme.colors.blue}`,
    },
    formulaTokens: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
      overflow: 'auto',
      maxWidth: '85%',
    },
    deleteButton: {
      marginLeft: 'auto',
    },
  }),
);

interface Props {
  activeToken?: number;
  setActiveToken: Dispatch<SetStateAction<number | undefined>>;
  error: boolean;
}

function FormulaInput(props: Props) {
  const [field, , helpers] = useField('formula');
  const formula: string = field.value.trim();

  const formulaTokens = formula.split(' ');

  const { activeToken, setActiveToken } = props;

  const classes = useStyles(props);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (_.isNumber(activeToken) && event.key === 'Backspace') {
      const newTokens = formula.split(' ');
      newTokens.splice(activeToken, 1);
      helpers.setValue(newTokens.join(' '));
      setActiveToken(undefined);
    }
  };

  const handleDeleteButton = () => {
    if (!_.isEmpty(formula)) {
      const newTokens = formula.split(' ');
      if (_.isNumber(activeToken)) {
        newTokens.splice(activeToken, 1);
        setActiveToken(undefined);
      } else {
        newTokens.pop();
      }
      helpers.setValue(newTokens.join(' '));
    }
  };

  return (
    <Box className={classes.container} display="flex" alignItems="center">
      <Box className={classes.formulaTokens} display="flex">
        {!_.isEmpty(formula) &&
          formulaTokens.map((token, index) => (
            <GenericButton
              key={`${token}-${index}`}
              className={`${classes.token} ${index === activeToken && classes.active}`}
              variant={token.match(/C\d+/) ? 'secondary' : 'tertiary'}
              onClick={() => {
                if (activeToken === index) {
                  setActiveToken(undefined);
                } else {
                  setActiveToken(index);
                }
              }}
              onKeyDown={handleKeyPress}
            >
              {token}
            </GenericButton>
          ))}
      </Box>
      <GenericButton
        className={`${classes.token} ${classes.deleteButton}`}
        variant="destructiveSecondary"
        onClick={handleDeleteButton}
        disabled={_.isEmpty(formula)}
      >
        <ChevronLeft />
      </GenericButton>
    </Box>
  );
}

export default FormulaInput;
