import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import warehouseAPI from '../../shared/api';
import useReportStore from '../reportStore';
import Sider from './Sider';
import ReportDetails from './ReportDetails';
import Footer from '../../shared/components/Footer';

type Params = {
  id: string;
};

const useStyles = makeStyles({
  mainContainer: {
    boxSizing: 'border-box',
    height: '100%',
  },
  leftPanelBox: {
    overflow: 'auto',
    flexGrow: 1,
    height: '100%',
    background: `white 0% 0% no-repeat padding-box`,
    borderRadius: '10px',
    padding: '0px',
  },
  leftPanelFooter: {
    marginTop: 'auto',
  },
  reportBox: {
    boxSizing: 'border-box',
    flexGrow: 1,
    height: '100%',
    background: `white 0% 0% no-repeat padding-box`,
    borderRadius: '10px',
  },
  reportBoxHeaderBox: {
    padding: '20px 40px',
    borderBottom: `1px solid #F6F6F6`,
    boxSizing: 'border-box',
    maxHeight: '86px',
  },
  reportBoxContentBox: {
    padding: '30px',
    overflow: 'auto',
  },
  gridItem: {
    height: '100%',
  },
  title: {
    fontSize: '32px',
  },
});

function ReportBrowser() {
  const classes = useStyles();
  const { id } = useParams<Params>();

  const { activeReport, setActiveReport } = useReportStore((state) => ({
    activeReport: state.activeReport,
    setActiveReport: state.setActiveReport,
  }));

  const { data: report } = useQuery(['fetchReportDetails', Number(id)], () =>
    warehouseAPI.report.fetchDetails(Number(id)),
  );

  useEffect(() => {
    if (report) {
      setActiveReport(report);
    }
  }, [report, setActiveReport]);

  return (
    <Grid container className={classes.mainContainer} spacing={7}>
      <Grid className={classes.gridItem} item xs={3}>
        <Box className={classes.leftPanelBox} display="flex" flexDirection="column">
          <Box>
            <Sider />
          </Box>
          <Box className={classes.leftPanelFooter} display="flex" flexDirection="row">
            <Footer />
          </Box>
        </Box>
      </Grid>
      <Grid className={classes.gridItem} item xs={9}>
        <Box className={classes.reportBox} display="flex" flexDirection="column">
          <div className={classes.reportBoxHeaderBox}>
            <Header report={activeReport} />
          </div>
          <Grid className={classes.reportBoxContentBox} container>
            <Grid item xs={12}>
              {activeReport && <ReportDetails report={activeReport} />}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ReportBrowser;
