import React, { useState } from 'react';
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Theme,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import warehouseAPI from '../../shared/api';
import FolderListItem from '../ReportList/ReportStructure/FolderListItem';
import GenericButton from '../../shared/components/GenericButton';
import Check from '../../shared/components/icons/Check';
import FolderEditorModal from './FolderEditorModal';
import Title from '../../shared/components/Title';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '60%',
    },
    addButton: {
      width: '100%',
      background: `${theme.colors.blue} 0% 0% no-repeat padding-box`,
      color: 'white',
      '&:hover': {
        boxShadow: `0px 6px 10px ${theme.colors.darkGray}`,
        backgroundColor: theme.colors.blue,
      },
      '&:disabled': {
        backgroundColor: theme.colors.offWhite,
        color: theme.colors.darkGray,
      },
    },
    arrow: {
      fontSize: '16px',
      marginLeft: '8px',
    },
  }),
);

type Props = {
  open: boolean;
  onClose: () => void;
  onSelect: (id: number | null) => void;
  toShowDropdown?: boolean;
  allowEmptySelection?: boolean;
};

const ReportFolderModal: React.FC<Props> = ({
  open,
  onClose,
  onSelect,
  toShowDropdown = true,
  allowEmptySelection = false,
}) => {
  const [openEditorModal, setOpenEditor] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const [activeCategoryId, setActiveCategoryId] = useState<number | null>(null);

  const { data: folderTree = [], isLoading } = useQuery('fetchReportFolderTree', warehouseAPI.reportFolder.tree);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} PaperProps={{ className: classes.root }}>
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Title variant="h1" modal title="Wybierz folder docelowy" />
        </Box>
      </DialogTitle>
      {!isLoading ? (
        <DialogContent>
          <List disablePadding>
            {folderTree?.map((folder) => (
              <FolderListItem
                key={folder.id}
                folder={folder}
                showIndicators={false}
                showMenu={false}
                setActive={setActiveCategoryId}
                activeId={activeCategoryId ?? undefined}
              />
            ))}
          </List>
        </DialogContent>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress size={200} />
        </Box>
      )}
      <DialogActions>
        <GenericButton
          variant="primary"
          disabled={!(allowEmptySelection || activeCategoryId)}
          icon={<Check color={theme.colors.white} />}
          onClick={() => {
            if (allowEmptySelection || typeof activeCategoryId === 'number') {
              onSelect(activeCategoryId);
              setActiveCategoryId(null);
              onClose();
            }
          }}
        >
          Wybierz
        </GenericButton>
        <GenericButton variant="destructiveSecondary" onClick={onClose}>
          Anuluj
        </GenericButton>
      </DialogActions>
      <FolderEditorModal
        open={openEditorModal}
        onClose={() => setOpenEditor(false)}
        parent={activeCategoryId || undefined}
      />
    </Dialog>
  );
};

export default ReportFolderModal;
