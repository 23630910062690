import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryCache } from 'react-query';
import { useSnackbar } from 'notistack';
import warehouseAPI from '../shared/api';
import Title from '../shared/components/Title';
import TextInput from '../shared/components/forms/TextInput';
import GenericButton from '../shared/components/GenericButton';

type Props = {
  open: boolean;
  onClose: () => void;
};

function AddGroupListModal(props: Props) {
  const { open, onClose } = props;

  const [mutate] = useMutation(warehouseAPI.groupList.save);
  const queryCache = useQueryCache();

  const { enqueueSnackbar } = useSnackbar();

  const schema = Yup.object({
    name: Yup.string().required('To pole nie może być puste.'),
    description: Yup.string(),
  });

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await mutate(values);
          await queryCache.invalidateQueries('fetchAdministrativeGroupLists');
          onClose();
        } catch (e) {
          enqueueSnackbar('Błąd zapisywania listy grup', { variant: 'error' });
        }
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ dirty, isValid, submitForm }) => (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
          <DialogTitle disableTypography>
            <Title variant="h1" modal title="Dodaj folder" />
          </DialogTitle>
          <DialogContent>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput fieldId="folderName" label="Nazwa" name="name" placeholder="Podaj nazwę" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fieldId="folderDescription"
                    label="Opis"
                    name="description"
                    rows={5}
                    placeholder="Podaj opis"
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <GenericButton variant="primary" onClick={submitForm} disabled={!(dirty && isValid)}>
              Dodaj
            </GenericButton>
            <GenericButton variant="destructiveSecondary" onClick={onClose}>
              Anuluj
            </GenericButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

export default AddGroupListModal;
