import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { createStyles, Typography, Tab, Tabs, Box } from '@material-ui/core';
import Title from '../../../shared/components/Title';
import { Report } from '../../../shared/types';
import GeneratedReports from '../GeneratedReports/GeneratedReports';
import { useQueryCache } from 'react-query';
import ReportStructure from '../ReportStructure/ReportStructure';

const useStyles = makeStyles({
  content: {
    //textAlign: 'left',
  },
  first: {
    marginTop: '20px',
  },
  rest: {
    marginTop: '30px',
  },
  header: {
    color: 'grey',
  },
  reportDetails: {
    marginTop: '20px',
    width: '100%',
    maxHeight: '55%',
    display: 'flex',
    marginBottom: '10px',
  },
  iconButton: {
    padding: '0px',
    width: '24px',
    height: '24px',
    minWidth: '30px',
    marginLeft: 'auto',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  iconStyle: {
    outline: 'none',
    fontSize: '24px',
    color: 'black',
  },
  reportTab: {
    padding: '20px 40px',
    borderBottom: `1px solid #F6F6F6`,
    boxSizing: 'border-box',
    maxHeight: '86px',
  },
});

const Content = withStyles((theme) =>
  createStyles({
    root: {
      marginTop: '8px',
      fontSize: '15px',
      letterSpacing: '0px',
      color: theme.colors.black,
    },
  }),
)(Typography);

interface Props {
  report: Report;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function ReportDetails(props: Props) {
  const { report } = props;
  const classes = useStyles();
  const queryCache = useQueryCache();
  useEffect(() => {
    const interval = setInterval(() => {
      queryCache.invalidateQueries(['fetchGeneratedReports']);
    }, 5000);
    return () => clearInterval(interval);
  }, [queryCache]);

  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    report && (
      <>
        <div className={classes.reportTab}>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label="Szczegóły" />
            <Tab label="Struktura" />
            <Tab label="Wygenerowane pliki" />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <div className={classes.content}>
            <div className={classes.first}>
              <Title variant="h4" title="Nazwa raportu" className={classes.header} />
              <Content>{report?.name}</Content>
            </div>
            <div style={{ overflow: 'auto', height: '100%' }}>
              <div className={classes.rest}>
                <Title variant="h4" title="Opis" className={classes.header} />
                <Content>{report?.description}</Content>
              </div>
              <div className={classes.rest}>
                <Title variant="h4" title="Folder źródłowy" className={classes.header} />
                <Content>{report?.folder?.name}</Content>
              </div>
              <div className={classes.rest}>
                <Title variant="h4" title="Dostępność w latach" className={classes.header} />
                <Content>{report.yearsRange?.join(', ')}</Content>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {report.folder.id && <ReportStructure folderId={report.folder.id} />}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={classes.reportDetails}>
            <Title variant="h2" title="Dokumenty" />
          </div>
          <GeneratedReports reportId={report.id as number} />
        </TabPanel>
      </>
    )
  );
}

export default ReportDetails;
