import React from 'react';

type Props = {
  color: string;
};

function Confirm(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <rect style={{ opacity: 0 }} width="16" height="16" />
      <g transform="translate(1 2.999)">
        <rect
          style={{ fill: color }}
          width="12.403"
          height="2.026"
          rx="1.013"
          transform="translate(3.792 8.771) rotate(-45)"
        />
        <rect
          style={{ fill: color }}
          width="2.026"
          height="7.389"
          rx="1.013"
          transform="translate(0 4.979) rotate(-45)"
        />
      </g>
    </svg>
  );
}

export default Confirm;
