import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, InputLabel, Theme } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginBottom: '10px',
      color: theme.colors.darkGray,
      fontSize: '15px',
      fontWeight: 400,
    },
  }),
);

interface Props {
  fieldId: string;
  label: string;
  name: string;
  placeholder?: string;
  rows?: number;
}

function TextInput(props: Props) {
  const { fieldId, label, name, placeholder, rows } = props;

  const classes = useStyles();

  return (
    <>
      <InputLabel className={classes.label} htmlFor={fieldId}>
        {label}
      </InputLabel>
      <Field
        component={TextField}
        name={name}
        InputProps={{ id: fieldId, disableUnderline: true }}
        placeholder={placeholder || ''}
        type="text"
        fullWidth
        multiline={rows ? rows > 0 : false}
        rows={rows}
      />
    </>
  );
}

export default TextInput;
