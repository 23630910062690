import React from 'react';

type Props = {
  color: string;
};

function Logout(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-354 -39)">
        <rect style={{ opacity: 0 }} width="16" height="16" transform="translate(354 39)" />
        <g transform="translate(-526 -436)">
          <path
            style={{ fill: color }}
            d="M904.109,486.688v-.005a.815.815,0,0,0-.025-.084.571.571,0,0,0-.081-.2c-.014-.026-.026-.053-.042-.078a1.03,1.03,0,0,0-.121-.149l0,0h0l-2.121-2.122a1,1,0,1,0-1.414,1.415l.414.414h-3.586a1,1,0,0,0,0,2h3.586l-.414.414a1,1,0,0,0,1.414,1.415l2.121-2.122,0,0a1.03,1.03,0,0,0,.121-.149c.016-.025.028-.052.042-.078a1.055,1.055,0,0,0,.05-.094l0-.006c.013-.031.019-.064.029-.1a.821.821,0,0,0,.025-.084v-.005a1,1,0,0,0,0-.382Z"
            transform="translate(-8.128 -3.879)"
          />
          <path
            style={{ fill: color }}
            d="M889.709,487.167a4.929,4.929,0,0,1-2.748.833,5,5,0,0,1,0-10,4.926,4.926,0,0,1,2.748.833.992.992,0,0,0,1.379-.28,1,1,0,0,0-.277-1.387A6.9,6.9,0,0,0,886.96,476a7,7,0,0,0,0,14,6.9,6.9,0,0,0,3.849-1.167,1,1,0,0,0,.278-1.386A.991.991,0,0,0,889.709,487.167Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Logout;
