import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Cube, CubeCategory, TableData } from '../shared/types';
import { RootState } from '../app/rootReducer';
import { API_URL } from '../shared/utils/config';

export const fetchCubeCategoryTree = createAsyncThunk('dataBrowser/fetchCubeCategoryTree', async () => {
  const response = await axios.get(`${API_URL}/warehouse/categories/tree/`);
  return response.data as Array<CubeCategory>;
});

export const fetchCubeDetails = createAsyncThunk('dataBrowser/fetchCubeDetails', async (cubeId: number) => {
  const response = await axios.get(`${API_URL}/warehouse/cubes/${cubeId}/`);
  return response.data as Cube;
});

export const setCurrentCube = createAsyncThunk('dataBrowser/setCurrentCube', async (cubeId: number) => {
  const response = await axios.get(`${API_URL}/warehouse/cubes/${cubeId}/`);
  return response.data as Cube;
});

interface fetchDataPayload {
  cubeId: number;
  postData: any;
}

export const fetchCubeData = createAsyncThunk('dataBrowser/fetchCubeData', async (payload: fetchDataPayload) => {
  const { cubeId, postData } = payload;
  const response = await axios.post(`${API_URL}/warehouse/cubes/${cubeId}/data/`, postData);
  return response.data as TableData;
});

interface SliceState {
  categoryTree: Array<CubeCategory>;
  currentCube: Cube | null;
}

const dataBrowserSlice = createSlice({
  name: 'warehouse',
  initialState: { categoryTree: [], currentCube: null } as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCubeCategoryTree.fulfilled, (state, action) => {
        state.categoryTree = action.payload;
      })
      .addCase(setCurrentCube.fulfilled, (state, action) => {
        state.currentCube = action.payload;
      });
  },
});

const dataBrowserBranch = (state: RootState) => state.dataBrowser;
export const cubeCategoryTree = createSelector(dataBrowserBranch, (branch) => branch.categoryTree);
export const currentCube = createSelector(dataBrowserBranch, (branch) => branch.currentCube);
export const header = createSelector(currentCube, (cube) => ({
  title: cube?.name,
}));
export const cubeParentsIds = createSelector(currentCube, (cube) =>
  cube ? cube.parents.map((parent) => parent.id) : [],
);

export default dataBrowserSlice.reducer;
